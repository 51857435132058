import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Card } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Buttons, Spinners, Drawers, Forms } from "..";
import { Store } from "../../stores/stores";
import { IBanner } from "../../utils/interfaces";

const Banners = observer(() => {
    const { bannersStore } = Store;
    const banners = bannersStore.banners.value;

    const cardCssClassName = (banner: IBanner): string => {
        let pastToDate = false
        if (banner.toDate) {
            const toDate = moment(banner.toDate);
            pastToDate = toDate.isBefore(moment())
        }
        return !pastToDate ? '' : 'banner-card-expired';
    }

    React.useEffect(() => {
        bannersStore.loadBanners()
    }, [bannersStore])

    return (
        <>
            <Spinners.CustomSpinner
                isSpinning={bannersStore.isLoading}
                title={"Getting Banners"}
            >
                <div className='form-options-container'>
                    <Buttons.Text
                        text="Add New"
                        onClick={() => bannersStore.editBanner()}
                        loading={bannersStore.isLoading}
                        disabled={bannersStore.isLoading}
                    />
                </div>

                <Row gutter={[16, 16]} className='banner-row'>
                    {banners.data.map(banner => (
                        <>
                            <Col span={12}>
                                <Card
                                    hoverable
                                    className={`banner-card ${cardCssClassName(banner)}`}
                                    bodyStyle={{ padding: 0 }}
                                    cover={
                                        <img
                                            className='img-card-banner'
                                            alt="banner"
                                            src={banner.imageUrl}
                                        />
                                    }
                                    actions={[
                                        <EditOutlined
                                            onClick={() => bannersStore.editBanner({ ...banner })}
                                        />,
                                        <Buttons.Text
                                            key="ellipsis"
                                            icon={<DeleteOutlined />}
                                            onClick={() => bannersStore.removeBanner({ ...banner })}
                                        />
                                    ]}
                                >
                                </Card>
                            </Col>
                        </>
                    ))}
                </Row>

                <Drawers.CustomDrawer
                    title="Banners"
                    visible={bannersStore.showFormsPanel}
                    setVisible={(_) => bannersStore.resetBannerForm()}
                    children={() => (
                        <Forms.NewBanner
                            banner={bannersStore.currentBanner}
                            onFormFinish={(_) => bannersStore.submitBanner(_)}
                            isLoading={bannersStore.isLoading}
                        />
                    )}
                />
            </Spinners.CustomSpinner >
        </>
    )
});

export default Banners;
