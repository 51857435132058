import { DeleteTwoTone } from "@ant-design/icons";
import { DatePicker, Form, FormInstance, Input, message, Switch } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Buttons, Drawers, Files, Search } from "..";
import { APIService, copyObjects } from "../../services";
import { formDataValues } from "../../services/formData";
import { Store } from "../../stores/stores";
import { ISpecial } from "../../utils/interfaces";
import { GeoJSON } from "../../utils/types";
import { DebounceSelectProp } from "../Search/DebounceSearch";
import "./style.css";

type NewSpecialProp = {
    special?: ISpecial,
    isLoading?: boolean
    onFormFinish: (f: FormData) => void,
};

type SpecialFormType = Omit<ISpecial, 'fromDate' | 'toDate' | 'branchId'> & {
    branchValue: { value: string, label: string },
    fromDateMoment: moment.Moment,
    toDateMoment: moment.Moment,
}

const NewSpecial = observer(({ special, isLoading, onFormFinish }: NewSpecialProp) => {
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [isMapVisible, setIsMapVisible] = React.useState(false);
    const [removedFiles, setRemovedFiles] = React.useState<string[]>([]);
    const [selectedFile, setSelectedFile] = React.useState(0);
    const formRef = React.createRef<FormInstance<SpecialFormType>>();

    const { branchesStore, inventoryStore, specialsStore } = Store;

    const BranchSelect = ({ ...props }) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(q) => branchesStore.searchBranches(q)}
            labelInValue={true}
            allowClear
            {...props}
        />
    );

    const dateTimePicker = (props: any) => (
        <DatePicker {...props} showTime />
    );

    const formData = [
        { name: 'title', label: "Title", component: Input, required: true },
        { name: 'fromDateMoment', label: "From", component: dateTimePicker, required: false },
        { name: 'toDateMoment', label: "To", component: dateTimePicker, required: false },
        { name: 'branchValue', label: 'Branch', component: BranchSelect, required: false },
        { name: 'active', label: "Active", component: Switch, valuePropName: "checked", required: false },
        { name: 'redirect', label: "National", component: Switch, valuePropName: "checked", required: false },
    ];

    const onFinishFailed = (errorInfo: any) => {
        if (!special) {
            message.error('Could not add special.')
        } else {
            message.error('Could not update special.')
        }
    };

    const onFinish = (values: SpecialFormType) => {
        let formData = new FormData();
        special?.id && formData.append('id', special.id);
        values.title && formData.append('title', values.title);
        values.toDateMoment && formData.append('toDate', values.toDateMoment.toISOString());
        values.fromDateMoment && formData.append('fromDate', values.fromDateMoment.toISOString());
        values.branchValue && formData.append('branchId', values.branchValue.value);
        formData.append('active', values.active ? 'true' : 'false');
        formData.append('redirect', values.redirect ? 'true' : 'false');

        // date check
        if (values.fromDateMoment || values.toDateMoment) {
            if (!values.toDateMoment || !values.fromDateMoment) {
                message.error('Enter valid date range.');
                return;
            }
            if (!values.fromDateMoment.isSameOrBefore(values.toDateMoment)) {
                message.error('Enter valid date range.');
                return;
            }
            formData.append('fromDate', values.fromDateMoment.toISOString());
            formData.append('toDate', values.toDateMoment.toISOString());
        }
        if (fileList.length > 0) {
            fileList.forEach((file, idx) => {
                if (file?.originFileObj) {
                    formData.append('images', file.originFileObj)
                    formData.append('mapInfo', JSON.stringify(specialsStore.geoJsonRefs[idx] ?? ''));
                }
            });
            removedFiles.forEach(url => formData.append('imagesToDelete', url))
            onFormFinish(formData);
        } else {
            message.error('Add product images.');
            return;
        }
    }

    const validateMessages = {
        required: "Field is required!",
    };

    React.useEffect(() => {
        if (special?.imageMaps) {
            const ls = special.imageMaps.map((x) => ({
                uid: x.image,
                name: x.image,
                url: x.image,
            }))
            setFileList(ls)
        } else {
            setFileList([])
        }
    }, [special])

    React.useEffect(() => {
        if (!isMapVisible) {
            specialsStore.setMapImageUrl(null);
        }
    }, [isMapVisible, specialsStore]);

    React.useEffect(() => {
        if (special) {
            const imageMapsCurrent = special.imageMaps.map((x) => x.geoJsonObject);
            runInAction(() => specialsStore.geoJsonRefs = imageMapsCurrent);
        } else {
            runInAction(() => {
                // TODO: hard coded limit, mobx requires a with length
                specialsStore.geoJsonRefs = new Array(100);
            })
        }
    }, [special]);

    return (
        <Form<SpecialFormType>
            ref={formRef}
            name='new-special'
            className='custom-form inventory-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout='vertical'
            requiredMark={false}
            validateMessages={validateMessages}
            initialValues={{
                ...special,
                branchValue: special?.branch ? { value: special.branchId, label: special.branch.branchName } : undefined,
                fromDateMoment: special?.fromDate ? moment(special.fromDate) : undefined,
                toDateMoment: special?.toDate ? moment(special.toDate) : undefined,
            } as unknown as  SpecialFormType}
        >
            <Files.ImagesPreviewUpload
                name='specialImage'
                title={'Add Images'}
                fileList={fileList}
                selectedFile={selectedFile}
                setFileList={setFileList}
                setSelectedFile={setSelectedFile}
                onRemoveUrlImage={(url, idx) => {
                    setRemovedFiles(arr => [...arr, url]);
                    runInAction(() => {
                        specialsStore.geoJsonRefs.splice(idx, 1);
                    });
                }}
                onMapIconClick={(idx) => {
                    setSelectedFile(idx);
                    const img = { url: "", revokeUrl: false, index: idx };
                    if (fileList[idx].url) {
                        img.url = fileList[idx].url!;
                    } else if(fileList[idx].originFileObj) {
                        img.url = URL.createObjectURL(fileList[idx].originFileObj as any)
                        img.revokeUrl = true;
                    } else {
                        message.error("Failed to open image.");
                        return;
                    }
                    specialsStore.setMapImageUrl(img);
                    setIsMapVisible(true);
                }}
            />
            {formData.map(({ component: Component, ...item }, index) =>
                (
                    <Form.Item
                        key={index}
                        rules={[{ required: item.required }]}
                        {...item}
                    >
                        <Component />
                    </Form.Item>)
            )}
            <div className='custom-form-submit'>
                <Buttons.Small
                    htmlType='submit'
                    text={special ? 'Update special' : 'Add special'}
                    loading={isLoading}
                    disabled={isLoading}
                />
            </div>

           <Drawers.ImageMap
               isVisible={isMapVisible}
               setIsVisible={setIsMapVisible}
               onSaveImage={async (geojson) => {
                   if (specialsStore.mapImageUrl && specialsStore.geoJsonRefs) {
                       runInAction(() => specialsStore.geoJsonRefs[specialsStore.mapImageUrl!.index] = geojson);
                    }
                    if (fileList[selectedFile].url) {
                        const imageMap = special?.imageMaps?.find((x) => x.image === fileList[selectedFile].url);
                        if (imageMap) {
                            try {
                                imageMap.geoJsonObjectString = JSON.stringify(geojson);
                                await APIService.Special.updateSpecialImageMap(imageMap, []);
                            } catch (err) {
                                message.error('Failed to update image');
                                console.error(err);
                                return;
                            }
                        }
                    }
                   message.success("Map info saved");
               }}
            />
        </Form>
    );
});

export default NewSpecial;
