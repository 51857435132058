import { Col, DatePicker, Dropdown, Form, FormInstance, Input, InputNumber, message, Row, Select, Switch, UploadFile } from "antd";
import { observer } from "mobx-react-lite";
import React, { Component, useEffect, useRef, useState } from "react";
import { Buttons, Files } from "..";
import { Store } from "../../stores/stores";
import { IContentSlider } from "../../utils/interfaces";
import "./style.css";
import { ContentSliderPosition } from "../../utils/enums";

type NewSliderFormProp = {
    slider?: IContentSlider | null,
    onFormFinish?: (slider: FormData) => void,
    onSliderDelete: () => void,
    isLoading: boolean,
};

const initialLargeImageValue = (slider?:IContentSlider | null):UploadFile[] => {
    if (!slider)        {
            return [];
        }

    return [{uid: slider?.imageLargeUrl ?? '',
        url: slider?.imageLargeUrl, 
        name: slider?.imageLargeUrl ?? ''}]
};

const initialLaptopImageValue = (slider?:IContentSlider | null):UploadFile[] => {
    if (!slider)        {
            return [];
        }

    return [{uid: slider?.imageLaptopUrl ?? '',
        url: slider?.imageLaptopUrl, 
        name: slider?.imageLaptopUrl ?? ''}]
};
const initialTabletImageValue = (slider?:IContentSlider | null):UploadFile[] => {
    if (!slider)        {
            return [];
        }

    return [{uid: slider?.imageTabletUrl ?? '',
        url: slider?.imageTabletUrl, 
        name: slider?.imageTabletUrl ?? ''}]
};
const initialMobileImageValue = (slider?:IContentSlider | null):UploadFile[] => {
    if (!slider)        {
            return [];
        }

    return [{uid: slider?.imageMobileUrl ?? '',
        url: slider?.imageMobileUrl, 
        name: slider?.imageMobileUrl ?? ''}]
};
const BannerPageValues = {
    home_page: 'home_page',
    about_page: 'about_page',
    special_page: 'special_page'
};

type PositionLabelMap = {
    [ContentSliderPosition.RIGHT]: string;
    [ContentSliderPosition.CENTER]: string;
    [ContentSliderPosition.LEFT]: string;
};

const positionLabelMap: PositionLabelMap = {
    [ContentSliderPosition.RIGHT]: 'Right',
    [ContentSliderPosition.CENTER]: 'Center',
    [ContentSliderPosition.LEFT]: 'Left'
};

const NewBannerForm = observer(({
    slider,
    onFormFinish,
    onSliderDelete,
    isLoading,
}: NewSliderFormProp) => {
    const { contentSliderStore } = Store;
    const formRef = useRef<FormInstance>(null);
    const [fileListLaptop, setFileListLaptop] = useState<UploadFile[]>(initialLaptopImageValue(slider));
    const [fileListMobile, setFileListMobile] = useState<UploadFile[]>(initialMobileImageValue(slider));
    const [fileListTablet, setFileListTablet] = useState<UploadFile[]>(initialTabletImageValue(slider));
    const [fileListLarge, setFileListLarge] = useState<UploadFile[]>(initialLargeImageValue(slider));

    const validateMessages = {
        required: "Field is required!",
    };

    const selectRefNo = ({ ...props }: any) => (
        <Select
            options={[
                    { label: 'Home Page', value: BannerPageValues.home_page },
                    { label: 'About Page', value: BannerPageValues.about_page },
                    { label: 'Specials Page', value: BannerPageValues.special_page }
                ] }
            labelInValue={false}
            {...props}
        />
    );

    const selectPositionEnum = ({ ...props }: any) => (
        <Select
            options={[
                    { label: 'Right', value: ContentSliderPosition.RIGHT},
                    { label: 'Center', value: ContentSliderPosition.CENTER},
                    { label: 'Left', value: ContentSliderPosition.LEFT},
                ] }
            {...props}
        />
    );


    const fieldConfig = [
        { name: 'refNo', label: 'Page', required: true, component: selectRefNo},
        { name: 'headerText', label: 'Header Text', component: Input },
        { name: 'subHeaderText', label: 'Sub Header Text', component: Input },
        { name: 'subText', label: 'Sub Text', component: Input },
        { name: 'pageLink', label: 'Page Link', component: Input },
        { name: 'positionEnum', label: 'Position', component: selectPositionEnum, default: ContentSliderPosition.CENTER},
        { name:'positionNumber', label: 'Position Number', component: InputNumber, default: 25},
    ];

    const onFinishFailed = (errorInfo: any) => {
        if (!slider) {
            message.error('Could not add banner slider.')
        } else {
            message.error('Could not update banner slider.')
        }
    };
    
    const handleOnFinish = (formValues: any) => {
        const formData = new FormData();

        if (onFormFinish === undefined) {
            return;
        }

        if(!formValues.positionNumber){
            formValues.positionNumber = 30;
        }

        if (slider) {
            formData.append('id', slider.id);
        }
        // Populate formData with formValues data
        fieldConfig.forEach((field) => {
            const { name } = field;
            const value = formValues[name];

            if (value !== undefined && value !== null) {
                formData.append(name, value);
            }
        });

        if (fileListLaptop.length === 0 || fileListMobile.length === 0 || fileListTablet.length === 0 || fileListLarge.length === 0) {
            message.error('Fill in all required images before submitting.');
            return;
        }

        formData.append("imageLaptop", fileListLaptop[0]?.originFileObj || "");
        formData.append("imageMobile", fileListMobile[0]?.originFileObj || "");
        formData.append("imageTablet", fileListTablet[0]?.originFileObj || "");
        formData.append("imageLarge", fileListLarge[0]?.originFileObj || "");
        onFormFinish(formData);
    };

    return (
        <Form
            ref={formRef}
            name='new-slider-banner'
            className='custom-form'
            onFinish={handleOnFinish}
            onFinishFailed={onFinishFailed}
            layout='vertical'
            requiredMark={false}
            initialValues={{ 
                     ...slider,
                     positionEnum: slider && slider.positionEnum !== undefined ? positionLabelMap[slider.positionEnum] : positionLabelMap[ContentSliderPosition.RIGHT],
                    overlay: slider?.overlay ? true : false,
                    }}
        >
             <Row gutter={16}>
                    <Col span={8}>
                        <Files.ImagesPreviewUpload
                            name='imageLarge'
                            title={'Add Large Image'}
                            maxCount={1}
                            fileList={fileListLarge}
                            selectedFile={0}
                            setFileList={setFileListLarge}
                            setSelectedFile={() => {}}
                        />
                    </Col>
                    <Col span={8}>
                        <Files.ImagesPreviewUpload
                            name='imageLaptop'
                            title={'Add Laptop Image'}
                            maxCount={1}
                            fileList={fileListLaptop}
                            selectedFile={0}
                            setFileList={setFileListLaptop}
                            setSelectedFile={() => {}}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={8}>
                        <Files.ImagesPreviewUpload
                            name='imageTablet'
                            title={'Add Tablet Image'}
                            maxCount={1}
                            fileList={fileListTablet}
                            selectedFile={0}
                            setFileList={setFileListTablet}
                            setSelectedFile={() => {}}
                        />
                    </Col>
                    <Col span={8}>
                        <Files.ImagesPreviewUpload
                            name='imageMobile'
                            title={'Add Mobile Image'}
                            maxCount={1}
                            fileList={fileListMobile}
                            selectedFile={0}
                            setFileList={setFileListMobile}
                            setSelectedFile={() => {}}
                        />
                    </Col>
                </Row>
            {/* Render form fields based on field configuration */}
            {fieldConfig.map(({component:Component ,...field}) => (
            <>
                 <Form.Item
                    key={field.name}
                    rules={[{ required: field.required, message: `${field.label} is required` }]}
                    {...field}
                  
                >
                    <Component />
                </Form.Item>
            </>
            ))}
            <Form.Item
                    label={"Overlay"}
                    name={"overlay"}
                    rules={[{ required: true, message: `Overlay is required` }]}
                    valuePropName="checked"
            >
                <Switch  />
            </Form.Item>

            <div className='custom-form-submit'>
                <Buttons.Small
                    htmlType='submit'
                    text={slider ? 'Update banner' : 'Add banner'}
                    loading={isLoading}
                    disabled={isLoading}
                />
            </div>
        </Form>
    );
});

export default NewBannerForm;