import React from "react"
import { axiosInterceptor } from "../config/axios-config";
import { PlatformRights } from "../utils/enums";
import { IUserAuth } from "../utils/interfaces";

export interface IAuthContext {
    authKey?: any,
    setAuthKey?: any,
    isLogged?: boolean,
    setIsLogged?: any,
    authenticateUser?: any,
    logoutUser?: any,
    currentUser?: IUserAuth | null,
    setCurrentUser?: any,
    hasRoutePermission?: any
    generateAuthKey?: any
}

const AuthContext = React.createContext<IAuthContext>({})

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
    const [authKey, setAuthKey] = React.useState<any>();
    const [isLogged, setIsLogged] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState<IUserAuth | null>(null);

    const authenticateUser = async (authKey: any, user: any) => {
        localStorage.setItem('authKey', authKey);
        await axiosInterceptor({ authKey });
        setAuthKey(authKey);
        setIsLogged(true);
        setCurrentUser(user);
    }

    const generateAuthKey = (email: string, password: string) => {
        const authKey = btoa(`${email}:${password}`);
        return authKey;
    }

    const logoutUser = async () => {
        localStorage.removeItem('authKey');
        await axiosInterceptor({ authKey: null });
        setAuthKey('');
        setIsLogged(false);
        setCurrentUser(null);
    }

    const hasRoutePermission = (routePermissions: PlatformRights[]): boolean => {
        return routePermissions.some(right =>
            currentUser?.userTypeList.some(u => u === right));
    }

    return (
        <AuthContext.Provider
            value={{
                authKey,
                setAuthKey,
                isLogged,
                setIsLogged,
                currentUser,
                setCurrentUser,
                authenticateUser,
                logoutUser,
                hasRoutePermission,
                generateAuthKey
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
