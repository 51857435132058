import { APP_URL } from "../../config/axios-config";
import { IOrderMessage } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getOrderMessages = async (queryParameters?: string): Promise<ResponseFormat<IOrderMessage>> => {
    const response = await APP_URL.get('orderMessage?' + queryParameters || '');

    const items: IOrderMessage[] = response.data;
    return { data: items };
}

export const addOrderMessage = async (body: IOrderMessage, items: IOrderMessage[]): Promise<ResponseFormat<IOrderMessage>> => {
    const response = await APP_URL.post('orderMessage', body);

    items.push(response.data as IOrderMessage);
    return { data: items };
}

export const updateOrderMessage = async (body: IOrderMessage, items: IOrderMessage[]): Promise<ResponseFormat<IOrderMessage>> => {
    items = [...items]

    await APP_URL.put(`orderMessage/${body.id}`, body);

    const elementPos = items.map(x => x.id).indexOf(body.id);
    items[elementPos!] = body;
    return { data: items };
}

export const deleteOrderMessage = async (body: IOrderMessage, items: IOrderMessage[]): Promise<ResponseFormat<IOrderMessage>> => {
    await APP_URL.delete(`orderMessage/${body.id}`);

    return { data: items };
}

export const updateList = async (body: IOrderMessage[]): Promise<void> => {
    await APP_URL.put(`orderMessage/updateRange`, body);
}