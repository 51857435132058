import { APP_URL } from "../../config/axios-config";
import { IPostalAddress } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getAddresses = async (): Promise<ResponseFormat<IPostalAddress>> => {
    const response = await APP_URL.get('postalAddress');

    let addresses: IPostalAddress[] = [];

    if (response?.data) {
        addresses = response.data;
    }
    return { data: addresses };
}

export const addAddress = async (body: IPostalAddress, addresses: IPostalAddress[]): Promise<ResponseFormat<IPostalAddress>> => {
    const response = await APP_URL.post('postalAddress', body);

    addresses.push(response.data as IPostalAddress);

    return { data: addresses };
}

export const updateAddress = async (body: IPostalAddress, addresses: IPostalAddress[]): Promise<ResponseFormat<IPostalAddress>> => {
    addresses = [...addresses]

    await APP_URL.put(`postalAddress/${body.id}`, body);

    const elementPos = addresses.map(x => x.id).indexOf(body.id);
    addresses[elementPos!] = body;

    return { data: addresses };
}

export const deleteAddress = async (body: IPostalAddress, addresses: IPostalAddress[]): Promise<ResponseFormat<IPostalAddress>> => {
    await APP_URL.delete(`postalAddress/${body.id}`);

    return { data: addresses };
}