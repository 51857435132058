export const StandardFields = [
    "Name",
    "Surname",
    "ID Number",
    "Email address",
    "Contact number",
    "Province",
    "City",
    "Race",
    "Gender",
    "Current salary",
    "Expected salary",
    "Notice period",
    "Upload CV",
    "POPI Act consent agreement"
];

export const OptionalFields = [
    "Highest qualification",
    "Accreditation",
    "Licensing"
];