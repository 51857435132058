import moment from "moment";
import { IParcelLabel } from "../interfaces";

type ParcelLabelProp = {
    parcel: IParcelLabel,
    totalParcels: number,
    orderDate: string | Date | null | undefined
}

export const zplParcelLabel = ({ parcel, totalParcels, orderDate }: ParcelLabelProp) => `
^^XA

^FX Label Container
^FO 2, 2^GB  795, 795^FS

^FX Barcode Section
^FO 125, 25 ^BY 2, 3 ^BC , 120, , , , A ^A 0, 26 ^FD${parcel.barcode} ^FS

^FX Border Container
^FO 25, 190 ^GB  760, 580^FS

^CF0,28

^FX Service information section
^FO30,200 ^FDORIGIN: ${parcel.shipAddr3} ^FS
^FO30,240 ^FDSERVICE: ${parcel.serv} ^FS
^FO30,280 ^FDACCNO: ${parcel.shipAccnum} ^FS
^FO410,200 ^FDDESTINATION: ${parcel.recAddr3} ^FS
^FO410,240 ^FDCARRIER: ${parcel.agent} ^FS
^FO405,190 ^GB,130 ^FS

^FX SENDER SECTION
^FO25,320 ^GB760, 130 ^FS
^FO30,340 ^FDSENDER: ${parcel.shipName} ^FS
^FO30,380 ^FDCONTACT: ${parcel.shipCon} ^FS
^FO30,420 ^FDTEL: ${parcel.shipTel} ^FS

^FX RECEIVER SECTION
^FO30,460 ^FDRECEIVER: ${parcel.recName} ^FS
^FO30,500 ^FDADDRESS: ${parcel?.recAddr1 ?? ''} ${parcel?.recAddr2 ?? ''} ^FS
^FO138,540 ^FD ${parcel?.recAddr3 ?? ''} ${parcel.recAddr4 ?? ''} ^FS
^FO30,580 ^FDCONTACT: ${parcel.recCont} ^FS
^FO30,620 ^FDTEL: ${parcel.recTel} ^FS
^FO25,650 ^GB760, ^FS

^FX PARCEL SECTION
^FO30,660 ^FDDATE: ${moment(orderDate).format("DD/MM/YY")} ^FS
^FO30,700 ^FDPARCEL: ${getParcelNo(parcel)} OF ${totalParcels} ^FS
^FO30,740 ^FDPARCEL WEIGHT: ${parcel.totalKG} ^FS

^XZ
`;

const getParcelNo = (parcel: IParcelLabel): number => {
    return +parcel.barcode.split("_")[1]
}
