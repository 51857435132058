import React, { useState, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Tabs } from 'antd';
import { LexicalEditor } from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import HtmlEditor from './Editor';
import { $getRoot, $createParagraphNode, $isTextNode } from 'lexical';
import "./style.css";
import pretty from 'pretty';

interface HtmlEditorWithGeneratorProps {
  onChange: (editorState: string, editor: LexicalEditor | undefined) => void;
  initialEditorState?: string;
}

const HtmlEditorWithGenerator = forwardRef(({ onChange, initialEditorState }: HtmlEditorWithGeneratorProps, ref) => {
  const [editor, setEditor] = useState<LexicalEditor | undefined>();
  const [generatedHtml, setGeneratedHtml] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('editor');

  const handleEditorChange = useCallback((editorState: string, lexicalEditor: LexicalEditor | undefined) => {
    setEditor(lexicalEditor);
    onChange(editorState, lexicalEditor);
  }, [onChange]);

  const generateHtml = useCallback(() => {
    if (editor) {
      editor.update(() => {
        const html = $generateHtmlFromNodes(editor, null);
        setGeneratedHtml(pretty(html)); 
      });
    }
  }, [editor]);

  const updateEditorWithHtml = useCallback(() => {
    console.log("Inside updateEditorWithHtml");
    if (editor && generatedHtml) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(generatedHtml, 'text/html');
      editor.update(() => {
        const nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();
        root.clear();
        nodes.forEach(node => {
          if ($isTextNode(node) || node.isInline()) {
            let lastChild = root.getLastChild();
            if (!lastChild || !lastChild.isInline()) {
              lastChild = $createParagraphNode();
              root.append(lastChild);
            }
            lastChild.append(node);
          } else {
            root.append(node);
          }
        });
      });
    }
  }, [editor, generatedHtml]);

  const handleHtmlChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGeneratedHtml(event.target.value);
  }, []);

  useEffect(() => {
    if (activeTab === 'html' && editor) {
      generateHtml();
    }
  }, [activeTab, generateHtml, editor]);

  useEffect(() => {
    if (activeTab === 'editor' && editor && generatedHtml) {
      updateEditorWithHtml();
    }
  }, [activeTab, updateEditorWithHtml, editor, generatedHtml]);

  useImperativeHandle(ref, () => ({
    updateEditorWithHtml
  }));

  return (
    <div>
      <Tabs activeKey={activeTab} onChange={setActiveTab} className='custom-tabs'>
        <Tabs.TabPane tab="Editor" key="editor">
          <HtmlEditor onChange={handleEditorChange} initialEditorState={initialEditorState} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="HTML" key="html">
          <textarea
            value={generatedHtml}
            onChange={handleHtmlChange}
            className="code-editor"
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
});

export default HtmlEditorWithGenerator;
