import { APP_URL } from "../../config/axios-config";
import { ISkuMaster } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getSkuMasters = async (queryParameters?: string): Promise<ResponseFormat<ISkuMaster>> => {
    const response = await APP_URL.get('skuMaster?' + queryParameters || '');

    const skuMasters: ISkuMaster[] = response.data;
    return { data: skuMasters };
}

export const addSkuMaster = async (body: FormData, skuMasters: ISkuMaster[]): Promise<ResponseFormat<ISkuMaster>> => {
    const response = await APP_URL.post('skuMaster', body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });

    skuMasters.push(response.data as ISkuMaster);
    return { data: skuMasters };
}

export const updateSkuMaster = async (body: FormData, skuMasters: ISkuMaster[]): Promise<ResponseFormat<ISkuMaster>> => {
    skuMasters = [...skuMasters]

    const response = await APP_URL.post(`skuMaster/${body.get('id')}`, body, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    });

    const data = response.data as ISkuMaster;
    const elementPos = skuMasters.map(x => x.id).indexOf(data.id);
    skuMasters[elementPos!] = data;
    return { data: skuMasters };
}

export const deleteSkuMaster = async (body: ISkuMaster, skuMasters: ISkuMaster[], queryParameters?: string): Promise<ResponseFormat<ISkuMaster>> => {
    await APP_URL.delete(`skuMaster/${body.id}?` + queryParameters || '');

    skuMasters.splice(skuMasters.indexOf(body), 1);
    return { data: skuMasters };
}