import { Form, FormInstance, Input, message, Select } from "antd";
import React from "react";
import { Buttons } from "..";
import { EmailService, copyObjects } from "../../services";
import { PlatformRights } from "../../utils/enums";
import { IUserAuth } from "../../utils/interfaces";
import { PopupConfirm } from "../Modals";
import "./style.css";

type NewStaffFormProp = {
    userAuth?: any,
    onFormFinish?: (staff: IUserAuth) => void,
    onStaffDelete: () => void,
    isLoading: boolean,
};

const NewStaffForm = ({ userAuth, onFormFinish, onStaffDelete, isLoading }: NewStaffFormProp) => {
    const formRef = React.createRef<FormInstance>()

    const validateMessages = {
        required: "Field is required!",
    };

    const handleOnFinish = (formValues: any) => {
        if (EmailService.isEmailValid(formValues.emailAddress)) {
            const values: IUserAuth = copyObjects(formValues, {
                userType: { slug: formValues?.userTypeSlug }
            })

            onFormFinish!(values);
        } else {
            message.error('Enter a valid email address.');
        }
    }

    return (
        <>
            {userAuth &&
                <div className='form-delete-container'>
                    <PopupConfirm
                        isLoading={isLoading}
                        onConfirm={() => onStaffDelete()}
                    />
                </div>
            }

            <Form
                ref={formRef}
                name='new-staff'
                className='custom-form'
                onFinish={handleOnFinish}
                onFinishFailed={() => { }}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={userAuth}
            >
                <Form.Item
                    label="First name"
                    name="firstName"
                    rules={[{ required: true, }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Last name"
                    name="lastName"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="emailAddress"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="userTypeSlug"
                    label="Platform Rights"
                    rules={[{ required: true }]}
                >
                    <Select defaultValue={userAuth?.userType?.slug}>
                        {Object.values(PlatformRights)
                            .filter(x => x !== PlatformRights.DeactivatedStaff)
                            .map((item, index) =>
                                <Select.Option key={index} value={item}>
                                    {Object.keys(PlatformRights)[index]}
                                </Select.Option>
                            )}
                    </Select>
                </Form.Item>

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={userAuth ? 'Update staff' : 'Add staff'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>

            </Form>
        </>
    );
};

export default NewStaffForm;