import { ImageMapEditor, onCreateCallback } from "@bridgelabsdesign/gfox-image-tool"
import { message } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import { addSpecial, deleteSpecial, getSpecials, updateSpecial } from "../services/api-service/special"
import { ISpecial, IMessage } from "../utils/interfaces"
import { LoadManager } from "../utils/LoadManager/LoadManager"
import { GeoJSON } from "../utils/types";

export class SpecialStore {
    specials = new LoadManager<ISpecial>({ data: [] }, getSpecials, addSpecial, deleteSpecial, updateSpecial)

    searchQuery = ""
    showPanel = false;
    showFormsPanel = false;
    currentSpecial?: ISpecial;

    mapRef: ImageMapEditor | undefined;
    mapImageUrl : { url: string, revokeUrl: boolean, index: 0 } | null = null;
    mapLayerCreateCallback: onCreateCallback | undefined;
    isSkuSelectionVisible = false;
    geoJsonRefs: GeoJSON[] =  [];

    constructor() {
        makeObservable(this, {
            geoJsonRefs: observable,
            mapRef: observable,
            mapImageUrl: observable,
            mapLayerCreateCallback: observable,
            isSkuSelectionVisible: observable,
            setIsSkuSelectionVisible: observable,
            searchQuery: observable,
            specials: observable,
            isLoading: computed,
            filteredQueryResults: computed,
            showPanel: observable,
            showFormsPanel: observable,
            togglePanel: action,
            toggleFormsPanel: action,
            setSearchQuery: action,
            editSpecial: action,
            resetSpecialForm: action,
            loadMap: action,
            setMapImageUrl: action,
            submitSpecial: action,
        });
    }

    setIsSkuSelectionVisible(v: boolean) {
        this.isSkuSelectionVisible = v;
    }

    setMapImageUrl(obj: { url: string, revokeUrl: boolean, index: 0} | null) {
        if (!obj && this.mapImageUrl) {
            URL.revokeObjectURL(this.mapImageUrl.url);
        }
        this.mapImageUrl = obj;
    }

    loadMap(id: string): void {
        try {
            this.mapRef = new ImageMapEditor(id, {
              controls: true,
              onLayerCreate: (callback: any) => {
                this.mapLayerCreateCallback = callback;
                this.setIsSkuSelectionVisible(true);
              },
            });
        } catch (err: any) {
            console.error('image map err: failed to init / load image')
        }
    }

    get isLoading(): boolean {
        return this.specials.isLoading;
    }

    get filteredQueryResults(): ISpecial[] {
        if (this.searchQuery.length === 0) {
            return this.specials.value.data;
        }
        return this.specials.value.data.filter(x => (
            String(x.title).toUpperCase().indexOf(this.searchQuery.toUpperCase()) >= 0
        ));
    }

    setSearchQuery(s: string) {
        this.searchQuery = s.trim();
    }

    loadSpecials() {
        const queryParameters = "";
        this.specials.fetch(queryParameters);
    }

    togglePanel(v: boolean) {
        this.showPanel = v;
    }

    toggleFormsPanel(v: boolean) {
        this.showFormsPanel = v;
    }

    editSpecial(special?: ISpecial) {
        this.showFormsPanel = true;
        this.currentSpecial = special;
    }

    resetSpecialForm() {
        this.showFormsPanel = false;
        this.currentSpecial = undefined;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.specials.error || this.specials.error) {
            message.error(errorMsg)
        } else {
            this.resetSpecialForm();
            message.success(successMsg)
        }
    }

    async submitSpecial(special: FormData) {
        try {
            if (this.currentSpecial !== undefined) {
                await this.specials.update(special);
                this.showMessage({
                   successMsg: 'Special added.',
                   errorMsg: 'Could not add special.',
                });
            } else {
                await this.specials.add(special);
                this.showMessage({
                    successMsg: 'Special updated.',
                    errorMsg: 'Could not update special.',
                });
            }

        } catch (e) {
            console.error('Error updating/adding special', e);
        }

        if (!this.specials.error) {
            this.loadSpecials();
        }
    }

    async removeSpecial(special: ISpecial) {
        try {
            await this.specials.remove(special)
            this.showMessage({
                successMsg: 'Special removed.',
                errorMsg: 'Could remove add special.',
            });
        } catch (e) {
            console.error('Error removing special', e);
        }
    }
}
