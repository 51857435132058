import { Layout } from "antd";
import { AxiosError } from "axios";
import React, { useCallback, useEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { axiosInterceptor } from "./config/axios-config";
import { useAuthContext } from "./context/AuthContext";
import Home from "./layouts/Home";
import Landing from "./layouts/Landing";
import MiniProgram from "./layouts/MiniProgram";
import { APIService } from "./services";
import { Layouts } from "./utils/enums";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
    const { authenticateUser, currentUser, isLogged, setAuthKey, setIsLogged, } = useAuthContext();
    const history = useHistory();
    const location = useLocation();

    const handleNotLoggedIn = useCallback(() => {
        const returnUrl = btoa(`${location.pathname}${location.search}`);
        history.push(`/?returnUrl=${returnUrl}`);
        console.error("not logged in");
    }, [history, location.pathname, location.search]);

    React.useEffect(() => {
        let isLoggedIn = false;
        let authKey = localStorage.getItem('authKey');

        if (authKey) {
            isLoggedIn = true;
            axiosInterceptor({ authKey });
            setIsLogged(isLoggedIn);
            setAuthKey(authKey);

            if (!currentUser) {
                APIService.UserAuth.authorizeUser()
                    .then(user => authenticateUser(authKey, user))
                    .catch((err: AxiosError) => {
                        handleNotLoggedIn();
                        console.log(err);
                    })
            }
        } else {
            handleNotLoggedIn();
        }
    }, [authenticateUser, currentUser, handleNotLoggedIn, isLogged, setAuthKey, setIsLogged])

    return (
        <Route
            {...rest}
            render={prop =>
                isLogged ? (
                    <Component />
                ) : (
                    <div></div>
                )
            }
        />
    );
}

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <PrivateRoute path={Layouts.Home} component={(props: any) => <Home />} />
                <Route path={Layouts.MiniProgram} render={props => <MiniProgram />} />
                <Route exact path={Layouts.Landing} render={props => <Landing />} />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;
