import { APP_URL } from "../../config/axios-config";
import { IVoucherConstraint } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getVoucherConstraint = async (queryParams: string = ''): Promise<ResponseFormat<IVoucherConstraint>> => {
    const response = await APP_URL.get(`voucherConstraint?${queryParams}`);

    let voucherConstraint: IVoucherConstraint[] = [];

    if (response?.data) {
        voucherConstraint = response.data;
    }
    return { data: voucherConstraint };
}

export const addVoucherConstraint = async (body: IVoucherConstraint, voucherConstraint: IVoucherConstraint[]): Promise<ResponseFormat<IVoucherConstraint>> => {
    const response = await APP_URL.post(`voucherConstraint`, body);

    voucherConstraint.push(response.data as IVoucherConstraint);
    return { data: voucherConstraint };
}

export const updateVoucherConstraint = async (body: IVoucherConstraint, voucherConstraint: IVoucherConstraint[]): Promise<ResponseFormat<IVoucherConstraint>> => {
    voucherConstraint = [...voucherConstraint]

    await APP_URL.put(`voucherConstraint/${body.id}`, body);

    const elementPos = voucherConstraint.map(x => x.id).indexOf(body.id);
    voucherConstraint[elementPos!] = body;
    return { data: voucherConstraint };
}

export const deleteVoucherConstraint = async (body: IVoucherConstraint, voucherConstraint: IVoucherConstraint[]): Promise<ResponseFormat<IVoucherConstraint>> => {
    await APP_URL.delete(`voucherConstraint/${body.id}`);

    voucherConstraint.splice(voucherConstraint.indexOf(body), 1);
    return { data: voucherConstraint };
}

export const addVoucherConstraintRange = async (body: IVoucherConstraint[], voucherId?: string): Promise<IVoucherConstraint[]> => {
    const response = await APP_URL.post<IVoucherConstraint[]>(`voucherConstraint/range?voucherId=${voucherId ?? ''}`, body);
    return response.data;
}

export const updateVoucherConstraintRange = async (body: IVoucherConstraint[]): Promise<void> => {
    await APP_URL.put(`voucherConstraint/range`, body);
}
