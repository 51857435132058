import { APP_URL } from "../../config/axios-config";
import { IVoucher } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getVoucher = async (queryParams: string = ''): Promise<ResponseFormat<IVoucher>> => {
    const response = await APP_URL.get(`voucher?${queryParams}`);

    let voucher: IVoucher[] = [];

    if (response?.data) {
        voucher = response.data;
    }
    return { data: voucher };
}

export const addVoucher = async (body: IVoucher, voucher: IVoucher[]): Promise<ResponseFormat<IVoucher>> => {
    const response = await APP_URL.post(`voucher`, body);

    voucher.push(response.data as IVoucher);
    return { data: voucher };
}

export const updateVoucher = async (body: IVoucher, voucher: IVoucher[]): Promise<ResponseFormat<IVoucher>> => {
    voucher = [...voucher]

    await APP_URL.put(`voucher/${body.id}`, body);

    const elementPos = voucher.map(x => x.id).indexOf(body.id);
    voucher[elementPos!] = body;
    return { data: voucher };
}

export const deleteVoucher = async (body: IVoucher, voucher: IVoucher[]): Promise<ResponseFormat<IVoucher>> => {
    await APP_URL.delete(`voucher/${body.id}`);

    voucher.splice(voucher.indexOf(body), 1);
    return { data: voucher };
}

export const getVoucherByRefNo = async (refNo: string ): Promise<IVoucher> => {
     const response = await APP_URL.get<IVoucher>(`voucher/refNo/${refNo}`)
     return response.data;
}
