import { Form, FormInstance, Input,Switch} from "antd";
import React from "react";
import { Buttons } from "..";
import { PopupConfirm } from "../Modals";
import { Store } from "../../stores/stores";
import "./style.css";
import { IRatingModel } from "../../utils/interfaces";

type ModerateFormProp = {
    Ratings?: IRatingModel| null,
    // onFormFinish?: (staff: IRatingModel) => void,
    onStaffDelete: () => void,
    isLoading: boolean,
};

const ModerateReviewForm = ({ Ratings, onStaffDelete, isLoading }: ModerateFormProp) => {
    const { ratingsStore} = Store;
    const formRef = React.createRef<FormInstance>()

    const validateMessages = {
        required: "Field is required!",
    };

    const handleSwitchChange = async (checked: boolean) => {
    if (Ratings && Ratings.id) {
        const updatedRating: IRatingModel = {
            ...Ratings, // Spread the current rating properties
            moderated: checked // Set Moderated based on the switch state
        };
        await ratingsStore.updateRating(Ratings.id, updatedRating);
    }
};


    return (
        <>
            {Ratings &&
                <div className='form-delete-container'>
                    <PopupConfirm
                        isLoading={isLoading}
                        onConfirm={() => onStaffDelete()}
                    />
                </div>
            }

            <Form
                ref={formRef}
                name='new-staff'
                className='custom-form'
                onFinishFailed={() => { }}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={{ ...Ratings, moderation: Ratings?.moderated }}
            >
                <Form.Item
                    label="Name"
                    name="firstName"
                    
                    rules={[{ required: true, }]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    label="Review"
                    name="comment"
                    rules={[{ required: true }]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    label="Rating"
                    name="value"
                    rules={[{ required: true }]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    label="sku"
                    name="sku"
                    rules={[{ required: true }]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    label="Allow review to be seen on the website"
                    name="Moderation"
                    valuePropName="checked"
                    rules={[{ required: true }]}
                  
                >
                    <Switch defaultChecked={Ratings?.moderated} onChange={handleSwitchChange} />
                </Form.Item>

            </Form>
        </>
    );
};

export default ModerateReviewForm;