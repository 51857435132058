import { action, computed, makeObservable, observable } from "mobx";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { APP_URL_HOST } from "../config/websocket";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { IOrderMessage } from "../utils/interfaces";
import { addOrderMessage, deleteOrderMessage, getOrderMessages, updateOrderMessage } from "../services/api-service/order-message";
import { message, notification } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import React from "react";
import { APIService, copyObjects } from "../services";
import { NotificationSound } from "../assets";

export class NotificationStore {
    orderMessages = new LoadManager<IOrderMessage>({ data: [] }, getOrderMessages, addOrderMessage, deleteOrderMessage, updateOrderMessage);

    currentOrderId?: string;
    messageConnection?: HubConnection;
    typedMessage: string = "";
    isSendingMsg: boolean = false;
    unreadMessages: IOrderMessage[] = []

    constructor() {
        makeObservable(this, {
            orderMessages: observable,
            currentOrderId: observable,
            messageConnection: observable,
            startMessageConnection: action,
            loadOrderMessages: action,
            markOrderMessageAsRead: action,
            isLoading: computed,
            typedMessage: observable,
            setTypedMessage: action,
            isSendingMsg: observable,
            sendOrderMessage: action,
            unreadMessages: observable,
            loadUnreadMessages: action,
            unreadList: computed,
        })
    }

    get isLoading(): boolean {
        return this.orderMessages.isLoading || this.isSendingMsg;
    }

    buildHubConnection(authKey: string, path: string): HubConnection {
        return new HubConnectionBuilder()
            .withUrl(`${APP_URL_HOST}hub/${path}`, {
                headers: { "Authorization": `Basic ${authKey}` },
            })
            .withAutomaticReconnect()
            .build();
    }

    async startMessageConnection(authKey: string) {
        const messageConnection = this.buildHubConnection(authKey, "message");

        messageConnection.on("QuerySpecialistMessage", (orderMessage, ...args) => {
            const receivedMessage = JSON.parse(orderMessage) as IOrderMessage;
            this.unreadMessages.push(receivedMessage);
            notification.info({
                message: 'New Order Message',
                icon: React.createElement(MessageOutlined),
                description: receivedMessage.message,
                className: 'message-notification',
            });
            const audio = new Audio(NotificationSound);
            audio.play().catch(() => {
                audio.muted = true;
                audio.play().catch(() => { })
            })
            if (this.currentOrderId === receivedMessage.orderId) {
                this.loadOrderMessages(this.currentOrderId);
            }
        })

        try {
            await messageConnection.start();
        } catch (error) {
            console.log(error);
        }
    }

    get unreadList(): IOrderMessage[] {
        return this.unreadMessages;
    }

    async loadUnreadMessages() {
        try {
            let queryParameters = `readyByUser=${false}`;
            const reponse = await APIService.OrderMessages.getOrderMessages(queryParameters);
            this.unreadMessages = reponse.data
        } catch (error) {
            message.error('Could not load notifications.')
        }
    }

    async markOrderMessageAsRead(orderId: string) {
        this.loadOrderMessages(orderId);
        let messages = this.unreadMessages.filter(x => x.orderId === orderId)
        const requestBody = messages.map(x => copyObjects<IOrderMessage, Partial<IOrderMessage>>(x, { readyByUser: true }));
        APIService.OrderMessages.updateList(requestBody);
        this.unreadMessages = this.unreadMessages.filter(x => x.orderId !== orderId);
    }

    async loadOrderMessages(orderId?: string) {
        this.currentOrderId = orderId;
        let queryParameters = '';
        if (orderId) {
            queryParameters = `orderId=${orderId}&`;
        }
        await this.orderMessages.fetch(queryParameters)
    }

    setTypedMessage(message: string) {
        this.typedMessage = message;
    }

    async sendOrderMessage(userAuthId: string, orderId: string) {
        const requestBody = {
            replyUserId: userAuthId,
            orderId: orderId,
            message: this.typedMessage,
            readByClient: false,
            readyByUser: true,
        } as IOrderMessage;

        const hide = message.loading("Sending message...", 0);
        try {
            this.isSendingMsg = true;
            await this.orderMessages.add(requestBody);
            await this.loadOrderMessages(orderId);
            this.isSendingMsg = false;
            hide();
        } catch (error) {
            hide();
            message.error("Could not send order message.");
            this.isSendingMsg = false;
            console.log(error);
        }
    }

}