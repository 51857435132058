import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { addClient, deleteClient, getClients, updateClient, getClientsCSV, populateVerification, getVerifications } from "../services/api-service/client";
import { IAddress, IClient, IClientAddress, IMessage, ISysProPricing, IVerificationRequest } from "../utils/interfaces";
import { ResponseFormat } from "../utils/types";
import { message } from "antd";
import { getClientAddresses, addClientAddress, deleteClientAddress, updateClientAddress } from "../services/api-service/client-address";
import { APIService, copyObjects } from "../services";
import { downloadObject } from "../services/downloadObject";
import { DebounceSelectProp } from "../components/Search/DebounceSearch";

export class ClientStore {
    clients = new LoadManager<IClient>({ data: [] }, getClients, addClient, deleteClient, updateClient);
    clientAddresses = new LoadManager<IClientAddress>({ data: [] }, getClientAddresses, addClientAddress, deleteClientAddress, updateClientAddress);
    verifications = new LoadManager<IVerificationRequest>({ data: [] }, getVerifications);
    query = "";
    verificationQuery ="";
    searchQuery = '';
    pricingQuery = "";
    showPanel = false;
    showVerificationPanel = false;
    showPricingPanel = false;
    currentClient?: IClient;
    activeTab = "0"
    defaultPageSize = 100; // default pagination size, not an ovbservable
    searchId = 0;
    isSearching = false;
    isLoadingPricing = false;
    clientPricing: ISysProPricing[] = [];



    constructor() {
        makeObservable(this, {
            clients: observable,
            clientAddresses: observable,
            verificationQuery:observable,
            query: observable,
            searchQuery: observable,
            pricingQuery: observable,
            isSearching: observable,
            isLoadingPricing: observable,
            clientPricing: observable,
            setQuery: action,
            searchCustomer:action,
            setPricingQuery: action,
            getClients: computed,
            getClientPricing: computed,
            pagination: computed,
            showPanel: observable,
            showVerificationPanel: observable,
            currentClient: observable,
            showPricingPanel: observable,
            tooglePricingPanel: action,
            panelTitle: computed,
            addNew: action,
            editClient: action,
            isLoading: computed,
            activeTab: observable,
            setActiveTab: action,
            addAddress: action,
            loadClients: action,
            loadContractPricing: action,
            onFormSubmit: action,
            removeClient: action,
            exportClientCSV: action,
            submitVerification:action,
            verifications:observable,
        })
    }

    setQuery(s: string) {
        this.query = s.trim();
        setTimeout(() => {
            this.searchId += 1;
            const fetchId = this.searchId;
            this.isSearching = true;
            const queryParameters = `search=${this.query}&pageNumber=1&pageSize=${this.defaultPageSize}`;
            APIService.Client.getClients(queryParameters)
                .then(response => {
                    if (fetchId === this.searchId) {
                        this.clients.value = { ...response };
                        this.isSearching = false;
                    }
                });
        }, 800);
    }

    setSearchQuery(query: string) {
        this.searchQuery = query.trim();
    }

    get searchedVerifications(): IVerificationRequest[] {
        let filteredVerifications = this.verifications;

        if (this.searchQuery.length === 0) {
            return filteredVerifications.value.data;
        } else {
            const data = filteredVerifications.value.data.filter(verification => (
                String(verification?.user.emailAddress).toUpperCase().indexOf(this.searchQuery.toUpperCase()) >= 0
                || String(verification.phoneNumber).toUpperCase().indexOf(this.searchQuery.toUpperCase()) >= 0
                || String(verification?.verified).toUpperCase().indexOf(this.searchQuery.toUpperCase()) >= 0
            ));
            return data;
        }
    }

    setPricingQuery(s: string) {
        this.pricingQuery = s.trim();
    }

      // Load staff from api
    loadVerifications() {
        this.verifications.fetch()
    }

    setActiveTab(key: string) {
        this.activeTab = key;
    }

    get getClients(): ResponseFormat<IClient> {
        return this.clients.value;
    }

    get getClientPricing(): ISysProPricing[] {
        if (this.pricingQuery.length === 0) {
            return this.clientPricing;
        } else {
            const data = this.clientPricing.filter(pricing => (
                String(pricing.stockCode).toUpperCase().indexOf(this.pricingQuery.toUpperCase()) >= 0
            ));

            return data;
        }
    }

    get pagination() {
        return this.clients.value.pagination ?? {
            PageNumber: 1,
            PageSize: this.defaultPageSize,
            TotalPages: 1,
            TotalRecords: 1,
        };
    }

    get panelTitle(): string {
        return this.isNew ? 'Add new client' : 'Edit client';
    }

     get verificationPanelTitle(): string {
        return  'Add Verification';
    }

    get isNew(): boolean {
        return !this.currentClient;
    }

    addNew() {
        this.showPanel = true;
        this.currentClient = undefined;
    }

    addNewVerification() {
        this.showVerificationPanel = true;
        this.currentClient = undefined;
    }


    editClient(client: any) {
        this.showPanel = true;
        this.currentClient = client;
    }

    resetForm() {
        this.showPricingPanel = false;
        this.showPanel = false;
        this.currentClient = undefined;
        this.setActiveTab("0");
    }

    verificationResetForm() {
        this.showVerificationPanel = false;
    }

    tooglePricingPanel(value: boolean) {
        this.showPricingPanel = value;

        if (value) {
            this.loadContractPricing();
        }
    }

    get isLoading(): boolean {
        return this.clients.isLoading || this.isSearching || this.isLoadingPricing;
    }

    get isLoadingVerification(): boolean {
        return this.verifications.isLoading 
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.clients.error) {
            message.error(errorMsg)
        } else {
            message.success(successMsg)
        }
    }

    async addAddress(address?: IAddress, isShippingAddres?: boolean) {
        if (address && this.currentClient) {
            let body: any = {
                clientId: this.currentClient?.id,
                addressId: address.id,
                isShippingAddres: !!isShippingAddres,
            };

            const currentAddress = this.currentClient?.clientAddresses?.find(x => x?.isShippingAddres === isShippingAddres);
            if (!currentAddress) {
                await this.clientAddresses.add(body);
                this.showMessage({
                    successMsg: 'Customer address updated.',
                    errorMsg: 'Could not update customer address.',
                });
            }
        }

        this.setQuery(this.currentClient?.userAuth.emailAddress ?? "");
        this.resetForm();
    }

    // Load clients from api
    loadClients(pageNumber?: number, pageSize: number = this.defaultPageSize) {
        try {
            let queryParameters = `search=${this.query}`;
            if (pageNumber && pageSize) {
                queryParameters = `pageNumber=${pageNumber}&pageSize=${pageSize}`
            }

            this.clients.fetch(queryParameters)
        }
        catch (e) {
            console.log('Error fetching clients', e);
        }
    }

    async loadContractPricing() {
        try {
            if (this.currentClient) {
                this.isLoadingPricing = true;
                const response = await APIService.Client.getPricing(this.currentClient);
                if (response?.data) {
                    this.clientPricing = response?.data
                }
            }
        } catch (error) {
            console.log("loadContractPricing", error);
        }

        this.isLoadingPricing = false;
    }

    // add clientes from api
    async onFormSubmit(client: IClient) {
        let updatedBody: IClient | undefined;

        try {
            if (this.currentClient) {
                updatedBody = copyObjects(this.currentClient, client);

                await this.clients.update(updatedBody);
                this.showMessage({
                    successMsg: 'Customer updated.',
                    errorMsg: 'Could not update customer.',
                });
            } else {
                await this.clients.add(client)
                updatedBody = this.clients.value.data.find(x =>
                    x?.userAuth?.emailAddress === client?.userAuth?.emailAddress);
                this.showMessage({
                    successMsg: 'Added new customer.',
                    errorMsg: 'Could not added branch.',
                });
            }

            this.editClient(updatedBody);
        } catch (e) {
            console.log('Error updating/adding clients', e);
        }
    }

    async removeClient() {
        try {
            if (this.currentClient) {
                await this.clients.remove(this.currentClient,"hardDelete=true");
                this.loadClients();
                this.showPanel = false;
            }
            this.showMessage({
                successMsg: 'Customer deactivated.',
                errorMsg: 'Could not deactivate customer.',
            });
        } catch (e) {
            console.log('Error deleting clients', e);
        }
    }


    // exportClient CSV file store
    async exportClientCSV() {
        const hideLoading = message.loading("Downloading CSV...");
        try {
            const csv = await getClientsCSV() || "";
            const csvBlob = new Blob([csv], { type: "text/csv" });
            
            const reader = new FileReader();
            reader.readAsDataURL(csvBlob);
            reader.onloadend = (e) => {
                downloadObject(reader.result as string, "Clients.csv");
            };
            reader.onerror = (error) => {
                message.error("Error downloading file");
            };
            ;
        } catch (error) {
            // Handle errors and optionally show a message
            message.error("Error downloading CSV");
            return null;
        }
        hideLoading();
    }

        async submitVerification(request: IVerificationRequest): Promise<void> {
        try {
            await populateVerification(request);
            message.success("Verification data successfully submitted.");
            this.loadVerifications();
        } catch (error) {
            console.error("Error submitting verification data:", error);
            message.error("Failed to submit verification data. Please try again.");
        }
    };

        get getVerification(): ResponseFormat<IVerificationRequest> {
        if (this.verificationQuery.length === 0) {
            return this.verifications.value;
        } else {
            const data = this.verifications.value.data.filter(verification => {
                return (
                    String(verification?.user.firstName).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(verification?.user.lastName).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(verification?.user.emailAddress).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(verification.phoneNumber).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(verification?.verified).toUpperCase().indexOf(this.query.toUpperCase()) >= 0

                )
            });

            return { data: data }
        }
    }

    async searchCustomer(query: string, pageNumber = 1, pageSize = 25): Promise<DebounceSelectProp[]> {
        let queryParameters = `pageNumber=${pageNumber}&pageSize=${pageSize}&`;
        queryParameters += `search=${query || ''}`;
        const response = await APIService.UserAuth.getUserAuths(queryParameters);

        // Access the `data` property to get the array of IUserAuth objects
        return response.data.map(userAuth => ({
            key: userAuth.id,
            value: userAuth.id,
            label: `${userAuth.firstName} ${userAuth.lastName} (${userAuth.emailAddress})`
        }));
    }

}