import { observable, action, makeObservable, computed } from "mobx";
import { message } from "antd";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { IMFAStatus } from "../utils/interfaces"; // Adjust according to your actual interfaces
import { postMFAStatus, getMFAStatusByUserId} from "../services/api-service/mfa";
import { ResponseFormat } from "../utils/types";

export class MFAStore {
    currentMFA?: IMFAStatus; 
    isLoading = false;
    constructor() {
        makeObservable(this, {
            currentMFA: observable, 
            isLoading: observable,
            setCurrentMFA: action, 
            clearCurrentMFA: action, 
        });
    }

    // loadMFAStatus() {
    //     this.isLoading = true;
    //     this.mfaStatus.fetch().finally(() => {
    //         this.isLoading = false;
    //     });
    // }

    setCurrentMFA(mfa: IMFAStatus) {
        this.currentMFA = mfa;
    }

    clearCurrentMFA() {
        this.currentMFA = undefined;
    }

    async addMFAStatus(formData: FormData, mfaStatuses: IMFAStatus[]): Promise<void> {
        this.isLoading = true;
        try {
            const response = await postMFAStatus(formData, mfaStatuses);
            this.setCurrentMFA(response.data[response.data.length - 1]);
            message.success('MFA status added successfully');
        } catch (error) {
            message.error('Failed to add MFA status');
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    async fetchMFAStatusByUserId(userId: string): Promise<void> {
    this.isLoading = true;
        try {
            const response = await getMFAStatusByUserId(userId);

            if (response.data && response.data.length > 0) {
                this.setCurrentMFA(response.data[0][0]);
                message.success('MFA status fetched successfully');
            } else {
                message.info('No MFA status found for the user');
            }
        } catch (error) {
            message.error('Failed to fetch MFA status');
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }


}