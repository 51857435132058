import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Input, Table, Tabs, Modal, Form, message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Forms, Spinners } from "..";
import { Store } from "../../stores/stores";
import { IUserAuth, ILinkedAccount, IClient } from "../../utils/interfaces";


const LinkedAccountsDrawer = observer(() => {
    const { LinkedAccountsStore, emailTokensStore } = Store;
    const [form] = Form.useForm();
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            fixed: 'left' as 'left',
            width: 70,
            render: (text: any, record: ILinkedAccount, index: number) => ((current - 1) * pageSize) + index + 1,
        },
        {
            title: "Name",
            dataIndex: "userAuth",
            width:200,
            render: (userAuth: IUserAuth) => `${userAuth?.firstName ?? ''} ${userAuth?.lastName ?? ''}`,
        },
        {
            title: "Email",
            dataIndex: "userAuth",
            width:200,
            render: (userAuth: IUserAuth) => `${userAuth?.emailAddress}`,
        },
        {
            title: "Verified",
            dataIndex: "verified",
            width:140,
            filters: [
                {
                    text: 'Verified',
                    value: true,
                },
                {
                    text: 'Not Verified',
                    value: false,
                },
            ],
            onFilter: (value: any, record: ILinkedAccount) => record.verified === value,
            render: (verified: boolean) => (
                verified? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
            ),
        },
        {
            title: "Account Number",
            dataIndex: "mainClient",
            width:140,
            render: (mainClient: IClient) => mainClient?.accountNumber,
        },
        {
            key: 'action',
            fixed: 'right' as 'right',
            width:140,
            render: (text: any, record: ILinkedAccount) => (
                <span>
                    <Buttons.Text text="Accept" onClick={() => handleAccept(record)} />
                    <Buttons.Text text="Decline" onClick={() => handleDecline(record)} />
                </span>
            ),
        },
    ];
    const handleAccept = async (record: ILinkedAccount) => {
        const userAuth = record.userAuth;
        if(record.mainClient.accountNumber)
        {
            await emailTokensStore.onLinkAccount(userAuth.emailAddress);
            console.log(`User ${userAuth.firstName} ${userAuth.lastName} has been verified.`);
            LinkedAccountsStore.togglePanel(false);      
        }else
        {
            message.error('Can not accept user without account number.');
        }
        
    };
    
    const handleDecline = (record: ILinkedAccount) => {
        const userAuth = record.userAuth;

            Modal.confirm({
                title: 'Enter Decline Reason',
                content: (
                    <Form form={form}>
                        <Form.Item name="declineReason" rules={[{ required: true, message: 'Please enter a decline reason!' }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                ),
                onOk: async () => {
                    try {
                        await form.validateFields();
                        const declineReason = form.getFieldValue('declineReason');
                        if(declineReason)
                        {
                            await emailTokensStore.onDeclineAccountLink(userAuth.emailAddress, declineReason);                            
                            console.log(`User ${userAuth.firstName} ${userAuth.lastName} has been unverified.`);
                            LinkedAccountsStore.togglePanel(false);
                        }else
                        {
                            message.error('Provide decline reason.');
                        }
                       
                      
                    } catch (error) {
                        message.error('Failed to decline account link.');
                    }
                },
                onCancel: () => {
                    console.log('Decline operation cancelled');
                },
                okButtonProps: { 
                    className: 'text-btnOK',
                  
                },
                cancelButtonProps: {
                    className: 'text-btn', 
                },
                okText: 'Decline',
                cancelText: 'Cancel',
            });
        
    };
      

    useEffect(() => {
        if (LinkedAccountsStore.showPanel) {
            LinkedAccountsStore.loadLinkedAccounts();
        }
    }, [LinkedAccountsStore.showPanel]);
    
    return (
        <Drawer
            className='custom-drawer'
            title="Linked Accounts"
            visible={LinkedAccountsStore.showPanel}
            onClose={(_) => LinkedAccountsStore.togglePanel(false)}
            width="60%"
            children={(
                <>
                    <div className='form-options-container'>
                        <Buttons.Text
                            text="Add new"
                            loading={false}
                            disabled={false}                           
                        />
                    </div>
                    <Input
                        className='pageheader-options-search orders-search'
                        placeholder="Search list..."                      
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        disabled={LinkedAccountsStore.isLoading}
                        onChange={(_) => LinkedAccountsStore.setSearchQuery(_.target.value)}
                        style={{ marginTop: '1em', marginBottom: '2em', width: '50%' }}
                    />

                    <div className='custom-table-container'>
                    <Spinners.CustomSpinner
                            title={'Getting Linked Accounts'}
                            isSpinning={LinkedAccountsStore.isLoading}
                         >
                        <Table
                            rowKey='id'
                            className='custom-table contractpricing-table'
                            columns={columns}                            
                            dataSource={LinkedAccountsStore.searchLinkedAccounts}
                            pagination={{
                                pageSize: pageSize,
                                current: current,
                                onChange: (page, pageSize) => {
                                    setCurrent(page);
                                    setPageSize(pageSize);
                                }}}
                        />
                     </Spinners.CustomSpinner>

                    </div>
                </>
            )}
        />
    )
});

export default LinkedAccountsDrawer;
