import { APP_URL } from "../../config/axios-config";
import { ICODAccountNumber } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getCODAccountNumbers = async (): Promise<ResponseFormat<ICODAccountNumber>> => {
    const response = await APP_URL.get('CODAccountNumber');

    let CODAccountNumber: ICODAccountNumber[] = [];

    if (response?.data) {
        CODAccountNumber = response.data;
    }
    return {
        data: CODAccountNumber
    };
}

export const addCODAccountNumber = async (body: ICODAccountNumber, CODAccountNumber: ICODAccountNumber[]): Promise<ResponseFormat<ICODAccountNumber>> => {
    const response = await APP_URL.post('CODAccountNumber', body);

    CODAccountNumber.push(response.data as ICODAccountNumber);

    return { data: CODAccountNumber };
}

export const deleteCODAccountNumber = async (body: ICODAccountNumber, cODAccountNumber: ICODAccountNumber[]): Promise<ResponseFormat<ICODAccountNumber>> => {
    await APP_URL.delete(`CODAccountNumber/${body.id}`);

    const updatedCODAccountNumber = cODAccountNumber.filter(x => x.id !== body.id);

    return { data: updatedCODAccountNumber };
}

export const updateCODAccountNumber = async (body: ICODAccountNumber, CODAccountNumber: ICODAccountNumber[]): Promise<ResponseFormat<ICODAccountNumber>> => {
    const response = await APP_URL.put(`CODAccountNumber/${body.id}`, body);

    const updatedCODAccountNumber = CODAccountNumber.map(x => x.id === body.id ? response.data : x);

    return { data: updatedCODAccountNumber };
}