import {Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, Forms, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import "./style.css";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const ReviewManagement = observer(() => {
    const { ratingsStore} = Store;

    const ratings = ratingsStore.getRatings.data;

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            fixed: 'left' as 'left',
            width: 30,
            render: (text: any, record: any, index: any) => index + 1
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            width: 200,
            render: (text: any, record: any) => (
                <div>{`${record?.firstName ?? ''} ${record?.lastName ?? ''}`}</div>
            )
        },
        {
            title: 'Review',
            dataIndex: 'comment',
            width: 200,
        },
        {
            title: 'Rating',
            dataIndex: 'value',
            width: 200,
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            width: 200,
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            width: 200,
        },
        {
            title: "Moderated",
            dataIndex: "moderated",
            render: (isNew: boolean, record: any) => (
                isNew ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            key: 'view more',
            fixed: 'right' as 'right',
            width: 60,
            render: (text: any, record: any) => (
                <Buttons.Text
                    text='view'
                    onClick={() => {
                        ratingsStore.showRating(record);
                    }}
                />
            )
        },
    ];

    React.useEffect(() => {
        ratingsStore.loadRatings()
    }, [ratingsStore]);

    return (
        <main id="home" className='home-content-page'>
            <div className='pageheader-container'>
                <h1 className='header'>Rating and Review Moderation</h1>
                <div className='pageheader-options'>
                    <Input
                        size='large'
                        className='pageheader-options-search'
                        placeholder="Search list..."
                        defaultValue={ratingsStore.query}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        onChange={(_) => {
                            ratingsStore.setQuery(_.target.value)
                        }}
                    />  
                </div>
            </div>

            <div className='custom-table-container'>
                <Spinners.CustomSpinner
                    title={'Getting Ratings and Reviews'}
                    isSpinning={ratingsStore.isLoading}
                >
                    <Table
                        dataSource={[...ratings]}
                        columns={columns}
                        className='custom-table'
                        rowKey='id'
                        scroll={{ x: 1400 }}
                        pagination={{
                            style: { margin: '16px' }
                        }}
                    />
                </Spinners.CustomSpinner>

            </div>

            <Drawers.CustomDrawer
                title={ratingsStore.panelTitle}
                visible={ratingsStore.showPanel}
                setVisible={(_) => ratingsStore.resetForm()}
                children={() => (
                    <Forms.ModerateReviewForm
                        Ratings={ratingsStore.currentRating }
                        isLoading={ratingsStore.isLoading}
                        onStaffDelete={() => ratingsStore.removeRatings}
                    />
                )}
            />
        </main>
    );
});
