import { AddressStore } from "./addresses";
import { BranchStore } from "./branches";
import { InventoryStore } from "./inventory";
import { PostalAddressStore } from "./postal-addresses";
import { StaffStore } from "./staff";
import { ClientStore } from "./client";
import { EmailToken } from "./email-token";
import { OrderStore } from "./order";
import { NotificationStore } from "./notifications";
import { BannerStore } from "./banners";
import { PayStore } from "./pay";
import { SurveyStore } from "./survey";
import QuoteStore from "./quote";
import { VoucherStore } from "./voucher";
import { SurveyQuestionStore } from "./survey-question";
import { SurveyQuestionTypeStore } from "./survey-type-question";
import { SpecialStore } from "./specials";
import { ratingsStore } from "./ratings";
import { LinkedAccountsStore } from "./linked-accounts";
import { MFAStore } from "./mfa";
import { ContentSliderStore } from "./content-sliders";
import { CODAccountNumberStore } from "./cod-account-number";
import { ImageMapStore } from "./image-map";
import { CustomPageStore } from "./custom-page";
import { SurveyResponseStore } from "./survey-response";

export class Stores { }

export const Store = {
    addressesStore: new AddressStore(),
    bannersStore: new BannerStore(),
    branchesStore: new BranchStore(),
    inventoryStore: new InventoryStore(),
    emailTokensStore: new EmailToken(),
    ordersStore: new OrderStore(),
    payStore: new PayStore(),
    postalAddressStore: new PostalAddressStore(),
    quotesStore: new QuoteStore(),
    specialsStore: new SpecialStore(),
    surveyResponseStore: new SurveyResponseStore(),
    staffStore: new StaffStore(),
    surveyStore: new SurveyStore(),
    surveyQuestionType: new SurveyQuestionTypeStore(),
    surveyQuestion : new SurveyQuestionStore(),
    clientsStore: new ClientStore(),
    notificationsStore: new NotificationStore(),
    vouchersStore: new VoucherStore(),
    ratingsStore: new ratingsStore(),
    LinkedAccountsStore: new LinkedAccountsStore(),
    MFAStore: new MFAStore(),
    contentSliderStore: new ContentSliderStore(),
    CODAccountNumberStore: new CODAccountNumberStore(),
    ImageMapStore: new ImageMapStore(),
    customPageStore: new CustomPageStore(),
};
