import { APP_URL } from "../../config/axios-config";
import { IAttribute } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getAttributes = async (queryParameters?: string): Promise<ResponseFormat<IAttribute>> => {
    const response = await APP_URL.get('attribute?' + queryParameters || '');

    const attributes: IAttribute[] = response.data;
    return { data: attributes };
}

export const addAttribute = async (body: IAttribute, attributes: IAttribute[]): Promise<ResponseFormat<IAttribute>> => {
    const response = await APP_URL.post('attribute', body);

    attributes.push(response.data as IAttribute);
    return { data: attributes };
}

export const updateAttribute = async (body: IAttribute, attributes: IAttribute[]): Promise<ResponseFormat<IAttribute>> => {
    attributes = [...attributes]

    await APP_URL.put(`attribute/${body.id}`, body);

    const elementPos = attributes.map(x => x.id).indexOf(body.id);
    attributes[elementPos!] = body;
    return { data: attributes };
}

export const deleteAttribute = async (body: IAttribute, attributes: IAttribute[]): Promise<ResponseFormat<IAttribute>> => {
    await APP_URL.delete(`attributes/${body.id}`);

    return { data: attributes };
}