import { APP_URL } from  "../../config/axios-config";
import { ISurveyResponse, ISurveyResponseUpdate } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getSurveyResponses = async (): Promise<ResponseFormat<ISurveyResponse>> => {
    const response = await APP_URL.get<ISurveyResponse[]>('/SurveyResponse');
    return { data: response.data };
};

export const getSurveyResponse = async (id: string): Promise<ISurveyResponse> => {
    const response = await APP_URL.get<ISurveyResponse>(`/SurveyResponse/${id}`);
    return response.data;
};


export const updateSurveyResponse = async (id: string, body: ISurveyResponseUpdate): Promise<void> => {
    await APP_URL.put(`/SurveyResponse/${id}`, body);
};

export const createSurveyResponse = async (body: ISurveyResponse): Promise<ISurveyResponse> => {
    const response = await APP_URL.post<ISurveyResponse>('/SurveyResponse', body);
    return response.data;
};

export const deleteSurveyResponse = async (id: string): Promise<ISurveyResponse> => {
    const response = await APP_URL.delete<ISurveyResponse>(`/SurveyResponse/${id}`);
    return response.data;
};

export const updateBatchSurveyResponses = async (body: ISurveyResponseUpdate[]): Promise<void> => {
    await APP_URL.put(`/SurveyResponse/batch`, body);
};


