import { AxiosError } from "axios";
import { APP_URL } from "../../config/axios-config";
import { IUserAuth } from "../../utils/interfaces";

type VerifyUserProp = {
    emailAddress: string, password?: string
}

export const forgotPassword = (body: VerifyUserProp): Promise<IUserAuth> => {
    return new Promise((resolve, reject) => {
        APP_URL.post('userAuth/forgotPassword', body)
            .then(res => resolve(res.data as IUserAuth))
            .catch((err: AxiosError) => reject(err));
    });
};

export const verifyUser = (body: VerifyUserProp): Promise<IUserAuth> => {
    return new Promise((resolve, reject) => {
        APP_URL.post('userAuth/verify-user', body)
            .then(res => resolve(res.data as IUserAuth))
            .catch((err: AxiosError) => reject(err));
    });
};

export const authorizeUser = (): Promise<IUserAuth> => {
    return new Promise((resolve, reject) => {
        APP_URL.get('userAuth/authorize-user')
            .then(res => resolve(res.data as IUserAuth))
            .catch((err: AxiosError) => reject(err));
    });
};

export const getUserAuths = async (queryParameters?: string): Promise<{ data: IUserAuth[] }> => {
    const response = await APP_URL.get(`UserAuth?${queryParameters || ''}`);

    let userAuths: IUserAuth[] = [];

    if (response?.data) {
        userAuths = response.data as IUserAuth[];
    }

    return { data: userAuths };
}
