import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { message } from "antd";
import { IAddress, IMessage } from "../utils/interfaces";
import { addAddress, deleteAddress, getAddresses, updateAddress } from "../services/api-service/address";
import { copyObjects } from "../services";

export class AddressStore {
    addresses = new LoadManager<IAddress>({ data: [] }, getAddresses, addAddress, deleteAddress, updateAddress);

    currentAddress?: IAddress | null;

    constructor() {
        makeObservable(this, {
            addresses: observable,
            currentAddress: observable,
            editAddress: action,
            addAddress: action,
            isLoading: computed,
        })
    }

    get isNew(): boolean {
        return !this.currentAddress;
    }

    editAddress(address?: IAddress) {
        this.currentAddress = address;
    }

    addAddress(): void {
        this.currentAddress = null;
    }

    get isLoading(): boolean {
        return this.addresses.isLoading;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.addresses.error) {
            message.error(errorMsg)
        } else {
            message.success(successMsg)
        }
    }

    // Load addresses from api
    async loadAddresses() {
        this.addresses.fetch();
    }

    // add addresses from api
    async onFormSubmit(address: IAddress) {
        let updatedBody: IAddress | undefined;

        try {
            if (this.currentAddress) {
                updatedBody = copyObjects(this.currentAddress, address);
                await this.addresses.update(updatedBody);
                this.showMessage({
                    successMsg: 'Address updated.',
                    errorMsg: 'Could not update address .',
                });
            } else {
                await this.addresses.add(address);
                updatedBody = this.addresses.value.data[this.addresses.value.data.length - 1];
                this.showMessage({
                    successMsg: 'Address added.',
                    errorMsg: 'Could not add address.',
                });
            }

            this.editAddress(updatedBody);
            return updatedBody;
        } catch (e) {
            console.log('Error updating/adding addresses', e);
        }
    }

    async removeAddress() {
        try {
            if (this.currentAddress) {
                await this.addresses.remove(this.currentAddress)
            }

            this.showMessage({
                successMsg: 'Adress deactivated.',
                errorMsg: 'Could not deactivate address.',
            });
        } catch (e) {
            console.log('Error deleting addresses', e);
        }
    }
}

export default new AddressStore();