import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { observer } from "mobx-react-lite";
import { Store } from "../../stores/stores";
import { Buttons, Files } from "../../components";
import { UploadFile } from "antd/es/upload/interface";

interface BulkEmailSignatureFormProps {
    selectedBranchIds: string[];
    onClose: () => void;
    branch?: { emailSignature?: string };
}

const BulkEmailSignatureForm = observer(({ selectedBranchIds, onClose, branch }: BulkEmailSignatureFormProps) => {
    const { branchesStore } = Store;
    const [form] = Form.useForm();
    const [formData, setFormData] = useState<FormData>(new FormData());
    const [fileListEmailSignature, setFileListEmailSignature] = useState<UploadFile[]>([]);
    const [selectedFile, setSelectedFile] = useState(0);
    
    const [selectedBranches, setSelectedBranches] = useState<Array<{
        id: string;
        name: string;
    }>>([]);

    useEffect(() => {
        const initialBranches = branchesStore.getBranches.data
            .filter(branch => selectedBranchIds.includes(branch.id))
            .map(branch => ({
                id: branch.id,
                name: branch.branchName
            }));
        setSelectedBranches(initialBranches);
    }, [selectedBranchIds, branchesStore.getBranches.data]);

    useEffect(() => {
        if (branch?.emailSignature) {
            setFileListEmailSignature([
                {
                    uid: branch.emailSignature,
                    url: branch.emailSignature,
                    name: "Email Signature",
                },
            ]);
        }
    }, [branch]);

    const handleBulkEmailSignatures = async () => {
        if (selectedBranches.length === 0 || !fileListEmailSignature.length) {
            return;
        }

        try {
            const newFormData = new FormData();
            
            if (fileListEmailSignature.length > 0 && fileListEmailSignature[0].originFileObj) {
                newFormData.append("BulkEmailSignatureImage", fileListEmailSignature[0].originFileObj);
            }

            selectedBranches.forEach((branch) => {
                newFormData.append("BranchIds", branch.id);
            });

            await branchesStore.updateEmailSignatures(newFormData);
            onClose();
            await branchesStore.loadBranches();
        } catch (error) {
            console.error("Error updating email signatures:", error);
        }
    };

    const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
        const newFileList = fileList.map((file) => {
            if (!file.url && !file.preview) {
                file.preview = URL.createObjectURL(file.originFileObj as Blob);
            }
            return file;
        });
        setFileListEmailSignature(newFileList);
    };

    const handleBranchChange = (values: string[]) => {
        const newSelectedBranches = values.map(value => {
            const existingBranch = branchesStore.getBranches.data.find(
                branch => branch.branchName === value
            );

            if (existingBranch) {
                return {
                    id: existingBranch.id,
                    name: existingBranch.branchName
                };
            }

            return {
                id: `temp-${value}`,
                name: value
            };
        });

        setSelectedBranches(newSelectedBranches);
    };

    return (
        <Form form={form} onFinish={handleBulkEmailSignatures}>
            <Form.Item label="Select Branches">
                <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Select or add branches"
                    value={selectedBranches.map(branch => branch.name)}
                    onChange={handleBranchChange}
                >
                    {branchesStore.getBranches.data.map(branch => (
                        <Select.Option 
                            key={branch.id} 
                            value={branch.branchName}
                        >
                            {branch.branchName}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="bulkEmailSignature"
                rules={[{ required: true, message: "Please upload an image" }]}
            >
                <Files.ImagesPreviewUpload
                    name="bulkemailsignatureImage"
                    title="Email Signature Image"
                    maxCount={1}
                    fileList={fileListEmailSignature}
                    setFileList={setFileListEmailSignature}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    onChange={handleFileChange}
                />
            </Form.Item>

            <Form.Item>
                <div className="custom-form-submit">
                    <Buttons.Small
                        htmlType="submit"
                        text="Submit"
                        loading={branchesStore.isLoading}
                        disabled={branchesStore.isLoading || selectedBranches.length === 0}
                    />
                </div>
            </Form.Item>
        </Form>
    );
});

export default BulkEmailSignatureForm;
