import { APP_URL } from  "../../config/axios-config";
import { ISurveyQuestionType } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";


export const getQuestionType = async (id: string): Promise<ISurveyQuestionType> => {
    const response = await APP_URL.get<ISurveyQuestionType>(`/SurveyQuestionType/${id}`);
    return response.data;
};

export const getQuestionTypes = async (): Promise<ResponseFormat<ISurveyQuestionType>> => {
    const response = await APP_URL.get<ISurveyQuestionType[]>('/SurveyQuestionType');
    return { data: response.data };
};

export const updateQuestionType = async (id: string, body: ISurveyQuestionType): Promise<void> => {
    await APP_URL.put(`SurveyQuestionType/${id}`, body);
};

export const createQuestionType = async (body: ISurveyQuestionType): Promise<ISurveyQuestionType> => {
    const response = await APP_URL.post<ISurveyQuestionType>('/SurveyQuestionType', body);
    return response.data;
};

export const deleteQuestionType = async (id: string): Promise<ISurveyQuestionType> => {
    const response = await APP_URL.delete<ISurveyQuestionType>(`/SurveyQuestionType/${id}`);
    return response.data;
};