import { Button } from "antd";
import { CustomButtonProps } from "./Primary";
import "./style.css";

const Small = ({ text, ...rest }: CustomButtonProps) => {
    return (
        <Button className='small-btn' {...rest}>
            {text}
        </Button>
    );
}

export default Small;
