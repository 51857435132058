import { Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Spinners, Drawers } from "../../../components";
import "./style.css";
import { IDeliveryOrder, IOrder, IQuote } from "../../../utils/interfaces";
import { Store } from "../../../stores/stores";
import React from "react";
import { OrderPlatform } from "../../../utils/enums";

export const QuotesManagement = observer(() => {
  const { quotesStore } = Store;

  const compareStrings = (a?: string, b?: string) => {
    if (!a || !b) return false;
    return a.localeCompare(b);
  }

  const orderPlatformString = (platform: OrderPlatform): string | undefined => {
    switch (platform) {
      case OrderPlatform.ANDROID:
        return "Android";
      case OrderPlatform.APPLE:
        return "Apple";
      case OrderPlatform.VODAPAY:
        return "VodaPay";
      case OrderPlatform.WEB:
        return "Web";
      default:
        return undefined; // unknown order platform
    }
  }

  const columns: any = [
    {
      title: 'Quote Ref',
      dataIndex: 'refNo',
      fixed: 'left' as 'left',
      width: 150,
      sorter: (a: IOrder, b: IOrder) => compareStrings(a.refNo, b.refNo),
      render: (text: any) => '#' + text,
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      render: (text: OrderPlatform) => (
        <div>{orderPlatformString(text) ?? '-'}</div>
      )
    },
    {
      title: 'Courier',
      dataIndex: 'deliveryOrders',
      sorter: (a: IQuote, b: IQuote) =>
        compareStrings(a.deliveryOrders[0]?.delivery?.refNo, b.deliveryOrders[0]?.delivery?.refNo),
      render: (text: IDeliveryOrder[], record: any) => (
        <div>{text[0]?.delivery?.refNo}</div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text: string) => (
        <div>{text}</div>
      )
    },
    {
      title: 'Expiry Date',
      dataIndex: 'updatedAt',
      render: (text: string) => (
        <div>{text}</div>
      )
    },
    {
      key: 'edit',
      fixed: 'right' as 'right',
      width: 60,
      render: (text: any, record: IQuote) => (
        <Buttons.Icon
          onClick={() => {
            quotesStore.editQuote(record);
          }}
        />
      )
    },
  ];

  React.useEffect(() => {
    quotesStore.loadQuotes();
  }, []);

  return (
    <main id="home" className='home-content-page'>
      <div className='pageheader-container'>
        <h1 className='header'>Quotes</h1>
        <div className='pageheader-options'>

          <Input
            size='large'
            className='pageheader-options-search orders-search'
            placeholder="Search list..."
            defaultValue={quotesStore.query}
            prefix={<RiSearchLine color='#CBD5E0' />}
            disabled={quotesStore.quotes.isLoading}
            onChange={(_) => {
              quotesStore.setQuery(_.target.value)
            }}
          />
        </div>
      </div>

      <div className='custom-table-container'>
        <Spinners.CustomSpinner
          title={'Getting Orders'}
          isSpinning={quotesStore.quotes.isLoading}
        >
          <Table
            dataSource={quotesStore.filterByQuery.data}
            columns={columns}
            className='custom-table'
            rowKey='id'
            pagination={{
              style: { margin: '16px' }
            }}
          />
        </Spinners.CustomSpinner>
      </div>
      <Drawers.QuoteInfoDrawer />
    </main>
  );
});
