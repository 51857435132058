import { observable, action, computed, makeObservable } from "mobx";
import { IEmailToken } from "../utils/interfaces";
import { message } from "antd";
import { APIService } from "../services";
export class EmailToken {
    currentEmailToken?: IEmailToken;
    verifyingToken = false;
    isLoadingAPI = false;

    emailPattern = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;
    passwordRequirements = 'Minimum 8 characters, 1 upper case, number, 1 special character'

    constructor() {
        makeObservable(this, {
            verifyingToken: observable,
            isLoadingAPI: observable,
            isLoading: computed,
            tokenValid: computed,
            loadEmailToken: action,
            onCreatePassword: action,
            onForgotPassword: action,
            onLinkAccount: action,
        })
    }

    get isLoading() {
        return this.verifyingToken || this.isLoadingAPI;
    }

    get tokenValid(): boolean {
        return !this.isLoading && this.currentEmailToken !== undefined;
    }

    async loadEmailToken(token: string) {
        this.verifyingToken = true;

        try {
            const response = await APIService.EmailToken.getEmailTokenById(token);
            this.currentEmailToken = response;
        } catch (error) {
            console.log('error', error);
        }

        this.verifyingToken = false;
    }

    onLinkAccount = async (emailAddress: string | null): Promise<boolean> => {
        if (!emailAddress) {
            message.error('Could not find email address.');
            return false
        } else {
            try {
                this.isLoadingAPI = true;
                await APIService.LinkedAccount.verifyLinkedNumber({ emailAddress },this?.currentEmailToken!?.id);
                message.success('Account linked.',1000);
                this.isLoadingAPI = false;
                setTimeout(() => {
                    message.destroy();
                }, 3000);
                return true;
            }
            catch (error) {
                message.error('Could not link account number.',3000);
                this.isLoadingAPI = false;
                return false;
            }
        }
    }

    onDeclineAccountLink = async (emailAddress: string | null,declineReason:string) => {
        if (!emailAddress) {
            message.error('Email address not provided.');
            return;
        }

        try {
            this.isLoadingAPI = true;
            await APIService.LinkedAccount.DeclineAccountLink({ emailAddress, declineReason },this?.currentEmailToken!?.id);
            message.success('Account link declined.',1000);
            setTimeout(() => {
                message.destroy();
            }, 3000);
        } catch (error) {
            message.error('Could not decline account link.',3000);
        } finally {
            this.isLoadingAPI = false;
        }
};



    onCreatePassword = async ({ password, confirmPassword }: any, onFinish: () => void) => {
        if (password !== confirmPassword) {
            message.error('Passwords do  not match');
        } else {
            this.isLoadingAPI = true
            try {
                await APIService.EmailToken.resetPassword(this.currentEmailToken!.id, {
                    Password: password,
                })

                this.isLoadingAPI = false;
                message.success('Password created.');
                onFinish();
            } catch (e) {
                message.error('Could not create password.');
                this.isLoadingAPI = false;
            }
        }
    }

    onForgotPassword = async (values: any, onFinish: () => void) => {
        this.isLoadingAPI = true;

        try {
            await APIService.UserAuth.forgotPassword(values);
            this.isLoadingAPI = false;
            message.success('Check email for verification link.');
            onFinish();
        } catch (e) {
            message.error('Could not veify email address.');
            this.isLoadingAPI = false;
        }
    }
}
