import { Input, message } from "antd";
import { AxiosError } from "axios";
import React from "react";
import { useHistory } from "react-router-dom";
import { AppLogo } from "../../assets";
import { Buttons } from "../../components";
import { useAuthContext } from "../../context/AuthContext";
import { APIService } from "../../services";
import { IUserAuth } from "../../utils/interfaces";
import { home_pages } from "../home-pages";
import { PlatformRights } from "../../utils/enums";
import { PageRoute } from "../../utils/types";
import "./style.css";
import { useQuery } from "../../utils/ReactRouter/useQuery";

export const Landing = () => {
    const { authenticateUser, generateAuthKey } = useAuthContext();
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const history = useHistory();
    const query = useQuery();

    const handleReturnUrlQuery = (): boolean => {
        let returnUrl = query.get('returnUrl');
        if (!returnUrl) return false;
        try {
            history.push(atob(returnUrl));
        } catch (error) {
            history.push(returnUrl);
        }
        return true;
    }

    const getFirstRoute = (userAuth: IUserAuth) => {
        if (handleReturnUrlQuery()) {
            return;
        }

        const { userTypeList } = userAuth;

        if (userTypeList?.some((u) => u === PlatformRights.DeactivatedStaff)) {
            message.error('Account deactivated. Please contact administrator.');
            return;
        }

        let enabledRoute: PageRoute | null = null;
        for (let i = 0; i < home_pages.length; i++) {
            const page = home_pages[i];
            const hasAccess = page.platformRights.some(x => userTypeList?.some((u) => u === x));
            if (hasAccess) {
                enabledRoute = page;
                break;
            }
        }

        if (enabledRoute) {
            history.push('/home' + enabledRoute.path);
        } else {
            message.error('Could not grant access.');
        }
    }

    const isStringEmptyOrNull = (value: string) => {
        const valueTrimmed = value.trim();
        return !!valueTrimmed.length
    }

    // TODO: setup login
    const handleSignIn = () => {
        if (isStringEmptyOrNull(email) && !!isStringEmptyOrNull(password)) {
            setIsSubmitting(true);
            const requestBody = {
                emailAddress: email,
                password: password
            };

            APIService.UserAuth.verifyUser(requestBody)
                .then(user => {
                    const authKey = generateAuthKey(user.emailAddress, user.password);
                    authenticateUser(authKey, user);
                    getFirstRoute(user);
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 401) {
                        message.error('Could not sign in.');
                    } else {
                        message.error('Could not sign in.');
                    }
                    console.log(err);
                })
                .finally(() => setIsSubmitting(false));
        } else {
            message.error('Enter all required fields.')
        }
    }

    const hanldeForgotPassword = () => {
        history.push('/forgot-password')
    }

    return (
        <main id="landing">
            <div className='landing-image-container' />

            <div className="landing-login-container">
                <img className='landing-login-applogo' src={AppLogo} alt="G.Fox logo" />

                <div className='landing-login-form'>
                    <Input
                        className='text-input landing-login-form-email'
                        placeholder='Email'
                        size="large"
                        type='email'
                        onChange={(_) => setEmail(_.target.value)}
                    />

                    <Input.Password
                        className='text-input'
                        placeholder='Password'
                        size="large"
                        onChange={(_) => setPassword(_.target.value)}
                    />

                    <div
                        className='forgot-password-btn'
                        onClick={hanldeForgotPassword}
                    >
                        Forgot Password?
                    </div>

                    <Buttons.Primary
                        text={'Sign In'}
                        onClick={() => handleSignIn()}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                    />
                </div>


            </div>

        </main>
    );
}
