// src/stores/CustomPageStore.ts

import { makeObservable, observable, action, computed } from "mobx";
import { message } from "antd";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import {
    addCustomPage,
    deleteCustomPage,
    getCustomPages,
    updateCustomPage,
    addPreview,
    publishPage,
} from "../services/api-service/custom-page";
import { ICustomPage, IMessage } from "../utils/interfaces";

export class CustomPageStore {
    customPages = new LoadManager<ICustomPage>({ data: [] }, getCustomPages, addCustomPage, deleteCustomPage, updateCustomPage);
    searchQuery = '';
    showPanel = false;
    showFormsPanel = false;
    currentCustomPage?: ICustomPage;

    constructor() {
        makeObservable(this, {
            customPages: observable,
            searchQuery: observable,
            showPanel: observable,
            showFormsPanel: observable,
            currentCustomPage: observable,
            isLoading: computed,
            filteredQueryResults: computed,
            tooglePanel: action,
            toogleFormsPanel: action,
            editCustomPage: action,
            resetCustomPageForm: action,
            setSearchQuery: action,
            submitCustomPage: action,
            submitPreview: action,
            publishCustomPage: action,
            removeCustomPage: action,
        });
    }

    get isLoading(): boolean {
        return this.customPages.isLoading;
    }

    get filteredQueryResults(): ICustomPage[] {
        const query = this.searchQuery.trim().toLowerCase();
        return (
            this.customPages.value?.data || []
        ).filter(page =>
            page.name?.toLowerCase?.()?.includes(query) ?? false
        );
    }

    loadCustomPages() {
        this.customPages.fetch();
    }

    tooglePanel(v: boolean) {
        this.showPanel = v;
    }

    toogleFormsPanel(v: boolean) {
        this.showFormsPanel = v;
    }

    editCustomPage(customPage?: ICustomPage) {
        this.showFormsPanel = true;
        this.currentCustomPage = customPage;
    }

    resetCustomPageForm() {
        this.showFormsPanel = false;
        this.currentCustomPage = undefined;
    }

    setSearchQuery(query: string) {
        this.searchQuery = query;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.customPages.error || this.customPages.error) {
            message.error(errorMsg);
        } else {
            message.success(successMsg);
        }
    }

    async submitCustomPage(formData: FormData) {
        try {
            if (this.currentCustomPage !== undefined) {
                await this.customPages.update(formData);
                this.showMessage({
                    successMsg: 'Custom page updated.',
                    errorMsg: 'Could not update custom page.',
                });
                this.showFormsPanel = false;
            } else {
                await this.customPages.add(formData);
                this.showMessage({
                    successMsg: 'Custom page added.',
                    errorMsg: 'Could not add custom page.',
                });
                this.showFormsPanel = false;
                this.showPanel = false;
            }
            this.loadCustomPages();
        } catch (e) {
            console.log('Error updating/adding custom page', e);
        }
    }

    async submitPreview(formData: FormData) {
        try {
            await addPreview(formData);
            await this.loadCustomPages();
            this.showMessage({
                successMsg: 'Preview saved successfully.',
                errorMsg: 'Could not save preview.',
            });
        } catch (e) {
            console.error('Error saving preview:', e);
            message.error('Failed to save preview.');
        }
    }

    async publishCustomPage(formData: FormData) {
        try {
            await publishPage(formData);
            this.showMessage({
                successMsg: 'Custom page published successfully.',
                errorMsg: 'Could not publish custom page.',
            });
            this.loadCustomPages();
        } catch (e) {
            console.error('Error publishing custom page:', e);
            message.error('Failed to publish custom page.');
        }
    }

    async removeCustomPage(customPage: ICustomPage) {
        try {
            await this.customPages.remove(customPage);
            this.showMessage({
                successMsg: 'Custom page removed.',
                errorMsg: 'Could not remove custom page.',
            });
            this.loadCustomPages();
            this.showFormsPanel = false;
        } catch (e) {
            console.log('Error removing custom page', e);
        }
    }
}
