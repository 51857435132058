import { observable, action, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { getQuestionTypes, getQuestionType, createQuestionType, updateQuestionType, deleteQuestionType } from "../services/api-service/survey-question-type";
import { ISurveyQuestionType } from "../utils/interfaces";


export class SurveyQuestionTypeStore {
    hrQuestionTypes = new LoadManager<ISurveyQuestionType>({data:[]}, getQuestionTypes);
    currentQuestionType: ISurveyQuestionType | null = null;

    constructor(){
        makeObservable(this,{
            currentQuestionType: observable,
            hrQuestionTypes: observable,
            loadQuestionTypes: action,
            loadQuestionType: action,
            createQuestionType: action,
            updateQuestionType: action,
            deleteQuestionType: action,
        })
    }
    
    async loadQuestionTypes() {
        await this.hrQuestionTypes.fetch()
    }

    async loadQuestionType(id: string) {
        try {
            this.currentQuestionType = await getQuestionType(id);
        } catch (e) {
            console.log('Error fetching question type', e);
        }
    }

    async createQuestionType(body: ISurveyQuestionType) {
        try {
            await createQuestionType(body);
            this.loadQuestionTypes();
        } catch (e) {
            console.log('Error creating question type', e);
        }
    }

    async updateQuestionType(id: string, body: ISurveyQuestionType) {
        try {
            if(this.currentQuestionType) {
                await updateQuestionType(id, body);
                this.loadQuestionTypes();
            }
        } catch (e) {
            console.log('Error updating question type', e);
        }
    }

    async deleteQuestionType(id: string) {
        try {
            await deleteQuestionType(id);
            this.loadQuestionTypes();
        } catch (e) {
            console.log('Error deleting question type', e);
        }
    }
}
