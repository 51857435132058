import Icon from "@ant-design/icons";

const Svg = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 6.66666H7V13.3333H15V6.66666Z" stroke-miterlimit="10" stroke-linecap="square" />
    <path d="M19 13.3333H11V20H19V13.3333Z" stroke-miterlimit="10" stroke-linecap="square" />
    <path d="M11 13.3333H3V20H11V13.3333Z" stroke-miterlimit="10" stroke-linecap="square" />
    <path d="M1 5.66667L11 1L21 5.66667" stroke-miterlimit="10" />
    <path d="M8 13.3333V16.3333H6V13.3333" stroke-miterlimit="10" stroke-linecap="square" />
    <path d="M16 13.3333V16.3333H14V13.3333" stroke-miterlimit="10" stroke-linecap="square" />
    <path d="M12 6.66666V9.66666H10V6.66666" stroke-miterlimit="10" stroke-linecap="square" />
  </svg>
);

const InventoryManagementIcon = ({ ...props }) => {
  return <Icon component={Svg} {...props} />
};

export default InventoryManagementIcon;
