import React from "react"

export interface IOrgContext {
    globalUserAuths?: any[],
    setGlobalUserAuths?: any,
    globalBranches?: any[],
    setGlobalBranches?: any,
    globalCustomers?: any[],
    setGlobalCustomers?: any,
}

const OrgContext = React.createContext<IOrgContext>({})

export const useOrgContext = () => React.useContext(OrgContext);

export const GlobalProvider = ({ children }: any) => {
    const [globalUserAuths, setGlobalUserAuths] = React.useState<any[]>();
    const [globalBranches, setGlobalBranches] = React.useState<any[]>();
    const [globalCustomers, setGlobalCustomers] = React.useState<any[]>();

    return (
        <OrgContext.Provider
            value={{
                globalUserAuths,
                setGlobalUserAuths,
                globalBranches,
                setGlobalBranches,
                globalCustomers,
                setGlobalCustomers,
            }}
        >
            {children}
        </OrgContext.Provider>
    );
}
