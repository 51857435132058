import { Button } from "antd";
import { CustomButtonProps } from "./Primary";
import "./style.css";

const Text = ({ text, ...rest }: CustomButtonProps) => {
    return (
        <Button className='text-btn' type='text' {...rest}>
            {text}
        </Button>
    );
}

export default Text;