import { Form, FormInstance, Input, message } from "antd";
import React from "react";
import { Buttons } from "..";
import { copyObjects } from "../../services";
import { IBranchCod } from "../../utils/interfaces";

type NewBranchCodFormProp = {
    codAccount?: IBranchCod,
    onFormFinish: (cod: IBranchCod) => void
    isLoading: boolean
};

const NewBranchCodForm = ({ codAccount, isLoading, onFormFinish }: NewBranchCodFormProp) => {
    const formRef = React.createRef<FormInstance>();

    const initialValues =  copyObjects({}, codAccount);

    const validateMessages = {
        required: "Field is required!",
    };

    const formData = [
        { name: 'codAccountNumber', label: "Account Number", component: Input, required: true },
        { name: 'name', label: "Name", component: Input, required: true },
        { name: 'bankName', label: "Bank Name", component: Input, required: true },
        { name: 'bankBranch', label: "Bank Branch", component: Input, required: true },
        { name: 'bankBranchCode', label: "Bank Branch Code", component: Input, required: true },
        { name: 'bankAccountNumber', label: "Bank Account Number", component: Input, required: true },
        { name: 'bankSwiftNumber', label: "Bank Swift Number", component: Input, required: true },
    ];

    const onFinish = (values: any) => {
        onFormFinish(values);
    };

    const onFinishFailed = (errorInfo: any) => {
        if (!codAccount) {
            message.error('Could not add new COD details.')
        } else {
            message.error('Could not update COD details.')
        }
    };

    return (
        <>
            <Form
                ref={formRef}
                name='new-branchcod'
                className='custom-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={initialValues}
            >
                {formData.map(({ component: Component, ...item }, index) =>
                    <Form.Item
                        key={index}
                        rules={[{ required: item.required }]}
                        {...item}
                    >
                        <Component />
                    </Form.Item>
                )}

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={codAccount ? 'Update branch COD' : 'Add branch COD'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
};

export default NewBranchCodForm;