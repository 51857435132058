import { Form, FormInstance, Input, InputNumber, message, Switch, Upload, UploadFile } from "antd";
import React, { useEffect, useState } from "react";
import { Buttons, Files, Search } from "..";
import { EmailService, copyObjects } from "../../services";
import { IBranch, ISysproWarehouse } from "../../utils/interfaces";
import { PopupConfirm } from "../Modals";
import { DebounceSelectProp } from "../Search/DebounceSearch";
import { UploadOutlined } from "@ant-design/icons";

type NewBranchFormProp = {
    branch?: IBranch | null,
    onFormFinish: (branch: FormData) => void
    onBranchDelete: () => void
    onWarehouseSearch: (query: string) => Promise<any[]>;
    isLoading: boolean
};

const NewBranchForm = ({ branch, isLoading, onBranchDelete, onFormFinish, onWarehouseSearch }: NewBranchFormProp) => {

    const initialEmailImageValue = (branch?:IBranch | null):UploadFile[] => {
        if (!branch)        {
                return [];
            }
    
        return [{uid:branch?.emailSignature ?? '',
            url: branch?.emailSignature, 
            name: branch?.emailSignature ?? ''}]
    };

    const formRef = React.createRef<FormInstance>();
    const warehouseRef = React.useRef<Partial<ISysproWarehouse>>({});
    const [fileListEmailSignature, setFileListEmailSignature] = useState<UploadFile[]>(initialEmailImageValue(branch));

    const initialValues = copyObjects(branch, { sysproWarehouseName: branch?.sysproWarehouse?.name });

    const validateMessages = {
        required: "Field is required!",
    };

    useEffect(() => {
        if (branch?.emailSignature) {
            setFileListEmailSignature([{
                uid: branch.emailSignature,
                url: branch.emailSignature,
                name: 'Email Signature',
            }]);
        }
    }, [branch]);

    const BranchSelect = ({ ...props }) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={onWarehouseSearch}
            onSelect={(value: any, option: any) => {
                warehouseRef.current.name = option.key as string;
            }}
            {...props}
        />
    );

    const EmailSignatureUpload = (
        <Files.ImagesPreviewUpload
            name='emailsignatureimage'
            title={'Email Signature Image'}
            maxCount={1}
            fileList={fileListEmailSignature}
            setFileList={setFileListEmailSignature}
            selectedFile={0}
            setSelectedFile={() => {}}
        />
    );

    const branchFormData = [
        { name: 'refNo', label: "Ref #", component: Input, required: true },
        { name: 'branchName', label: "Branch name", component: Input, required: true },
        { name: 'areaOfService', label: "Area Of Service", component: Input, required: true },
        { name: 'manager', label: "Manager", component: Input, required: true },
        { name: 'telNumber', label: "Tel. number", component: Input, required: true },
        { name: 'faxNumber', label: "Fax number", component: Input, required: true },
        { name: 'emailAddress', label: "Email", component: Input, required: true },
        { name: 'salesEmailAddress', label: "Sales email", component: Input, required: true },
        { name: 'embroideryEmail', label: "Embroidery email", component: Input, required: true },
        { name: 'sysproCompany', label: "SysPro Company", component: Input, required: true },
        { name: 'quoteThreshold', label: "Quote Threshold", component: InputNumber, required: false },
        { name: 'sysproWarehouseName', label: "SysPro Warehouse", component: BranchSelect, required: false },
        { name: 'active', label: "Active", component: Switch, valuePropName: "checked", required: false },
        { name: 'emailsignatureimage', label: "Email Signature Image",component: () => EmailSignatureUpload, required: false },
                // { name: 'location', label: "Location", component: Input, required: true },
    ];

    const emailsValid = (emails: string[]) => {
        let inValid = emails.filter(x => !EmailService.isEmailValid(x));
        return inValid.length === 0;
    };

    const onFinish = (values: any) => {
        const emails = [
            values.emailAddress,
            values.salesEmailAddress,
            values.embroideryEmail,
        ];

        if (emailsValid(emails)) {
            const formData = new FormData();
            Object.keys(values).forEach(key => {
                if (key !== 'emailsignatureimage') {
                            formData.append(key, values[key]);
                        }
                });
            if (branch?.id) {
                formData.append('id', branch.id);
            }

        if (fileListEmailSignature.length > 0 && fileListEmailSignature[0].originFileObj) {
            formData.append('emailsignatureimage', fileListEmailSignature[0].originFileObj);
        }

            onFormFinish(formData);
        } else {
            message.error('Enter a valid email address');
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        if (!branch) {
            message.error('Could not add new branch.')
        } else {
            message.error('Could not update branch.')
        }
    };


    return (
        <>
            {branch &&
                <div className='form-delete-container'>
                    <PopupConfirm
                        isLoading={isLoading}
                        onConfirm={() => onBranchDelete()}
                    />
                </div>
            }

            <Form
                ref={formRef}
                name='new-branch'
                className='custom-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={initialValues}
            >
                {branchFormData.map(({ component: Component, ...item }, index) =>
                    <Form.Item
                        key={index}
                        rules={[{ required: item.required }]}
                        {...item}
                    >
                        <Component />
                    </Form.Item>
                )}

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={branch ? 'Update branch' : 'Add branch'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
};

export default NewBranchForm;
