import { Drawer, Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import { RiSearchLine } from "react-icons/ri";
import { Store } from "../../stores/stores";
import { IPaystackTransactionRequest } from "../../utils/interfaces";

const TransactionDrawer = observer(() => {
    const { payStore } = Store;

    const columns = [
        {
            title: "Order No",
            dataIndex: "orderRefNo",
            fixed: 'left' as 'left',
            width: 140,
            render: (text: any) =>
                <div>{text ? `#${text}` : ''}</div>
        },
        {
            title: "Status",
            key: "status",
            render: (text: any, record: IPaystackTransactionRequest) =>
                <div>{record?.data?.status ? `${record.data.status}` : ''}</div>
        },
        {
            title: "Reference",
            key: "reference",
            render: (text: any, record: IPaystackTransactionRequest) =>
                <div>{record?.data?.reference ? `${record.data.reference}` : ''}</div>
        },
        {
            title: "Currency",
            key: "currency",
            render: (text: any, record: IPaystackTransactionRequest) =>
                <div>{record?.data?.currency ? `${record.data.currency}` : ''}</div>
        },
        {
            title: "Amount (cents)",
            key: "amount",
            render: (text: any, record: IPaystackTransactionRequest) =>
                <div>{record?.data?.amount ? `${(Number(record.data.amount) / 100).toFixed(2)}` : ''}</div>
        },
        {
            title: "Fees (cents)",
            key: "fees",
            render: (text: any, record: IPaystackTransactionRequest) =>
                <div>{record?.data?.fees ? `${(Number(record.data.fees) / 100).toFixed(2)}` : ''}</div>
        },
        {
            title: "Channel",
            key: "channel",
            render: (text: any, record: IPaystackTransactionRequest) =>
                <div>{record?.data?.channel ? `${record.data.channel}` : ''}</div>
        },
    ];

    return (
        <Drawer
            className='custom-drawer'
            title="Transactions"
            visible={payStore.showTransactionPanel}
            onClose={(_) => payStore.toggleTransactionPanel(false)}
            width="60%"
            children={(
                <>
                    <Input
                        className='pageheader-options-search orders-search'
                        placeholder="Search list..."
                        defaultValue={payStore.transactionQuery}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        disabled={payStore.paystackTransactions.isLoading}
                        onChange={(_) => payStore.setTransactionQuery(_.target.value)}
                        style={{ marginBottom: '2em', width: '50%' }}
                    />
                    <div className='custom-table-container'>
                        <Table
                            rowKey='id'
                            className='custom-table contractpricing-table'
                            columns={columns}
                            scroll={{ x: 2000 }}
                            dataSource={payStore.filterPaystackTransactions.slice()}
                            pagination={false}
                        />
                    </div >
                </>
            )}
        />
    )
});

export default TransactionDrawer;
