import { Avatar, Badge, Button, Popover } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { Store } from "../../stores/stores";
import { IOrderMessage } from "../../utils/interfaces";
import "./style.css";
import { PlatformRights } from "../../utils/enums";
import { MenuUnfoldOutlined, MenuFoldOutlined, NotificationOutlined } from "@ant-design/icons";
import { useQuery } from "../../utils/ReactRouter/useQuery";
import { Drawers, ExpandedTables } from "..";

const Main = observer(({
    collapsed,
    setCollapsed
}: {
    collapsed: boolean,
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [fullName, setFullName] = React.useState<any>();
    const [orderMsgVisible, setOrderMsgVisible] = React.useState(false)
    const { currentUser } = useAuthContext();
    const query = useQuery();

    const { notificationsStore, ordersStore } = Store;
    const orderMessages = notificationsStore.unreadList;

    const getAvatar = () => {
        return `https://ui-avatars.com/api/?name=${fullName}background=000&color=D8232A`
    };

    const onOrderMessageClick = async ({ orderId, ...msg }: IOrderMessage) => {
        await ordersStore.onUnreadMessage(orderId!);
        notificationsStore.markOrderMessageAsRead(orderId!);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUrlQuery = async () => {
        const openMessage = query.get("openMessage")
        const orderId = query.get("orderId")
        if (openMessage === 'true' && orderId !== undefined) {
            await ordersStore.onUnreadMessage(orderId!);
        }
    };

    const popoverContent = (
        <div className="notification-container">
            <div className="notification-btn">
                <Button
                    className="text-btn"
                    type='text'
                    onClick={() => setOrderMsgVisible(true)}
                >
                    See all
                </Button>
            </div>
            {orderMessages.length === 0 && (
                <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    No unread messages
                </p>
            )}
            {orderMessages.map(msg => (
                <div
                    key={msg.id}
                    className='notification'
                    onClick={() => {
                        onOrderMessageClick(msg)
                    }}
                >
                    {`#${msg.order?.refNo ?? ''} ${msg.message}`}
                </div>
            ))}
        </div>
    );

    useEffect(() => {
        handleUrlQuery();
    }, [handleUrlQuery, query]);

    React.useEffect(() => {
        if (currentUser) {
            setFullName(`${currentUser?.firstName} ${currentUser?.lastName}`);
        }
    }, [currentUser]);

    return (
        <nav id="navbar-main">
            <div className='navbar-main-details'>
                <div className="navbar-collapsed-menu-container">
                    {collapsed ?
                        <MenuUnfoldOutlined
                            className="navbar-collapsed-menu"
                            onClick={() => setCollapsed(!collapsed)}
                        /> :
                        <MenuFoldOutlined
                            className="navbar-collapsed-menu"
                            onClick={() => setCollapsed(!collapsed)}
                        />
                    }
                </div>
                <div>
                    <div className='navbar-main-details-name'>{fullName}</div>
                    <div className='navbar-main-details-role'>
                        {Object.values(PlatformRights)
                            .filter(x => currentUser?.userTypeList.some(t => t === x))}
                    </div>
                </div>
            </div>

            <div>
                <Avatar size={54} src={getAvatar()} />
            </div>

            <div
                style={{ marginLeft: "1em", cursor: 'pointer' }}
            >
                <Popover
                    content={popoverContent}
                >
                    <Badge count={orderMessages.length} size="small">
                        <NotificationOutlined
                            style={{ fontSize: 16 }}
                            onClick={() => setOrderMsgVisible(true)}
                        />
                    </Badge>
                </Popover>
            </div>

            <Drawers.CustomDrawer
                title="Order Messages"
                visible={orderMsgVisible}
                setVisible={(_) => setOrderMsgVisible(_)}
                width="60%"
                children={() => (
                    <ExpandedTables.OrderMessagesTable
                        isLoading={false}
                        orderMessages={orderMessages}
                        onViewMessage={(ordMsg) => onOrderMessageClick(ordMsg)}
                    />
                )}
            />
        </nav >
    );
});

export default Main;