import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { message } from "antd";
import { ICODAccountNumber, IMessage } from "../utils/interfaces";
import { addCODAccountNumber, deleteCODAccountNumber, getCODAccountNumbers, updateCODAccountNumber } from "../services/api-service/cod-account-number";

export class CODAccountNumberStore {
    codAccountNumbers = new LoadManager<ICODAccountNumber>({ data: [] }, getCODAccountNumbers, addCODAccountNumber, deleteCODAccountNumber, updateCODAccountNumber);
    currentCODAccountNumber?: ICODAccountNumber | undefined;
    showPanel = false;
    searchQuery = '';
    showFormsPanel = false;

    constructor() {
        makeObservable(this, {
            codAccountNumbers: observable,
            currentCODAccountNumber: observable,
            showPanel: observable,
            showFormsPanel: observable,
            searchQuery: observable,
            editCODAccountNumber: action,
            addCODAccountNumber: action,
            togglePanel: action,
            setSearchQuery: action,
            filteredCODAccountNumbers: computed,
            isLoading: computed,
            resetCODForm: action,
        });
    }

    get isNew(): boolean {
        return !this.currentCODAccountNumber;
    }

    editCODAccountNumber(codAccountNumber?: ICODAccountNumber) {
        this.currentCODAccountNumber = codAccountNumber;
        this.showFormsPanel = true;
    }

    addCODAccountNumber() {
        this.showFormsPanel = true;
    }

    togglePanel(visible: boolean) {
        this.showPanel = visible;
    }
    
    toogleFormsPanel(v: boolean) {
        this.showFormsPanel = v;
    }

    setSearchQuery(query: string) {
        this.searchQuery = query;
    }

    resetCODForm() {
        this.showFormsPanel = false;
        this.currentCODAccountNumber = undefined;
    }
    get filteredCODAccountNumbers() {
        const query = this.searchQuery ? this.searchQuery.toLowerCase() : '';
        return this.codAccountNumbers.value.data.filter(sn =>
            sn.accountNumber && sn.accountNumber.toLowerCase().includes(query)
        );
    }

    get isLoading(): boolean {
        return this.codAccountNumbers.isLoading;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.codAccountNumbers.error) {
            message.error(errorMsg);
        } else {
            message.success(successMsg);
            this.resetCODForm();
        }
    }

    // Load codaccount numbers from API
    async loadCODAccountNumbers() {
        this.codAccountNumbers.fetch();
    }


    // Add or update COD account number
    async onFormSubmit(codaccountNumber: ICODAccountNumber) {
        let updatedBody: ICODAccountNumber | undefined;

        try {
            if (this.currentCODAccountNumber) {
                updatedBody = { ...this.currentCODAccountNumber, ...codaccountNumber };
                const response = await updateCODAccountNumber(updatedBody, this.codAccountNumbers.value.data);
                this.codAccountNumbers.value.data = response.data;
                this.showMessage({
                    successMsg: 'COD account number updated.',
                    errorMsg: 'Could not update COD account number.',
                });
                this.showFormsPanel = false;
            } else {
                const response = await addCODAccountNumber(codaccountNumber, this.codAccountNumbers.value.data);
                updatedBody = response.data[response.data.length - 1];
                this.codAccountNumbers.value.data = response.data;
                this.showMessage({
                    successMsg: 'COD account number added.',
                    errorMsg: 'Could not add COD account number.',
                });
                this.showFormsPanel = false;
            }
            return updatedBody;
        } catch (e) {
            console.log('Error updating/adding COD account numbers', e);
            this.showMessage({
                successMsg: '',
                errorMsg: 'An error occurred while saving the COD account number.',
            });
            this.showFormsPanel = false;
        }
    }
    async removeCODAccountNumber() {
        try {
            if (this.currentCODAccountNumber) {
                await this.codAccountNumbers.remove(this.currentCODAccountNumber);
            }

            this.showMessage({
                successMsg: 'CODAccount number removed.',
                errorMsg: 'Could not remove codaccount number.',
            });
        } catch (e) {
            console.log('Error deleting codaccount numbers', e);
        }
    }
}

export default new CODAccountNumberStore();
