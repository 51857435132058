import { Form, Input, message } from "antd";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { AppLogo } from "../../../assets";
import { Buttons } from "../../../components";
import { Store } from "../../../stores/stores";
import "./style.css";

const ForgotPassword = observer(() => {
    const history = useHistory();
    const { emailTokensStore } = Store;

    const onFinish = () => history.replace('/');

    const onFinishFailed = (errorInfo: any) => {
        message.error('Could not create password.');
    };

    return (
        <main id="forgot-verification">
            <img className='account-verification-applogo' src={AppLogo} alt="G.Fox logo" />

            <h1 className='header account-verification-header'>Forgot Password</h1>

            <div className='landing-login-form'>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={(values => emailTokensStore.onForgotPassword(values, onFinish))}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="emailAddress"
                        rules={[{
                            required: true,
                            message: 'Enter an email address.',
                            pattern: emailTokensStore.emailPattern,
                        }]}
                    >
                        <Input
                            className='text-input account-verification-input'
                            placeholder='Email Address'
                            size="large"
                        />
                    </Form.Item>

                    <Buttons.Primary
                        text={'RESET PASSWORD'}
                        htmlType='submit'
                        loading={emailTokensStore.isLoading}
                        disabled={emailTokensStore.isLoading}
                    />
                </Form>
            </div>
        </main>
    );
});

export default ForgotPassword;