import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React from "react";
import { Buttons, Spinners } from "..";
import { IOrderMessage } from "../../utils/interfaces";
import "./style.css";

export type StockAvailabilityTableProp = {
    isLoading: boolean;
    orderMessages: IOrderMessage[];
    onViewMessage(ordMsg: IOrderMessage): void,
}

const OrderMessagesTable = ({
    isLoading,
    orderMessages,
    onViewMessage,
}: StockAvailabilityTableProp) => {

    const formatDate = (date?: string, withTime = false) => {
        if (!date) {
            return " ";
        }
        const newDate = new Date(date);
        let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        if (withTime) {
            options.hour = 'numeric';
            options.minute = 'numeric';
        }
        return new Intl.DateTimeFormat("en-GB", options).format(newDate);
    };


    const columns = [
        {
            title: "Order #",
            dataIndex: "orderRefNo",
            render: (text: any, record: IOrderMessage) => (
                <>
                    <div>{record?.order?.refNo ?? ''}</div>
                </>
            )
        },
        {
            title: "Message",
            dataIndex: "message",
            render: (text: any, record: IOrderMessage) => (
                <>
                    <div>{text}</div>
                </>
            )
        },
        {
            title: "Read",
            dataIndex: "readyByUser",
            render: (text: any, record: IOrderMessage) => (
                <div>
                    {text ? (
                        <CheckOutlined style={{ color: 'green' }} />
                    ) : (
                        <CloseOutlined style={{ color: 'red' }} />
                    )}
                </div>
            )
        },
        {
            title: "Sent at",
            dataIndex: "sentAt",
            render: (text: any, record: IOrderMessage) => (
                <div>{text ? formatDate(text, true) : ''}</div>
            )
        },
        {
            title: "View",
            key: "view",
            render: (text: any, record: IOrderMessage) => (
                <Buttons.Text
                    text="View"
                    onClick={() => onViewMessage(record)}
                />
            )
        }
    ];

    return (
        <Spinners.CustomSpinner
            title="Getting Order Messages"
            isSpinning={isLoading}
        >
            <Table
                rowKey='id'
                className='custom-table masterstock-table'
                columns={columns}
                dataSource={[...orderMessages]}
                pagination={false}
            />
        </Spinners.CustomSpinner>
    );
}

export default OrderMessagesTable;