import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { getRatings, deleteRatings, updateRating } from "../services/api-service/ratings";
import { IMessage, IRatingModel } from "../utils/interfaces";
import { message } from "antd";
import { ResponseFormat } from "../utils/types";
import { APP_URL } from "../config/axios-config";

export class ratingsStore {
    ratings = new LoadManager<IRatingModel>({ data: [] }, getRatings, deleteRatings);

    query = "";
    showPanel = false;
    currentRating: IRatingModel | null = null;

    constructor() {
        makeObservable(this, {
            ratings: observable,
            query: observable,
            setQuery: action,
            getRatings: computed,
            showPanel: observable,
            currentRating: observable,
            panelTitle: computed,
            addNew: action,
            showRating: action,
            resetForm: action,
            isLoading: computed,
            loadRatings: action,
            removeRatings: action,
        })
    }

    setQuery(s: string) {
        this.query = s.trim();
    }

    get getRatings(): ResponseFormat<IRatingModel> {
        if (this.query.length === 0) {
            return this.ratings.value;
        } else {
            const data = this.ratings.value.data.filter(rating => {
                return (
                    String(rating?.firstName).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(rating?.comment).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(rating?.createdAt).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(rating?.value).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(rating?.skuMaster?.sku).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(rating?.moderated).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                )
            });

            return { data: data }
        }
    }

    get panelTitle(): string {
        return 'Moderate review';
    }

    get isNew(): boolean {
        return !this.currentRating;
    }

    addNew() {
        this.showPanel = true;
        this.currentRating = null;
    }

    showRating(staff: any) {
        this.showPanel = true;
        this.currentRating = staff;
    }

    resetForm() {
        this.showPanel = false;
        this.currentRating = null;
    }

    get isLoading(): boolean {
        return this.ratings.isLoading;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.ratings.error) {
            message.error(errorMsg)
        } else {
            message.success(successMsg)
            this.resetForm();
        }
    }

    // Load staff from api
    loadRatings() {
        this.ratings.fetch()
    }

    async removeRatings() {
        try {
            if (this.currentRating) {
                await this.ratings.remove(this.currentRating)
            }

            this.showMessage({
                successMsg: 'Ratings and Reviews has been deactivated.',
                errorMsg: 'Could not deactivate Ratings and Reviews ',
            });

            this.loadRatings();
            this.resetForm();
        } catch (e) {
            console.log('Error deleting staff', e);
        }
    }

      // eslint-disable-next-line class-methods-use-this
  async updateRating(id: string, ratingModel: IRatingModel): Promise<boolean> {
    const hide = message.loading('Updating rating...');
    let isSuccess = false;
    try {
      await updateRating(id, ratingModel);
      isSuccess = true;
      message.success('Moderation applied');
    } catch (error) {
      console.error('Error moderating rating:', error);
      message.error('Could not moderate rating. Please try again.');
    }
    hide();
    return isSuccess;
  }
}