import { Drawer, } from "antd";
import React from "react";
import "./style.css";

export type CustomDrawerDrawerProp = {
    visible: boolean,
    setVisible: (state: boolean) => void,
    title?: any,
    children: any,
    width?: any
    className?: string,
}

const CustomDrawer = ({ visible, setVisible, title, children: Component, className,...rest }: CustomDrawerDrawerProp) => {
    const onClose = () => {
        setVisible(false);
    };

    return (
        <Drawer
            title={title}
            className={`custom-drawer ${className}`}
            placement="right"
            onClose={onClose}
            visible={visible}
            destroyOnClose={true}
            width={'50%'}
            {...rest}
        >
            <Component />
        </Drawer>
    );
}

export default CustomDrawer;
