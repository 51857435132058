import axiosNS from "axios";
import { config } from "process";

export const APP_URL = axiosNS.create({
    baseURL:process.env.REACT_APP_GFOX_API_BASE_URL,
});

export const axiosInterceptor = async ({ authKey = null }: any) => {
    const authHeader = authKey ? `Basic ${authKey}` : null;

   
    await APP_URL.interceptors.request.use(config => {

        config.headers.Authorization = authHeader
         config.headers['X-Is-Admin'] = true;
        return config;
    });
}
