import { SendOutlined } from "@ant-design/icons";
import { Empty, Image, Input, List, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import { Drawers, ExpandedTables, Forms, Spinners } from "..";
import { useAuthContext } from "../../context/AuthContext";
import { OrderStatus } from "../../services";
import { Store } from "../../stores/stores";
import { StatusCodes } from "../../utils/enums";

const OrderedItems = observer(() => {
    const { ordersStore } = Store;
    const currentOrder = ordersStore.currentOrder;
    const inventoryList = ordersStore.inventoryList;

    return (
        <div>
            <div className='order-tab-heading'>
                Order NO. {currentOrder?.refNo || ''}
                <div>
                    Order Date: {moment(currentOrder?.dateOrdered).format("DD MMM yyyy")}
                </div>
                Courier information: {currentOrder?.deliveryOrders[0]?.delivery.description}
                <div className='order-spacing'>
                    Comments:
                    <div className='order-tab-comments'>
                        {currentOrder?.comments ?? "N/A"}
                    </div>
                </div>
                <div className='order-spacing'>
                    Order:
                </div>
            </div>
            <Spinners.CustomSpinner
                title=""
                isSpinning={ordersStore.isLoading}
            >
                {inventoryList?.map(item => (
                    <div className='product-border'>
                        <div className='create-flex order-product-image'>
                            <Image
                                className='image-style'
                                src={item.productImages[0]}
                                alt="product"
                                preview={false}
                                fallback='https://storage.googleapis.com/gfox-public/images/fallback.png'
                            />
                        </div>
                        <div className='product-text-margin'>
                            <div className='coloumn-flex'>
                                <div className='heading1'>
                                    {item.description}
                                </div>
                                {item.categorySpecificInfo?.map(x => (
                                    <div className='heading2'>
                                        {x.name}: {x.value}
                                    </div>
                                ))}
                            </div>
                            <div className='heading3 order-product-info'>
                                <div>R{item.orderInfo.price.toFixed(2)}</div>
                                <div>x{item.orderInfo.orderQty}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </Spinners.CustomSpinner>
        </div>
    )
});

const OrderInfoDrawer = observer(() => {
    const { currentUser } = useAuthContext();
    const { notificationsStore, ordersStore } = Store;

    const [isEmptyOrderRefNo, setIsEmptyOrderRefNo] = useState(false);

    const { showLabelsPanel, currentOrder, activeTab } = ordersStore;
    const messages = notificationsStore.orderMessages.value.data;
    const statusHistory = ordersStore.statusHistory;
    const { TabPane } = Tabs;

    const formatSentAt = (sentAt: string | Date | null) => {
        return moment.utc(sentAt).local().format('DD-MM-YYYY HH:mm');
    };

    const sendMessage = async () => {
        await notificationsStore.sendOrderMessage(currentUser!.id, currentOrder!.id);
    }

    const drawerHeader = (
        <div>
            <div>Orders</div>
            <div className="orders-drawer-header-sutitle">
                <div>Invoice Number: #{currentOrder?.refNo}</div>
                <div>Client: {currentOrder?.client?.userAuth?.firstName} {currentOrder?.client?.userAuth?.lastName}</div>
            </div>
        </div>
    );

    useEffect(() => {
        setIsEmptyOrderRefNo(!currentOrder?.refNo);
    }, [currentOrder])

    return (
        <Drawers.CustomDrawer
            title={drawerHeader}
            visible={ordersStore.showPanel}
            setVisible={(_) => ordersStore.resetForm()}
            children={() => (
                <Tabs
                    activeKey={activeTab}
                    onChange={(_) => ordersStore.setActiveTab(_)}
                    className='custom-tabs'
                    size='large'
                >
                    <TabPane
                        tab="Orders"
                        key="1"
                        disabled={isEmptyOrderRefNo}
                    >
                        <OrderedItems />
                    </TabPane>
                    <TabPane
                        tab="Status"
                        key="2"
                        disabled={isEmptyOrderRefNo}
                    >
                        <Forms.UpdateOrderStatus
                            order={currentOrder}
                            isLoading={ordersStore.isLoading}
                            onFormFinish={(order, parcels) => ordersStore.onFormSubmit(order, parcels)}
                        />
                        <Drawers.CustomDrawer
                            title={"Parcel Labels"}
                            visible={showLabelsPanel}
                            setVisible={(_) => ordersStore.toogleLabelsPanel(_)}
                            width={"40%"}
                            children={(props: any) => (
                                <ExpandedTables.ParcelLabelsTable />
                            )}
                        />
                    </TabPane>
                    <TabPane
                        tab={`Order #${currentOrder?.refNo || '(Processing)'} Chat`}
                        key="3"
                    >
                        <div>
                            <div className="order-messages-list">
                                {messages.map(msg => (
                                    <>
                                        {msg.fromClientId ? (
                                            <div className='message-box'>
                                                <div className='coloumn-flex'>
                                                    <div className='message-box-font'></div>
                                                    {msg.message}
                                                </div>
                                                <div className='message-box-time'>
                                                    {formatSentAt(msg.sentAt)}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='message-box-time'>
                                                <div className='flex-only'>
                                                    <div className='message-box-sender'>
                                                        <div className='coloumn-flex'>
                                                            {msg.message}
                                                        </div>
                                                        <div className='message-box-time'>
                                                            {formatSentAt(msg.sentAt)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                            <div>
                                <div className='message-bottom-margin'>
                                    <div className='message-bottom-style'>
                                        <Input
                                            placeholder='Write a message... '
                                            bordered={false}
                                            onChange={(_) => {
                                                notificationsStore.setTypedMessage(_.target.value)
                                            }}
                                        />
                                        <SendOutlined
                                            style={{ fontSize: "24px" }}
                                            className='icon-style-right'
                                            onClick={() => sendMessage()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane
                        tab="History"
                        key="4"
                        disabled={isEmptyOrderRefNo}
                    >
                        {statusHistory && statusHistory.length > 0 ? (
                            <List
                                itemLayout="horizontal"
                                dataSource={[...statusHistory]}
                                renderItem={({ statusCode, createdAt }) => {
                                    const status = OrderStatus.getStatusInfo(statusCode as StatusCodes);
                                    return (
                                        status && (
                                            <List.Item
                                                style={{ color: status.color }}
                                            >
                                                <List.Item.Meta
                                                    style={{ color: status.color }}
                                                    title={status?.readableText}
                                                />
                                                <div style={{ color: status.color }}>
                                                    {formatSentAt(createdAt)}
                                                </div>
                                            </List.Item>
                                        ));
                                }}
                            />)
                            : (
                                <Empty />
                            )}
                    </TabPane>
                </Tabs>
            )}
        />
    );
})


export default OrderInfoDrawer;
