import { APP_URL } from "../../config/axios-config";
import { IClientAddress } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getClientAddresses = async (): Promise<ResponseFormat<IClientAddress>> => {
    const response = await APP_URL.get('clientAddress');

    let clients: IClientAddress[] = [];

    if (response?.data) {
        clients = response.data;
    }

    return { data: clients };
}

export const addClientAddress = async (body: IClientAddress, clients: IClientAddress[]): Promise<ResponseFormat<IClientAddress>> => {
    const response = await APP_URL.post('clientAddress', body);

    clients.push(response.data as IClientAddress);
    return { data: clients };
}

export const updateClientAddress = async (body: IClientAddress, clients: IClientAddress[]): Promise<ResponseFormat<IClientAddress>> => {
    clients = [...clients]

    await APP_URL.put(`clientAddress/${body.id}`, body);

    const elementPos = clients.map(x => x.id).indexOf(body.id);
    clients[elementPos!] = body;
    return { data: clients };
}

export const deleteClientAddress = async (body: IClientAddress, clients: IClientAddress[]): Promise<ResponseFormat<IClientAddress>> => {
    await APP_URL.delete(`clientAddress/${body.id}`);

    return { data: clients };
}