import { FiExternalLink } from "react-icons/fi";

export type ExternalLinkProp = {
    src: string
}

const ExternalLink = ({ src }: ExternalLinkProp) => (
    <a href={src} rel="noreferrer" target="_blank">
        <FiExternalLink size='16px' />
    </a>
);

export default ExternalLink;
