import React, { useRef } from "react";
import { Form, FormInstance, Input, InputNumber, message } from "antd";
import { observer } from "mobx-react-lite";
import { Search, Buttons } from "..";
import { IVerificationRequest } from "../../utils/interfaces";
import { Store } from "../../stores/stores";
import "./style.css";
import { ClientStore } from "../../stores/client";
import { UserAuth } from "../../services/api-service";

type NewVerificationProp = {
    onFormFinish: (verificationRequest: IVerificationRequest) => void,
    isLoading?: boolean
};

const NewVerification = observer(({ isLoading, onFormFinish }: NewVerificationProp) => {
    const formRef = useRef<FormInstance>(null);
    const { clientsStore } = Store;

    const selectCustomer = ({ ...props }: any) => (
        <Search.DebounceSearch
            fetchOptions={(query) => clientsStore.searchCustomer(query)}
            labelInValue={true}
            {...props}
        />
    );

    const formData = [
        { name: 'customerSearch', label: "Customer Search", component: selectCustomer, required: true },
    ];

    const onFinish = (values: any) => {
        // Assuming the customerSearch returns an object with a `value` property that is the userId
        const userId = values.customerSearch?.value;
        const phoneNumber = values.phoneNumber;

        const verificationRequest: IVerificationRequest = {
            userId: userId,
            phoneNumber: phoneNumber,
            verified: true, 
        }as IVerificationRequest;

        console.log('onformfinsih')

        onFormFinish(verificationRequest);
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error('Failed to submit form.');
    };

    return (
        <>
            <Form
                ref={formRef}
                name='new-verification'
                className='custom-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
            >
                {formData.map(({ name, label, component: Component, required }, index) => (
                    <Form.Item
                        key={index}
                        name={name}
                        label={label}
                        rules={[{ required, message: `${label} is required!` }]}
                    >
                        <Component />
                    </Form.Item>
                ))}
                 <Form.Item
                    name='phoneNumber'
                    label="Phone Number"
                    rules={[{ required: true, message: "Phone Number is required!" }]}
                >
                    <Input type="tel" />
                </Form.Item>

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text='Submit'
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
});

export default NewVerification;