import { APP_URL } from  "../../config/axios-config";
import { ISurveyQuestion } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getSurveyQuestions = async (): Promise<ResponseFormat<ISurveyQuestion>> => {
    const response = await APP_URL.get<ISurveyQuestion[]>('/SurveyQuestion');
    return { data: response.data };
}

export const getSurveyQuestion = async (id: string): Promise<ISurveyQuestion> => {
    const response = await APP_URL.get<ISurveyQuestion>(`/SurveyQuestion/${id}`);
    return response.data;
}

export const addSurveyQuestion = async (body: ISurveyQuestion, questions: ISurveyQuestion[]): Promise<ResponseFormat<ISurveyQuestion>> => {
    const response = await APP_URL.post('SurveyQuestion', body);

    questions.push(response.data as ISurveyQuestion);
    return { data: questions };
}

export const updateSurveyQuestions = async (body: ISurveyQuestion, questions: ISurveyQuestion[]): Promise<ResponseFormat<ISurveyQuestion>> => {
    questions = [...questions]
    await APP_URL.put(`SurveyQuestion/${body.id}`, body);

    const elementPos = questions.map(x => x.id).indexOf(body.id);
    questions[elementPos!] = body;
    return { data: questions };
}

export const updateSurveyQuestion = async (id: string, question: ISurveyQuestion): Promise<void> => {
    await APP_URL.put(`SurveyQuestion/${id}`, question);
}


export const deleteSurveyQuestion = async (body: ISurveyQuestion, questions: ISurveyQuestion[]): Promise<ResponseFormat<ISurveyQuestion>> => {
    await APP_URL.delete(`SurveyQuestion/${body.id}`);

    questions.splice(questions.indexOf(body), 1);
    return { data: questions };
}



