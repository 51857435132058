import { Form, Input, message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AppLogo } from "../../../assets";
import { Buttons, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import { useQuery } from "../../../utils/ReactRouter/useQuery";
import "./style.css";

const LinkAccountNumber = observer(() => {
    const { token }: any = useParams();
    const { emailTokensStore } = Store;
    const [isDeclineClicked, setIsDeclineClicked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [form] = Form.useForm();

    let query = useQuery();
     console.log(query);

    const onFinishFailed = (errorInfo: any) => {
        message.error('Could not link account number.');
    };

    React.useEffect(() => {
        if (token) {
            emailTokensStore.loadEmailToken(token);
        }
    }, [emailTokensStore, token]);

    return (
        <Spinners.CustomSpinner
            title={"Getting Account"}
            isSpinning={emailTokensStore.isLoading}
        >
            <main id="account-verification">
                <img className='account-verification-applogo' src={AppLogo} alt="G.Fox logo" />

                {isSuccess && (
                    <h1 className='header account-verification-header'>
                        Account #{query.get("acc")} has been successfully linked
                    </h1>
                )}

                {(emailTokensStore.tokenValid && !isSuccess) && (
                    <>
                        <h1 className='header account-verification-header'>Link Account Number To:</h1>

                        <h2 className="header account-verification-header">{query.get("acc")}</h2>

                        <h2 className="header account-verification-header">{query.get("emailAddress")}</h2>

                        <div className='landing-login-form'>
                            <Form
                                name="basic"
                                form={form}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={async (values) => {
                                    const result = await emailTokensStore.onLinkAccount(query.get("emailAddress"));
                                    setIsSuccess(result);
                                }}
                                onFinishFailed={onFinishFailed}
                            >
                                <div className="account-verification-input">
                                    <Buttons.Primary
                                        text={'LINK ACCOUNT'}
                                        htmlType='submit'
                                        loading={emailTokensStore.isLoading}
                                        disabled={emailTokensStore.isLoading}
                                        onClick={() => setIsDeclineClicked(false)}
                                    />
                                </div>
                                  <div className="account-verification-input">
                                    <Buttons.Primary
                                        text={'Decline'}
                                        htmlType='button'
                                           onClick={async () => {
                                            console.log('button-clicked')
                                            setIsDeclineClicked(true);
                                            const declineReason = form.getFieldValue('declineReason');
                                            if (declineReason) {
                                               await emailTokensStore.onDeclineAccountLink(query.get("emailAddress"), declineReason);
                                            }
                                            else {
                                                message.error('Please enter a reason for decline.');
                                            }
                                        }}
                                        loading={emailTokensStore.isLoading}
                                        disabled={emailTokensStore.isLoading}
                                    />
                                        <div className="decline-input">
                                            <Form.Item
                                                label="Decline Reason"
                                                name="declineReason"
                                                rules={isDeclineClicked ? [{ required: true, message: 'Please input your reason for decline!' }] : []}
                                            >
                                                <Input
                                                    size='large'
                                                    className='pageheader-options-search'
                                                    placeholder="Reason for Decline"
                                                />
                                            </Form.Item>
                                        </div>

                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </main>
        </Spinners.CustomSpinner >
    );
});

export default LinkAccountNumber;
