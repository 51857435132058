import { Form, FormInstance, InputNumber, message, Switch } from "antd";
import { Search } from "..";
import React from "react";
import { Buttons } from "..";
import { VoucherConstraintType } from "../../utils/enums";
import { IVoucherConstraint } from "../../utils/interfaces";
import { PopupConfirm } from "../Modals";
import { DebounceSelectProp } from "../Search/DebounceSearch";
import { Store } from "../../stores/stores";
import { observer } from "mobx-react-lite";

type NewVoucherConstraintFormProp = {
    voucherId?: string;
    voucherConstraints: IVoucherConstraint[],
    onFormFinish: (voucherConstraint: IVoucherConstraint[]) => void
    onDelete: () => void
    isLoading: boolean
    isLoyaltyCard?: boolean
};

type FormSelectType = { label: string, value: string };
type VoucherConstraintForm = Omit<IVoucherConstraint, 'id' | 'voucherId' | 'constraintType' | 'constraintTypeRefs' >  & {
    productRefs: FormSelectType[],
    productCategoryRefs: FormSelectType[],
    customerRefs: FormSelectType[],
    customerAccountRefs: FormSelectType[],
    // TODO: bidvestEmployeeRefs?: VoucherConstraintType,
    // TODO: branchRefs: VoucherConstraintType,
}

const NewVoucherConstraintForm = observer(({
    voucherId,
    voucherConstraints,
    isLoading,
    isLoyaltyCard,
    onDelete,
    onFormFinish,
}: NewVoucherConstraintFormProp) => {
    const { inventoryStore } = Store;
    const formRef = React.createRef<FormInstance<VoucherConstraintForm>>();

    const getConstraintInitalValue = (t: VoucherConstraintType): FormSelectType[] => {
        return voucherConstraints.find(x => x.constraintType === t)?.constraintTypeRefs ?? [];
    };

    const initialValues: VoucherConstraintForm = {
        discountPercentage: voucherConstraints[0]?.discountPercentage ?? 0,
        quantityLimit: voucherConstraints[0]?.quantityLimit,
        quantityLimitPerUser: voucherConstraints[0]?.quantityLimitPerUser,
        excludeContractPricing: voucherConstraints[0]?.excludeContractPricing ?? true,
        excludeOutOfStock: voucherConstraints[0]?.excludeOutOfStock ?? true,
        excludeLineDiscount: voucherConstraints[0]?.excludeLineDiscount,
        firstPurchaseCount: voucherConstraints[0]?.firstPurchaseCount,
        productRefs: getConstraintInitalValue(VoucherConstraintType.PRODUCT),
        productCategoryRefs: getConstraintInitalValue(VoucherConstraintType.PRODUCT_CATEGORY),
        customerRefs: getConstraintInitalValue(VoucherConstraintType.CUSTOMER),
        customerAccountRefs: getConstraintInitalValue(VoucherConstraintType.CUSTOMER_ACCOUNT),
    };

    const validateMessages = {
        required: "Field is required!",
    };

    const selectInventoryMaster = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(query) => inventoryStore.searchInventoryMaster(query)}
            mode="multiple"
            labelInValue={true}
            {...props}
        />
    );

    const selectCategory = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(query) => inventoryStore.searchCategory(query)}
            mode="multiple"
            labelInValue={true}
            {...props}
        />
    );

    const selectCustomer = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(query) => inventoryStore.searchCustomer(query)}
            mode="multiple"
            labelInValue={true}
            {...props}
        />
    );

    const selectCustomerAccount = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={(query) => inventoryStore.searchCustomer(query, true)}
            mode="multiple"
            labelInValue={true}
            {...props}
        />
    );

    const disabledSwitch = ({ ...props }: any) => (
        <Switch {...props} disabled={true} />
    )

    const disabledInputNumber = ({ ...props }: any) => (
        <InputNumber {...props} disabled={true} />
    )

    const formData = [
        { name: 'discountPercentage', label: "Discount %", component: InputNumber, required: true },
        { name: 'quantityLimit', label: "Num of Users", component: InputNumber, required: true },
        { name: 'quantityLimitPerUser', label: " Product Limit Per User", component: disabledInputNumber, disabled: true, required: false },
        // FIXME: this ehtoer prodyct reg or categeory are always requred
        { type: VoucherConstraintType.PRODUCT, name: 'productRefs', label: "Inventory details", component: selectInventoryMaster, required: false },
        { type: VoucherConstraintType.PRODUCT_CATEGORY, name: 'productCategoryRefs', label: "Applied Category", component: selectCategory, required: false },
        { type: VoucherConstraintType.CUSTOMER, name: 'customerRefs', label: "Customer email", component: selectCustomer, required: false },
        ...(isLoyaltyCard ? [{ type: VoucherConstraintType.BIRTHDAY, name: 'birthday', label: "Birthday", component: Switch, valuePropName: "checked", required: false }] : []),
        { type: VoucherConstraintType.CUSTOMER_ACCOUNT, name: 'customerAccountRefs', label: "Customer accounts", component: selectCustomerAccount, required: false },
        { name: 'firstPurchaseCount', label: "First Purchase Count", component: InputNumber, required: false },
        { name: 'excludeLineDiscount', label: "Exclude Line Discount", component: Switch, valuePropName: "checked", required: false },
        { name: 'excludeContractPricing', label: "Exclude Contract Pricing", component: disabledSwitch, valuePropName: "checked", required: true, disabled: true},
        { name: 'excludeOutOfStock', label: "Exclude Out of Stock", component: disabledSwitch, valuePropName: "checked", required: true },
    ];

    const onFinish = (values: VoucherConstraintForm) => {
        const getVoucherConstraintObj = (t: VoucherConstraintType, refs?: FormSelectType[]) : IVoucherConstraint => {
            let id = voucherConstraints.find(x => x.constraintType === t)?.id;

            return ({
                id: id,
                voucherId: voucherId,
                discountPercentage: values.discountPercentage,
                constraintType: t,
                constraintTypeRefs: refs ?? [],
                firstPurchaseCount: values.firstPurchaseCount,
                quantityLimit: values.quantityLimit,
                quantityLimitPerUser: values.quantityLimitPerUser,
                excludeContractPricing: values.excludeContractPricing,
                excludeOutOfStock: values.excludeOutOfStock,
                excludeLineDiscount: values.excludeLineDiscount,
            })
        };

        const mappedData: IVoucherConstraint[] = [
            getVoucherConstraintObj(VoucherConstraintType.PRODUCT, values.productRefs),
            getVoucherConstraintObj(VoucherConstraintType.PRODUCT_CATEGORY, values.productCategoryRefs),
            getVoucherConstraintObj(VoucherConstraintType.CUSTOMER, values.customerRefs),
            getVoucherConstraintObj(VoucherConstraintType.CUSTOMER_ACCOUNT, values.customerAccountRefs),
            ...(isLoyaltyCard ? [getVoucherConstraintObj(VoucherConstraintType.BIRTHDAY)] : []),
        ];

        onFormFinish(mappedData);
    };

    const onFinishFailed = ({values, errorFields, outOfDate }: any) => {
        const errText = errorFields[0]?.errors[0];
        const name = errorFields[0]?.name[0];
        let label = formData.find(x => x.name === name)?.label;

        if (name && errText) {
            message.error(`${errText} ${label ?? ''}`);
            return;
        }

        if (!voucherConstraints) {
            message.error('Could not add new constraints.')
        }
    };

    return (
        <>
            <Form<VoucherConstraintForm>
                ref={formRef}
                name='new-voucherConstraint'
                className='custom-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={initialValues}
            >

                {formData.map(({ component: Component, ...item }, index) =>
                    <Form.Item
                        key={index}
                        rules={[{ required: item.required }]}
                        {...item}
                    >
                        <Component />
                    </Form.Item>
                )}

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={voucherConstraints.length > 0 ? 'Update voucher constraint' : 'Add voucher constraint'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
});

export default NewVoucherConstraintForm;
