import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Input, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Forms } from "..";
import { Store } from "../../stores/stores";
import { IUserAuth, ISpecial, IBranch } from "../../utils/interfaces";
import { CustomSpinner } from "../Spinners";
import CustomDrawer from "./CustomDrawer";

const SpecialsDrawer = observer(() => {
    const { specialsStore } = Store;

    const columns = [
        {
            "title": "Id",
            "dataIndex": "id",
            fixed: 'left' as 'left',
            width: 70,
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Branch",
            key: "branchName",
            dataIndex: "branch",
            render: (text: IBranch | undefined | null, record: any) => {
                return text?.branchName ?? '---';
            }
        },
        {
            title: "Active",
            key: "active",
            dataIndex: "active",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Not Active',
                    value: false,
                },
            ],
            onFilter: (value: any, record: ISpecial) => record.active === !!value,
            render: (isNew: boolean, record: ISpecial) => (
                record.active ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            title: "National",
            key: "redirect",
            dataIndex: "redirect",
            filters: [
                {
                    text: 'National',
                    value: true,
                },
                {
                    text: 'Not National',
                    value: false,
                },
            ],
            onFilter: (value: any, record: ISpecial) => record.redirect === !!value,
            render: (isNew: boolean, record: ISpecial) => (
                record.redirect ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            title: "From",
            dataIndex: "fromDate",
            return: (text: any) => {
                return text ?? '---'
            }
        },
        {
            title: "To",
            dataIndex: "toDate",
            return: (text: any) => {
                return text ?? '---'
            }
        },
        {
            title: "Created",
            dataIndex: "createdAt",
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 90,
            render: (text: any, record: ISpecial) => (
                <Buttons.Text
                    text='Edit'
                    onClick={() => specialsStore.editSpecial(record)}
                />
            )
        },
    ];

    return (
        <CustomSpinner
            title="Getting Specials"
            isSpinning={specialsStore.isLoading}
        >
            <Drawer
                className='custom-drawer'
                title="Specials"
                visible={specialsStore.showPanel}
                onClose={(_) => specialsStore.togglePanel(false)}
                width="80%"
                children={(
                    <>
                        <div className='form-options-container'>
                            <Buttons.Text
                                text="Add new"
                                loading={false}
                                disabled={false}
                                onClick={() => specialsStore.editSpecial()}
                            />
                        </div>

                        <Input
                            className='pageheader-options-search orders-search'
                            placeholder="Search list..."
                            defaultValue={specialsStore.searchQuery}
                            prefix={<RiSearchLine color='#CBD5E0' />}
                            disabled={specialsStore.isLoading}
                            onChange={(_) => specialsStore.setSearchQuery(_.target.value)}
                            style={{ marginTop: '1em', marginBottom: '2em', width: '50%' }}
                        />

                        <div className='custom-table-container'>
                            <Table
                                rowKey='id'
                                className='custom-table contractpricing-table'
                                columns={columns}
                                scroll={{ x: 2500 }}
                                dataSource={specialsStore.filteredQueryResults}
                                pagination={false}
                            />
                        </div>

                        {
                            <CustomDrawer
                                title={!specialsStore.currentSpecial ? "Add new item" : "Edit item"}
                                visible={specialsStore.showFormsPanel}
                                width="70%"
                                setVisible={(_) => specialsStore.toggleFormsPanel(_)}
                                children={observer(() => (
                                    <Forms.NewSpecial
                                        special={specialsStore.currentSpecial}
                                        onFormFinish={(_) => specialsStore.submitSpecial(_)}
                                        // onSpecialDelete={() => {}} TODO: handle deletes
                                        isLoading={specialsStore.isLoading}
                                    />
                                ))}
                            />
                        }
                    </>
                )}
            />
        </CustomSpinner>
    )
});

export default SpecialsDrawer;
