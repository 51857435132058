import { APP_URL } from "../../config/axios-config";
import { IBranchCod } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getBranchCods = async (): Promise<ResponseFormat<IBranchCod>> => {
    const response = await APP_URL.get('branchCod');

    let branches: IBranchCod[] = [];

    if (response?.data) {
        branches = response.data;
    }
    return {
        data: branches
    };
}

export const addBranchCod = async (body: IBranchCod, branches: IBranchCod[]): Promise<ResponseFormat<IBranchCod>> => {
    const response = await APP_URL.post('branchCod', body);

    branches.push(response.data as IBranchCod);

    return { data: branches };
}

export const updateBranchCod = async (body: IBranchCod, branches: IBranchCod[]): Promise<ResponseFormat<IBranchCod>> => {
    branches = [...branches]

    await APP_URL.put(`branchCod/${body.id}`, body);

    const elementPos = branches.map(x => x.id).indexOf(body.id);
    branches[elementPos!] = body;

    return { data: branches };
}

export const deleteBranchCod = async (body: IBranchCod, branches: IBranchCod[]): Promise<ResponseFormat<IBranchCod>> => {
    await APP_URL.delete(`branchCod/${body.id}`);

    return { data: branches };
}