import Icon from "@ant-design/icons";

const Svg = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1V21" stroke-miterlimit="10" />
        <path d="M19.0953 7.19049V18.6191L11 21L2.90479 18.6191V7.19049" stroke-miterlimit="10" />
        <path d="M11 1L12.9048 7.19048H21L18.619 1.95238L11 1ZM11 1L3.38095 1.95238L1 7.19048H9.09524L11 1Z" stroke-miterlimit="10" />
    </svg>
);

const OrdersIcon = ({ ...props }) => {
    return <Icon component={Svg} {...props} />
};

export default OrdersIcon;