import { message } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import { getPaystackTransaction } from "../services/api-service/pay"
import { IPaystackTransactionRequest } from "../utils/interfaces"
import { LoadManager } from "../utils/LoadManager/LoadManager"

export class PayStore {
    paystackTransactions = new LoadManager<IPaystackTransactionRequest>({ data: [] }, getPaystackTransaction)
    showTransactionPanel = false;
    transactionQuery = ""

    constructor() {
        makeObservable(this, {
            showTransactionPanel: observable,
            transactionQuery: observable,
            filterPaystackTransactions: computed,
            setTransactionQuery: action,
            toggleTransactionPanel: action,
            loadPaystackTransactions: action,
        });
    }

    toggleTransactionPanel(v: boolean) {
        this.showTransactionPanel = v;
    }

    setTransactionQuery(s: string) {
        this.transactionQuery = s;
    }

    get filterPaystackTransactions(): IPaystackTransactionRequest[] {
        if (this.transactionQuery.length === 0) {
            return this.paystackTransactions.value.data;
        }
        return this.paystackTransactions.value.data.filter(t => (
            String(t.orderRefNo).toUpperCase().indexOf(this.transactionQuery.toUpperCase()) >= 0
            || String(t.data?.reference).toUpperCase().indexOf(this.transactionQuery.toUpperCase()) >= 0
        ));
    }

    async loadPaystackTransactions() {
        const hide = message.loading('Getting transactions...')
        await this.paystackTransactions.fetch();
        hide();
        if (this.paystackTransactions.error) {
            message.error('Could not load transactions');
            return;
        }
        this.toggleTransactionPanel(true);
    }
}