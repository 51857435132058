import { observable, action, makeObservable, computed } from "mobx";
import { APIService } from "../services";
import { getQuotes } from "../services/api-service/quote";
import { IClient, IQuote } from "../utils/interfaces";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { ResponseFormat } from "../utils/types";

class QuoteStore {
    quotes = new LoadManager<IQuote>({ data: [] }, getQuotes)

    query = ""
    currentQuote: IQuote | null = null;
    showPanel = false;

    constructor() {
        makeObservable(this, {
            quotes: observable,
            currentQuote: observable,
            query: observable,
            editQuote: action,
            showPanel: observable,
            filterByQuery: computed,
            setQuery: action,
            togglePanel: action,
            loadQuotes: action,
        });
    }

    get filterByQuery(): ResponseFormat<IQuote> {
        if (this.query.length === 0) {
            return this.quotes.value;
        }
        const data = this.quotes.value.data.filter(q => (
            (String(q.refNo).toUpperCase().indexOf(this.query.toUpperCase()) >= 0))
        );
        return { data };
    }
    
    setQuery(s: string) {
        this.query = s.trim()
    }

    togglePanel(value?: boolean) {
        this.showPanel = value !== undefined ? value : !this.showPanel;
        if (this.showPanel === false) {
            this.currentQuote = null;
        }
    }

    editQuote(quote?: IQuote | null) {
        this.showPanel = true;
        this.currentQuote = quote ?? null;
    }

    async loadClient(clientId: string): Promise<IClient | null> {
        let client: IClient | null = null;
        try {
            client = await APIService.Client.getById(clientId);
        } catch (error) {
            console.log('could not load client info: ', error);
        }
        return client;
    }

    async loadQuotes() {
        let queryParams = ``
        await this.quotes.fetch(queryParams);
    }
}

export default QuoteStore;
