import { Menu } from "antd";
import { ICategory } from "../../utils/interfaces";

type CategoryMenuProp = {
    items: ICategory[],
    handleOnClick: (item: ICategory) => void
}

const CategoryMenu = ({ items, handleOnClick }: CategoryMenuProp) => (
    <>
        {!items || items?.map((item, index) => (
            item?.inverseParentCategory.length === 0 ? (
                <Menu.Item onClick={(_) => handleOnClick(item)}                >
                    {item.name}
                </Menu.Item>
            ) : (
                <Menu.ItemGroup key={'group' + item.refNo}>
                    <Menu.SubMenu
                        key={'sub' + item.refNo}
                        title={item.name}
                        onTitleClick={(_) => handleOnClick(item)}
                    >
                        <CategoryMenu
                            items={item.inverseParentCategory}
                            handleOnClick={handleOnClick}
                        />
                    </Menu.SubMenu >
                </Menu.ItemGroup>
            )
        ))}
    </>
)


export default CategoryMenu;