import Icon from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import React from "react";
import { PDFIcon } from "../../assets";
import { getBase64UploadFile } from "./ImagesPreviewUpload";
import "./style.css";

interface CustomUploadProp extends Omit<UploadProps, 'children'> {
    uploadRef?: any
    fileList: UploadFile<any>[];
    setFileList: any;
    children?: any
}

const CustomUpload = ({ children: Component, fileList, setFileList, ...rest }: CustomUploadProp) => {
    const [previewImage, setPreviewImage] = React.useState<any>();
    const [previewVisible, setPreviewVisible] = React.useState(false);

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview && file.originFileObj) {
            file.preview = await getBase64UploadFile(file.originFileObj);
        }

        if (file.url && file.url.includes('.pdf'))
        {
            window.open(file.url);
        } else {
            setPreviewImage(file.preview || file.url);
            setPreviewVisible(true);
        }
    };

    const iconRender = (file: UploadFile) => {
        if (file.type === 'application/pdf' || file?.url?.includes('.pdf')) {
            return <Icon className='pdf-icon-render' component={() => <img src={PDFIcon} alt="pdf" />} />
        }
        return <div></div>
    }

    return (
        <div className='custom-files-upload'>
            <Upload
            accept={rest.accept}
                className="avatar-uploader"
                fileList={fileList}
                beforeUpload={(file, fileList) => false}
                onChange={({ fileList }) => setFileList(fileList)}
                onPreview={handlePreview}
                iconRender={iconRender}
                {...rest}
            >
                {Component && <Component />}
            </Upload>

            <Modal
                visible={previewVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <img alt="preview" src={previewImage} />
            </Modal>
        </div>
    );
}

export default CustomUpload;