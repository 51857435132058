import { APP_URL } from "../../config/axios-config";
import { IInventoryMaster } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getInventoryMasters = async (queryParameters?: string): Promise<ResponseFormat<IInventoryMaster>> => {
    const response = await APP_URL.get('inventoryMaster?' + queryParameters || '');

    const inventoryMasters: IInventoryMaster[] = response.data;
    return { data: inventoryMasters };
}

export const addInventoryMaster = async (body: FormData, inventoryMasters: IInventoryMaster[]): Promise<ResponseFormat<IInventoryMaster>> => {
    const response = await APP_URL.post('inventoryMaster', body, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    });

    inventoryMasters.push(response.data as IInventoryMaster);
    return { data: inventoryMasters };
}

export const updateInventoryMaster = async (body: FormData, inventoryMasters: IInventoryMaster[]): Promise<ResponseFormat<IInventoryMaster>> => {
    inventoryMasters = [...inventoryMasters]

    const response = await APP_URL.post(`inventoryMaster/${body.get('id')}`, body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })

    const data = response.data as IInventoryMaster;
    const elementPos = inventoryMasters.map(x => x.id).indexOf(data.id);
    inventoryMasters[elementPos!] = data;
    return { data: inventoryMasters };
}

export const deleteInventoryMaster = async (body: IInventoryMaster, inventoryMasters: IInventoryMaster[], queryParameters?: string): Promise<ResponseFormat<IInventoryMaster>> => {
    await APP_URL.delete(`inventoryMaster/${body.id}?` + queryParameters || '');
    inventoryMasters.splice(inventoryMasters.indexOf(body), 1);

    return { data: inventoryMasters };
}

export const updateEditorContent = async (id: string, editorContent: string, editorContentHtml: string): Promise<ResponseFormat<IInventoryMaster>> => {
    const response = await APP_URL.put(`skuMaster/EditorContent/${id}`, { 
        editorContent: editorContent,
        editorContentHtml: editorContentHtml 
    });
    const updatedInventoryMaster: IInventoryMaster[] = [response.data];
    return { data: updatedInventoryMaster };
}