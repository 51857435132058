import { Table } from "antd";
import React from "react";
import { Spinners } from "..";
import { IBranch, IWarehouseInventory } from "../../utils/interfaces";
import "./style.css";

export type StockAvailabilityTableProp = {
    isLoading: boolean;
    branches: IBranch[];
    warehouseInventory: IWarehouseInventory[];
}

const StockAvailabilityTable = ({ isLoading, branches, warehouseInventory }: StockAvailabilityTableProp) => {

    const getBranch = (sysproWarehouseId: string) => {
        return branches?.find(x => x.sysproWarehouseId === sysproWarehouseId)?.branchName;
    }

    const columns = [
        {
            title: "",
            dataIndex: "sysproWarehouseId",
            render: (text: any, record: IWarehouseInventory) => (
                <>
                    {record?.sysproWarehouseId &&
                        <div>{getBranch(record.sysproWarehouseId)}</div>}
                </>
            )
        },
        {
            title: "List Price",
            dataIndex: "listPrice",
            render: (text: any, record: IWarehouseInventory) =>
                <div>R {(record?.masterStockList[0]?.listPrice ?? 0)?.toFixed(2)}</div>
        },
        {
            title: "On Hold",
            dataIndex: "onHold",
            render: (text: any, record: IWarehouseInventory) =>
                <div>{record?.masterStockList[0]?.onHold ?? 0}</div>
        },
        {
            title: "Qty Available",
            dataIndex: "qtyAvailable",
            render: (text: any, record: IWarehouseInventory) =>
                <div>{record?.masterStockList[0]?.qtyAvailable ?? 0}</div>
        },
        {
            title: "TaxCode",
            dataIndex: "taxCode",
            render: (text: any, record: IWarehouseInventory) =>
                <div>{record?.masterStockList[0]?.taxCode ?? '-'}</div>
        },
    ];

    return (
        <Spinners.CustomSpinner
            title="Getting Inventory"
            isSpinning={isLoading}
        >
            <Table
                rowKey='id'
                className='custom-table masterstock-table'
                columns={columns}
                dataSource={[...warehouseInventory]}
                pagination={false}
                summary={pageData => {
                    let totalStock = 0;
                    pageData.forEach(warehouse => {
                        totalStock += warehouse?.masterStockList[0]?.qtyAvailable ?? 0;
                    });

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3} >
                                Total
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                {totalStock}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    )
                }}
            />
        </Spinners.CustomSpinner>
    );
}

export default StockAvailabilityTable;