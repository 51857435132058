import { APP_URL } from "../../config/axios-config";
import { IRatingModel } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getRatings = async (): Promise<ResponseFormat<IRatingModel>> => {
    const response = await APP_URL.get('ratings');

    let staff: IRatingModel[] = [];

    if (response?.data) {
        staff = response.data;
    }
    return { data: staff };
}

export const deleteRatings = async (body: IRatingModel, staff: IRatingModel[]): Promise<ResponseFormat<IRatingModel>> => {
    await APP_URL.delete(`ratings/${body.id}`);
    staff.splice(staff.indexOf(body), 1);
    return { data: staff };
}

export const updateRating = async (id: string, body: IRatingModel): Promise<ResponseFormat<null>> => {
    try {
        await APP_URL.put(`/Ratings/${id}`, body);
        return { data: [] };
    } catch (err) {
        throw err;
    }
};

