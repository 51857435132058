import { Button } from "antd";
import { CustomButtonProps } from "./Primary";
import "./style.css";
import {BsPencil} from "react-icons/bs";
const Icon = ({ ...rest }: CustomButtonProps) => {
    return (
        <Button className='text-btn' type='text' {...rest}>
           <BsPencil size={20} color="#D8232A" />
        </Button>
    );
}

export default Icon;