import { observable, action, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { getSurveyResponses, getSurveyResponse, updateSurveyResponse, createSurveyResponse, deleteSurveyResponse, updateBatchSurveyResponses } from "../services/api-service/survey-response";
import { ISurveyResponse, ISurveyResponseUpdate } from "../utils/interfaces";

export class SurveyResponseStore {
    surveyResponses = new LoadManager<ISurveyResponse>({ data: [] }, getSurveyResponses);
    currentSurveyResponse: ISurveyResponse | null = null;
    showResponseDrawer: boolean = false;
    
    constructor() {
        makeObservable(this, {
            surveyResponses: observable,
            currentSurveyResponse: observable,
            showResponseDrawer: observable,
            loadSurveyResponses: action,
            loadSurveyResponse: action,
            updateSurveyResponse: action,
            deleteSurveyResponse: action,
            setShowResponseDrawer: action,
        })
    }

    async loadSurveyResponses() {
        await this.surveyResponses.fetch();
    }

    async loadSurveyResponse(id: string) {
        try {
            this.currentSurveyResponse = await getSurveyResponse(id);
        } catch (e) {
            console.error('Error fetching survey response', e);
        }
    }

    async updateSurveyResponse(id: string, response: ISurveyResponseUpdate) {
        try {
            await updateSurveyResponse(id, response);
            await this.loadSurveyResponses();
        } catch (e) {
            console.error('Error updating survey response', e);
        }
    }

    async deleteSurveyResponse(id: string) {
        try {
            await this.surveyResponses.remove({ id } as ISurveyResponse);
            await this.loadSurveyResponses();
        } catch (e) {
            console.error('Error deleting survey response', e);
        }
    }
    async updateBatchSurveyResponses(updates: ISurveyResponseUpdate[]) {
        try {
            await updateBatchSurveyResponses(updates);
            await this.loadSurveyResponses();
        } catch (e) {
            console.error('Error updating batch survey responses', e);
        }
    }

    setShowResponseDrawer(show: boolean) {
        this.showResponseDrawer = show;
    }
}