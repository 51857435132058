import { Form, FormInstance, Input, message, Select } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { Buttons } from "..";
import { EmailService, copyObjects } from "../../services";
import { ISurvey } from "../../utils/interfaces";
import { PopupConfirm } from "../Modals";
import { useAuthContext } from "../../context/AuthContext";
import "./style.css";
import { Store } from "../../stores/stores";

type NewSurveyFormProp = {
    survey?: ISurvey,
    onFormFinish?: (survey: ISurvey) => void,
    onSurveyDelete: () => void,
    isLoading: boolean,
};

const NewSurveyForm = observer(({ survey, onFormFinish, onSurveyDelete, isLoading }: NewSurveyFormProp) => {
    const { currentUser } = useAuthContext();
    const formRef = React.createRef<FormInstance>()
    const validateMessages = {
        required: "Field is required!",
    };


    const handleOnFinish = (formValues:any)=> {
    const values: ISurvey = {
        name: formValues.name, 
        openingMessage: formValues.openingMessage, 
        closingMessage: formValues.closingMessage,
        createdById: currentUser?.id, 
    } as ISurvey;
    if (survey) { 
        values.id = survey.id; 
    }
    onFormFinish!(values); 
}




    return (
        <>
            {survey &&
                <div className='form-delete-container'>
                    <PopupConfirm
                        isLoading={isLoading}
                        onConfirm={() => onSurveyDelete()}
                    />
                </div>
            }

            <Form
                ref={formRef}
                name='new-survey'
                className='custom-form'
                onFinish={handleOnFinish}
                onFinishFailed={() => { }}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={survey}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Opening Message"
                    name="openingMessage"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Close Message"
                    name="closingMessage"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={survey ? 'Update survey' : 'Add survey'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>

            </Form>
        </>
    );
});

export default NewSurveyForm;