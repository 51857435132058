import React, { useState } from "react";
import { Form, message, Switch } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { Buttons, Files } from ".."; 
import { PlusOutlined } from "@ant-design/icons";
import { IMFAStatus } from "../../utils/interfaces";

type NewMFAFormProp = {
    userId?: string,
    MFAStatus?: IMFAStatus,
    onFormFinish: (status: FormData) => void,
    isLoading: boolean,
    // toogleMFAPanel: (value: boolean) => void
};

const NewMFAForm = ({ userId, MFAStatus, isLoading, onFormFinish }: NewMFAFormProp) => {
    const [optOutFile, setOptOutFile] = React.useState<UploadFile[]>([]);

    const initialValues = Object.assign({}, MFAStatus, {
        File: MFAStatus?.optOutFile,
        Status: MFAStatus?.status
    });

    const handleOnFinish = (values: any) => {
        let formData = new FormData();

        let formValues = Object.assign({}, MFAStatus, values, {
            status: values.status === initialValues?.status ? 
            MFAStatus?.status : values.status
        });

       
        formData.append('userId',userId !)
        console.log('MFA',MFAStatus)
        formData.append('status', values.status);
        // formData = formDataValues(formData, formValues)
        optOutFile.forEach(file => file?.originFileObj && formData.append('optOutFile', file.originFileObj));
        onFormFinish(formData);
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error('Could not process the form.')
    };
    
    const urlsToUploadList = (urls: string[]) => {
        return urls.map<UploadFile>(x => ({
            uid: x,
            url: x,
            name: x,
        }));
    }

    // React.useEffect(() => {
    //     if (MFAStatus) {
    //         setOptOutFile(urlsToUploadList([MFAStatus?.optOutFile]));
    //     }
    // }, [MFAStatus])

    return (
        <>
            <div className='form-options-container'>
                {/* Your buttons and other UI elements */}
            </div>

            <Form
                name='new-mfa'
                className='custom-form'
                onFinish={handleOnFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                initialValues={{ /* Your initial values here */ }}
            >
                <Form.Item
                    name="proof"
                    label="Proof of Opt-out"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                >
                    <Files.CustomUpload
                        accept="application/pdf,image/*"
                        fileList={optOutFile}
                        setFileList={(x:any)=>console.log('test',x)}
                        maxCount={1}
                        listType="picture-card"
                        previewFile={undefined}
                        children={() => (
                            <div className='custom-files-upload-indicator'>
                                <PlusOutlined />
                            </div>
                        )}
                    />
                </Form.Item>

                <Form.Item
                    name="status"
                    label="Opt out customer from MFA"
                    valuePropName="checked"
                    required = {true}
                >
                    <Switch />
                </Form.Item>

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text='Submit MFA Form'
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
};

export default NewMFAForm;