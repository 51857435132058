import { Form, FormInstance, message } from "antd"
import { observer } from "mobx-react-lite";
import React from "react";
import { Buttons, Search } from "..";
import { DebounceSelectProp } from "../Search/DebounceSearch";
import { Store } from "../../stores/stores";
import { IInventoryMaster } from "../../utils/interfaces";

type DuplicateInventoryItemProp = {
    isLoading?: boolean,
    onFormFinish: (invItem: IInventoryMaster) => void,
}

const DuplicateInventoryItem = observer(({ isLoading, onFormFinish }: DuplicateInventoryItemProp) => {
    const formRef = React.createRef<FormInstance>();
    const searchRef = React.useRef<IInventoryMaster[] | null>(null);
    const { inventoryStore } = Store;

    const initialValues = Object.assign({}, {});

    const validateMessages = {
        required: "Field is required!",
    };

    const onFinish = (values: any) => {
        const itemToDuplicate = searchRef.current?.find(x => values.search)
        if (itemToDuplicate === undefined) {
            message.error('Could not duplicate inventory.')
            return;
        }
        onFormFinish(itemToDuplicate);
    }

    const onFinishFailed = (errorInfo: any) => {
        message.error('Could not duplicate inventory.')
    };

    const selectComplementaryItems = ({ ...props }: any) => (
        <Search.DebounceSearch<DebounceSelectProp>
            // TODO: Partial search
            fetchOptions={(_) => inventoryStore.searchInventoryMaster(_, (s) => { searchRef.current = s })}
            placeholder='Enter sku/stock code'
            {...props}
        />
    );

    const formData = [
        { name: 'search', label: "Sku", component: selectComplementaryItems, required: true },
    ];

    return (
        <>
            <Form
                ref={formRef}
                name='new-inventory'
                className='custom-form inventory-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={initialValues}
            >
                {formData.map(({ component: Component, ...item }, index) =>
                    <Form.Item
                        key={index}
                        rules={[{ required: item.required }]}
                        {...item}
                    >
                        <Component />
                    </Form.Item>
                )}

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={'Duplicate'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    )
});

export default DuplicateInventoryItem;
