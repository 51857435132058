
export enum Layouts {
    Landing = '',
    Home = '/home',
    MiniProgram = '/mini-program',
}

export enum PlatformRights {
    Admin = 'Administrator',
    Editor = 'Editor',
    QuerySpecialist = 'InventoryManager',
    InventoryManager = 'QuerySpecialist',
    DeactivatedStaff = 'DeactivatedStaff'
}

export enum OrderPlatform {
    ANDROID = 1,
    APPLE,
    VODAPAY,
    WEB,
}

export enum DeliveryTypes {
    Collect = "C",
    GFoxAccountHolder = "D",
    ExpressDelivery = "EA",
}

export enum VoucherType {
    VOUCHER = 0,
    LOYALTY_CARD = 1,
}

export enum StatusCodes {
    RECEIVED = "RECEIVED",
    PACKED = "PACKED",
    IN_TRANSIT = "IN_TRANSIT",
    DELIVERED = "DELIVERED",
}

export enum VoucherConstraintType
{
    PRODUCT,
    PRODUCT_CATEGORY,
    CUSTOMER,
    CUSTOMER_ACCOUNT,
    BIDVEST_EMPLOYEE,
    BIRTHDAY,
}
export enum TypeId {
    Text = 'b2e42c3a-c902-4e41-8e90-cd53235da672',
    MultipleChoice = 'b445dfc8-4bfc-47aa-bfb6-dcf97a95128f',
    MultiSelect = '273cc294-f92c-4032-9f33-3a1c0dce7c37',
    Email = '40b0b0c9-df7f-440d-b2dd-e6aa6c5fe907',
    Document = '21a9c8d3-c815-40c2-a4ce-394a73f751a4',
    Number = '2007b178-2514-495a-923b-5f6f182cc11d',
    Header = 'Header'
}

export enum StockHoldOptions {
    Hold = '0' ,
    PartialHold = '1',
    FullHold  = '2' ,
}

export enum ContentSliderPosition {
    LEFT = '0',
    CENTER = '1',
    RIGHT = '2',
}

export enum VerdictStatus
{
    Pending = '0',
    Accepted = '1',
    Rejected = '2',
}

export enum QuestionTypeID
{
    nameQuestionId = "ea55cbd5-43bb-4dd4-9899-a7a8bb041a00",
    emailQuestionId = "5d9567ef-6a7f-4ba6-9d06-25b9d3d74bab",
    fileQuestionId = "ba6ef6d0-e3fb-44f8-accd-e836f1054917",

}

