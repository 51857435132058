import { Layouts } from "../../utils/enums";
import { PageRoute } from "../../utils/types";
import { PdfViwer } from "./pdf-viewer";


export const mini_program_pages: PageRoute[] = [
    {
        path: '/pdf-viewer',
        name: 'Staff Management',
        exact: true,
        component: () => <PdfViwer />,
        layout: Layouts.MiniProgram,
        platformRights: [],
    }
];