import { DatePicker, Form, FormInstance, Input, message, Switch } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Buttons } from "..";
import { Store } from "../../stores/stores";
import { IVoucher } from "../../utils/interfaces";
import "./style.css";

type NewVoucherFormProp = {
    voucher?: IVoucher | null,
    onFormFinish?: (voucher: IVoucher) => void,
    onVoucherDelete: () => void,
    isLoading: boolean,
};

type VoucherFormType = Omit<IVoucher, 'formDate' | 'toDate'> & {
    fromDateMoment: moment.Moment,
    toDateMoment: moment.Moment,
}

const NewVoucherForm = observer(({
    voucher,
    onFormFinish,
    onVoucherDelete,
    isLoading,
}: NewVoucherFormProp) => {
    const { vouchersStore } = Store;
    const formRef = React.createRef<FormInstance>()

    const validateMessages = {
        required: "Field is required!",
    };

    const handleOnFinish = (formValues: VoucherFormType) => {
        if ( formValues.fromDateMoment.isAfter(formValues.toDateMoment)) {
            message.error("Invalid date range.");
            return
        }
        const mappedValues: IVoucher = {
            ...voucher,
            ...formValues,
            fromDate: formValues.fromDateMoment.toISOString(),
            toDate: formValues.toDateMoment.toISOString(),
        };

        onFormFinish!(mappedValues);
    }


    return (
        <>
            <Form
                ref={formRef}
                name='new-voucher'
                className='custom-form'
                onFinish={handleOnFinish}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={{
                    active: false,
                    ...voucher,
                    fromDateMoment: voucher?.fromDate? moment(voucher?.fromDate) : moment(),
                    toDateMoment:  voucher?.toDate ? moment(voucher?.toDate) : moment(),
                } as unknown as VoucherFormType}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Ref"
                    name="refNo"
                    rules={[
                        { required: true, },
                        {
                            message: 'Reference not available!',
                            validator: async (_, value) => {
                                const avail =  await vouchersStore.isRefAvailable(value);
                                if (!avail) throw new Error("Reference not available for use");
                            }
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="From Date"
                    name="fromDateMoment"
                    rules={[{ required: true }]}
                >
                    <DatePicker showTime={true} />
                </Form.Item>

                <Form.Item
                    label="To Date"
                    name="toDateMoment"
                    rules={[{ required: true }]}
                >
                    <DatePicker showTime={true} />
                </Form.Item>

                <Form.Item
                    label="Active"
                    name="active"
                    rules={[{ required: false }]}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={voucher ? 'Update voucher' : 'Add voucher'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
});

export default NewVoucherForm;
