import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Input, Popconfirm, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Forms } from "..";
import { Store } from "../../stores/stores";
import { IImageMap } from "../../utils/interfaces";
import { CustomSpinner } from "../Spinners";
import CustomDrawer from "./CustomDrawer";

const NewProductsMap = observer(() => {
    const { ImageMapStore } = Store;

    const columns = [
        {
            "title": "Id",
            "dataIndex": "id",
            fixed: 'left' as 'left',
            width: 70,
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'imageUrl',
            width: 140,
            render: (imageUrl: string) => <img src={imageUrl} className='table-content-slider-image' alt="new product map" />,
        },
        {
            title: "Created",
            dataIndex: "createdAt",
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 90,
            render: (text: any, record: IImageMap) => (
                <>
                    <Buttons.Text
                        text='Edit'
                        onClick={() => ImageMapStore.editImageMap(record)}
                    />

                    <Popconfirm
                        title='Are you sure to delete this product map?'
                        onConfirm={() => ImageMapStore.removeImageMap(record)}
                        okText='Yes'
                        cancelText='No'
                        placement='topRight'
                        >
                        <Buttons.Text text="Delete"/>
                    </Popconfirm>
                </>
            )
        },
    ];

    return (
            <Drawer
                className='custom-drawer'
                title="New Products Map"
                visible={ImageMapStore.showPanel}
                onClose={(_) => ImageMapStore.togglePanel(false)}
                width="80%"
                children={(
                    <>
                        <div className='form-options-container'>
                            <Buttons.Text
                                text="Add new"
                                loading={false}
                                disabled={false}
                                onClick={() => ImageMapStore.editImageMap()}
                            />
                        </div>

                        <Input
                            className='pageheader-options-search orders-search'
                            placeholder="Search list..."
                            defaultValue={ImageMapStore.searchQuery}
                            prefix={<RiSearchLine color='#CBD5E0' />}
                            disabled={ImageMapStore.isLoading}
                            onChange={(_) => ImageMapStore.setSearchQuery(_.target.value)}
                            style={{ marginTop: '1em', marginBottom: '2em', width: '50%' }}
                        />

                        <div className='custom-table-container'>
                            <Table
                                rowKey='id'
                                className='custom-table contractpricing-table'
                                columns={columns}
                                scroll={{ x: 2500 }}
                                dataSource={ImageMapStore.filteredQueryResults}
                                pagination={false}
                            />
                        </div>

                        {
                            <CustomDrawer
                                title={!ImageMapStore.currentNewProduct ? "Add new item" : "Edit item"}
                                visible={ImageMapStore.showFormsPanel}
                                width="70%"
                                setVisible={(_) => ImageMapStore.toggleFormsPanel(_)}
                                children={observer(() => (
                                    <Forms.NewImageMapForm
                                        product={ImageMapStore.currentNewProduct}
                                        onFormFinish={(_) => ImageMapStore.submitImage(_)}
                                        // onSpecialDelete={() => {}} TODO: handle deletes
                                        isLoading={ImageMapStore.isLoading}
                                    />
                                ))}
                            />
                        }
                    </>
                )}
            />
    )
});

export default NewProductsMap;
