import Icon from "@ant-design/icons";

const Svg = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6364 13.7273V8.27271L7.36364 13.7273L4.63636 8.27271H1V21H21V8.27271L14.6364 13.7273Z"  strokeMiterlimit="10" strokeLinecap="square" />
        <path d="M13.3571 4.63636C12.4826 2.51909 10.1335 1 7.36351 1C4.59351 1 2.24442 2.51909 1.36987 4.63636H13.3571Z"  strokeMiterlimit="10" strokeLinecap="square" />
        <path d="M10.0908 17.3636H10.9999"  strokeMiterlimit="10" strokeLinecap="square" />
        <path d="M16.4546 17.3636H17.3637"  strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
);

export const BranchManagementIcon = ({ ...props }) => {
    return <Icon component={Svg} {...props} />
};

export default BranchManagementIcon;
