import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { message } from "antd";
import { IMessage, IPostalAddress } from "../utils/interfaces";
import { addAddress, deleteAddress, getAddresses, updateAddress } from "../services/api-service/postal-address";
import { copyObjects } from "../services";

export class PostalAddressStore {
    postalAddresses = new LoadManager<IPostalAddress>({ data: [] }, getAddresses, addAddress, deleteAddress, updateAddress);

    currentAddress?: IPostalAddress;

    constructor() {
        makeObservable(this, {
            postalAddresses: observable,
            currentAddress: observable,
            editAddress: action,
            addAddress: action,
            isLoading: computed,
            loadAddresses: action,
            onFormSubmit: action,
            removeAddress: action,
        })
    }

    get isNew(): boolean {
        return !this.currentAddress;
    }

    editAddress(address?: IPostalAddress) {
        this.currentAddress = address;
    }

    addAddress(): void {
        this.currentAddress = undefined;
    }

    get isLoading(): boolean {
        return this.postalAddresses.isLoading;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.postalAddresses.error) {
            message.error(errorMsg)
        } else {
            message.success(successMsg)
        }
    }

    // Load addresses from api
    loadAddresses() {
        this.postalAddresses.fetch()
    }

    // add addresses from api
    async onFormSubmit(address: IPostalAddress) {
        let updatedBody: IPostalAddress | undefined;

        try {
            if (this.currentAddress) {
                updatedBody = copyObjects(this.currentAddress, address);
                await this.postalAddresses.update(updatedBody);
                this.showMessage({
                    successMsg: 'Postal address updated.',
                    errorMsg: 'Could not update postal address .',
                });
            } else {
                await this.postalAddresses.add(address);
                updatedBody = this.postalAddresses.value.data[this.postalAddresses.value.data.length - 1];
                this.showMessage({
                    successMsg: 'Postal address added.',
                    errorMsg: 'Could not add postal address.',
                });
            }

            this.editAddress(updatedBody);
            return updatedBody;
        } catch (e) {
            console.log('Error updating/adding addresses', e);
        }
    }

    async removeAddress() {
        try {
            if (this.currentAddress) {
                await this.postalAddresses.remove(this.currentAddress)
            }

            this.showMessage({
                successMsg: 'Postal address deactivated.',
                errorMsg: 'Could not deactivate postal address.',
            });
        } catch (e) {
            console.log('Error deleting addresses', e);
        }
    }
}

export default new PostalAddressStore();