import { DatePicker, Form, FormInstance, Input, message, UploadFile } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Buttons, Drawers, Files } from "..";
import { Store } from "../../stores/stores";
import { IImageMap } from "../../utils/interfaces";
import "./style.css";
import { runInAction } from "mobx";
import { APIService } from "../../services";

type NewProductProp = {
    product?: IImageMap,
    isLoading?: boolean,
    onFormFinish: (f: FormData) => void,
};

type ProductFormType = {
    title: string,
    // fromDateMoment: moment.Moment,
    // toDateMoment: moment.Moment,
};

const NewProductsMap = observer(({ product, isLoading, onFormFinish }: NewProductProp) => {
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [isMapVisible, setIsMapVisible] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(0);
    const formRef = React.createRef<FormInstance<ProductFormType>>();
    const [removedFiles, setRemovedFiles] = React.useState<string[]>([]);

    const { ImageMapStore } = Store;
    const onFinishFailed = (errorInfo: any) => {
        if (!product) {
            message.error('Could not add product.')
        } else {
            message.error('Could not update product.')
        }
    };

    const onFinish = (values: ProductFormType) => {
        const formData = new FormData();
        console.log("values", values);
        product?.id && formData.append('id', product.id);
        formData.append('NewProduct', String(true));
        if (fileList.length > 0) {
            fileList.forEach((file, idx) => {
                if (file?.originFileObj) {
                    formData.append('images', file.originFileObj);
                    const geoJsonString = JSON.stringify(ImageMapStore.geoJsonRefs[idx] ?? '');
                    formData.append('mapInfo', geoJsonString);
                }
            });
            onFormFinish(formData);
        } else {
            message.error('Add product images.');
            return;
        }
    };

    React.useEffect(() => {
        if (product?.image) {
            const ls = [{
                uid: product.image,
                name: product.image,
                url: product.image,
            }];
            setFileList(ls);
        } else {
            setFileList([]);
        }
    }, [product]);

    React.useEffect(() => {
        if (!isMapVisible) {
            ImageMapStore.setMapImageUrl(null);
        }
    }, [isMapVisible, ImageMapStore]);

    React.useEffect(() => {
        if (product) {
            runInAction(() => ImageMapStore.geoJsonRefs = [product.geoJsonObject]);
        } else {
            runInAction(() => {
                ImageMapStore.geoJsonRefs = new Array(100);
            });
        }
    }, [product, ImageMapStore]);

    return (
        <Form<ProductFormType>
            ref={formRef}
            name='new-product-map'
            className='custom-form inventory-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout='vertical'
            requiredMark={false}
            initialValues={{
                ...product,
            } as unknown as  ProductFormType}
        >
            <Files.ImagesPreviewUpload
                name='newProductImage'
                title={'Add Images'}
                fileList={fileList}
                selectedFile={selectedFile}
                setFileList={setFileList}
                setSelectedFile={setSelectedFile}
                onRemoveUrlImage={(url, idx) => {
                    setRemovedFiles(arr => [...arr, url]);
                    runInAction(() => {
                        ImageMapStore.geoJsonRefs.splice(idx, 1);
                    });
                }}
                onMapIconClick={(idx) => {
                    setSelectedFile(idx);
                    const img = { url: "", revokeUrl: false, index: idx };
                    if (fileList[idx].url) {
                        img.url = fileList[idx].url!;
                    } else if(fileList[idx].originFileObj) {
                        img.url = URL.createObjectURL(fileList[idx].originFileObj as any)
                        img.revokeUrl = true;
                    } else {
                        message.error("Failed to open image.");
                        return;
                    }
                    ImageMapStore.setMapImageUrl(img);
                    setIsMapVisible(true);
                }}
            />
            <div className='custom-form-submit'>
                <Buttons.Small
                    htmlType='submit'
                    text={product ? 'Update New Product Map' : 'Add New Product'}
                    loading={isLoading}
                    disabled={isLoading}
                />
            </div>

            <Drawers.ProductsImageMapDrawer
                isVisible={isMapVisible}
                setIsVisible={setIsMapVisible}
                onSaveImage={async (geojson) => {
                    if (ImageMapStore.mapImageUrl) {
                        console.log("Inside map image save");
                        runInAction(() => ImageMapStore.geoJsonRefs[ImageMapStore.mapImageUrl!.index] = geojson);
                    }
                    if (fileList[selectedFile].url) {
                        console.log("Outside")
                        if (product) {
                            try {
                                product.geoJsonObjectString = JSON.stringify(geojson);
                                await APIService.ImageMap.updateImage(product, []);
                            } catch (err) {
                                message.error('Failed to update image');
                                console.error(err);
                                return;
                            }
                        }
                    }
                    message.success("Map info saved");
                }}
            />
        </Form>
    );
});

export default NewProductsMap;