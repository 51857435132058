import { Layouts, PlatformRights } from "../../utils/enums";
import { BsPeople } from "react-icons/bs"
import { VscAccount } from "react-icons/vsc"
import { StaffManagement } from "./staff-management";
import { BranchManagement } from "./branch-management";
import { CustomerManagement } from "./customer-management";
import { InventoryManagement } from "./inventory-management";
import CategoryManagement from "./category-management";
import { Orders } from "./orders";
import React from "react";
import { BranchManagementIcon, InventoryManagementIcon, OrdersIcon } from "../../assets";
import { PageRoute } from "../../utils/types";
import { HiOutlineDocumentText } from "react-icons/hi";
import { MdOutlineCategory } from "react-icons/md";
import { RiSurveyLine } from "react-icons/ri";
import { QuotesManagement } from "./quotes-management";
import { HRManagement } from "./hr-management";
import { ReviewManagement } from "./Reivew-management";
import { FormOutlined } from "@ant-design/icons";
import { Verification } from "./Verification";
import { MdOutlineVerified } from "react-icons/md";

export const home_pages: PageRoute[] = [
    {
        path: '/staff-management',
        name: 'Staff Management',
        exact: true,
        icon: <BsPeople className="menu-icon" />,
        component: () => <StaffManagement />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin],
    },
    {
        path: '/customer-management',
        name: 'Customer Management',
        exact: true,
        icon: <VscAccount className="menu-icon" />,
        component: () => <CustomerManagement />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.Editor],
    },
    {
        path: '/branch-management',
        name: 'Branch Management',
        exact: true,
        icon: <BranchManagementIcon className="menu-icon" />,
        component: () => <BranchManagement />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.Editor],
    },
    {
        path: '/inventory-management',
        name: 'Inventory Management',
        exact: true,
        icon: <InventoryManagementIcon className="menu-icon" />,
        component: () => <InventoryManagement />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.Editor, PlatformRights.InventoryManager],
    },
    {
        path: '/category-management',
        name: 'category Management',
        exact: true,
        icon: <MdOutlineCategory className="menu-icon"/>,
        component: () => <CategoryManagement />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.Editor, PlatformRights.InventoryManager],
    },
    {
        path: '/orders',
        name: 'Orders',
        exact: true,
        icon: <OrdersIcon className="menu-icon" />,
        component: () => <Orders />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.QuerySpecialist],
    },
    {
        path: '/quotes',
        name: 'Quotes Management',
        exact: true,
        icon: <HiOutlineDocumentText className="menu-icon" />,
        component: () => <QuotesManagement />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.QuerySpecialist],
    },
    {
        path: '/hr-management',
        name: 'HR Management',
        exact: true,
        icon: <RiSurveyLine className="menu-icon" />,
        component: () => <HRManagement />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.QuerySpecialist],
    },
    {
        path: '/review-management',
        name: 'Review Management',
        exact: true,
        icon: <FormOutlined className="menu-icon" />,
        component: () => <ReviewManagement />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.QuerySpecialist],
    },
    {
        path: '/verification',
        name: 'Verification ',
        exact: true,
        icon: <MdOutlineVerified className="menu-icon" />,
        component: () => <Verification />,
        layout: Layouts.Home,
        platformRights: [PlatformRights.Admin, PlatformRights.QuerySpecialist],
    }
];
