import { StatusCodes } from "../utils/enums";

export const orderStatusInfo = [
    { statusCode: StatusCodes.RECEIVED, color: "#718096", readableText: "Order received", },
    { statusCode: StatusCodes.PACKED, color: "#FFB800", readableText: "Ready for pickup" },
    { statusCode: StatusCodes.IN_TRANSIT, color: "#D8232A", readableText: "Order in transit" },
    { statusCode: StatusCodes.DELIVERED, color: "#53B175", readableText: "Order delivered" },
]

export const getStatusInfo = (key: StatusCodes) => {
    return orderStatusInfo.find(x => x.statusCode === key);
};
