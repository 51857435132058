import { APP_URL } from "../../config/axios-config";
import { IContentSlider } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getContentSliders = async (): Promise<ResponseFormat<IContentSlider>> => {
    const response = await APP_URL.get('ContentSlider');
    let sliders: IContentSlider[] = response.data;
    return { data: sliders };
}

export const getContentSliderById = async (id: string): Promise<IContentSlider> => {
    const response = await APP_URL.get(`ContentSlider/${id}`);
    let slider: IContentSlider = response.data;
    return slider;
}

export const addContentSlider = async (body: FormData, items: IContentSlider[]): Promise<ResponseFormat<IContentSlider>> => {
    const response = await APP_URL.post('ContentSlider', body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    items.push(response.data as IContentSlider);
    return { data: items };
}

export const updateContentSlider = async (body: FormData, items: IContentSlider[]): Promise<ResponseFormat<IContentSlider>> => {
    console.log('This is the ID: ' ,body.get('id'))
    const response = await APP_URL.put(`ContentSlider/${body.get('id')}`, body, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    });

    const data = response.data as IContentSlider;
    const elementPos = items.map(x => x.id).indexOf(data.id);
    items[elementPos!] = data;
    return { data: items };
}

export const deleteContentSlider = async (body:IContentSlider, items: IContentSlider[]): Promise<ResponseFormat<IContentSlider>> => {
    await APP_URL.delete(`ContentSlider/${body.id}`);
    items = items.filter(x => x.id !== body.id)
    return { data: items };
}
