import { APP_URL } from "../../config/axios-config";
import { ILinkedAccount } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";


type VerifyLinkedNumberType = {
    emailAddress: string;
}


type DeclineAccountLinkType = {
    emailAddress: string;
    declineReason: string;
}

export const getLinkedAccounts = async (): Promise<ResponseFormat<ILinkedAccount>> => {
    try {
        const response = await APP_URL.get(`LinkedAccount`);
        return {data :response.data};
    } catch (error) {
        console.error("Error fetching linked accounts:", error);
        throw error;
    }
}
export const verifyLinkedNumber = async ( body: VerifyLinkedNumberType,emailTokenId?: string): Promise<void> => {
    const url = `linkedAccount/VerifyAccountNumber${emailTokenId ? `/${emailTokenId}` : ''}`;
    await APP_URL.post(url, body);
}

export const DeclineAccountLink = async (body: DeclineAccountLinkType,emailTokenId?: string): Promise<void> => {
    const url = `linkedAccount/DeclineAccountLink${emailTokenId? `/${emailTokenId}` : ''}`;
    await APP_URL.post(url, body);
}
