import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { pages } from '../pages';
import { Layouts } from '../utils/enums';

const Landing = () => {
    const getRoutes = (defaultRoutes: any[]) =>
        defaultRoutes.map((prop, index) => {
            if (prop.layout === Layouts.Landing) {
                return (
                    <Route
                        key={index}
                        path={prop.layout + prop.path}
                        exact={prop.exact}
                        component={prop.component}
                    />
                );
            }
            return null;
        });

    return (
        <Switch>{getRoutes(pages)}</Switch>
    );
}

export default Landing;