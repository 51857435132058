import { Image } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Drawers } from "..";
import { Store } from "../../stores/stores";
import { IClient } from "../../utils/interfaces";

const QuoteItems = observer(() => {
    const { quotesStore } = Store;
    const currentQuote = quotesStore.currentQuote;

    return (
        <div>
            <div className='order-tab-heading'>
                Order NO. {`${currentQuote?.refNo ?? ""}`}
                <div>
                    Quote Date: {moment(currentQuote?.updatedAt).format("DD MMM yyyy")}
                </div>
                Courier information: {currentQuote?.deliveryOrders[0]?.delivery?.description}
                <div className='order-spacing'>
                    Quote:
                </div>
            </div>
            {currentQuote?.quoteInventories.map(item => (
                <div className='product-border'>
                    <div className='create-flex order-product-image'>
                        <Image
                            className='image-style'
                            src={item.inventoryMaster?.productImages[0]}
                            alt="product"
                            preview={false}
                            fallback='https://storage.googleapis.com/gfox-public/images/fallback.png'
                        />
                    </div>
                    <div className='product-text-margin'>
                        <div className='coloumn-flex'>
                            <div className='heading1'>
                                {item.inventoryMaster?.description}
                            </div>
                            {item.inventoryMaster?.categorySpecificInfo.map(x => (
                                <div className='heading2'>
                                    {x.name}: {x.value}
                                </div>
                            ))}
                        </div>
                        <div className='heading3 order-product-info'>
                            {/*<div>R{item.orderInfo.price.toFixed(2)}</div>*/}
                            <div>x{item.orderQty}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
});

const QuoteInfoDrawer = observer(() => {
    const [client, setClient] = useState<IClient | null>(null);
    const { quotesStore } = Store;

    const currentQuote = quotesStore.currentQuote;

    const drawerHeader = (
        <div>
            <div>Quotes</div>
            <div className="orders-drawer-header-sutitle">
                <div>Quote Number: #{currentQuote?.refNo ?? ""}</div>
                <div>Client: {client?.userAuth?.firstName} {client?.userAuth?.lastName}</div>
                <div>Email: {client?.userAuth?.emailAddress}</div>
                <div>Phone number: {client?.billingPhone}</div>
            </div>
        </div>
    );

    const handleGetClientInfo = useCallback(async (clientId?: string | null) => {
        if (!clientId) {
            return;
        }
        var client = await quotesStore.loadClient(clientId)
        setClient(client);
    }, [quotesStore])

    useEffect(() => {
        handleGetClientInfo(quotesStore.currentQuote?.clientId);
    }, [handleGetClientInfo, quotesStore.currentQuote])

    return (
        <Drawers.CustomDrawer
            title={drawerHeader}
            visible={quotesStore.showPanel}
            setVisible={(_) => quotesStore.togglePanel(_)}
            children={() => (
                <QuoteItems />
            )}
        />
    );
})

export default QuoteInfoDrawer;
