import { ImageMapEditor, onCreateCallback } from "@bridgelabsdesign/gfox-image-tool"
import { message } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import { addImageMap, deleteImageMap, getImages, updateImageMap } from "../services/api-service/image-map"
import { IMessage, IImageMap } from "../utils/interfaces"
import { LoadManager } from "../utils/LoadManager/LoadManager"
import { GeoJSON } from "../utils/types";

export class ImageMapStore {
    products = new LoadManager<IImageMap>({ data: [] }, getImages, addImageMap, deleteImageMap, updateImageMap)

    searchQuery = ""
    showPanel = false;
    showFormsPanel = false;
    currentNewProduct?: IImageMap;

    mapRef: ImageMapEditor | undefined;
    mapImageUrl : { url: string, revokeUrl: boolean, index: 0 } | null = null;
    mapLayerCreateCallback: onCreateCallback | undefined;
    isSkuSelectionVisible = false;
    geoJsonRefs: GeoJSON[] =  [];

    constructor() {
        makeObservable(this, {
            geoJsonRefs: observable,
            mapRef: observable,
            mapImageUrl: observable,
            mapLayerCreateCallback: observable,
            isSkuSelectionVisible: observable,
            setIsSkuSelectionVisible: observable,
            searchQuery: observable,
            // specials: observable,
            isLoading: computed,
            filteredQueryResults: computed,
            showPanel: observable,
            showFormsPanel: observable,
            togglePanel: action,
            toggleFormsPanel: action,
            setSearchQuery: action,
            editImageMap: action,
            // resetNewProductForm: action,
            loadMap: action,
            setMapImageUrl: action,
            submitImage: action,
        });
    }

    setIsSkuSelectionVisible(v: boolean) {
        this.isSkuSelectionVisible = v;
    }

    setMapImageUrl(obj: { url: string, revokeUrl: boolean, index: 0} | null) {
        if (!obj && this.mapImageUrl) {
            URL.revokeObjectURL(this.mapImageUrl.url);
        }
        this.mapImageUrl = obj;
    }

    loadMap(id: string): void {
        try {
            this.mapRef = new ImageMapEditor(id, {
              controls: true,
              onLayerCreate: (callback: any) => {
                this.mapLayerCreateCallback = callback;
                this.setIsSkuSelectionVisible(true);
              },
            });
        } catch (err: any) {
            console.error('image map err: failed to init / load image')
        }
    }

    get isLoading(): boolean {
        return this.products.isLoading;
    }

    get filteredQueryResults(): IImageMap[] {
        if (this.searchQuery.length === 0) {
            return this.products.value.data ?? [];
        }
        return this.products.value.data ?? [];
    }

    setSearchQuery(s: string) {
        this.searchQuery = s.trim();
    }

    loadImageMap() {
        this.products.fetch('newProduct=true');
    }

    togglePanel(v: boolean) {
        this.showPanel = v;
        console.log("Panel toggle", v);
    }

    toggleFormsPanel(v: boolean) {
        this.showFormsPanel = v;
    }

    editImageMap(products?: IImageMap) {
        this.showFormsPanel = true;
        this.currentNewProduct = products;
    }
    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.products.error || this.products.error) {
            message.error(errorMsg)
        } else {
            message.success(successMsg)
        }
    }

    async submitImage(product: FormData) {
        try {
            if (this.currentNewProduct !== undefined) {
                await this.products.update(product);
                this.showMessage({
                   successMsg: 'Product map added.',
                   errorMsg: 'Could not add product map.',
                });
            } else {
                await this.products.add(product);
                this.showMessage({
                    successMsg: 'Product map updated.',
                    errorMsg: 'Could not update product map.',
                });
            }

        } catch (e) {
            console.error('Error updating/adding product map', e);
        }

        if (!this.products.error) {
            this.loadImageMap();
        }
        this.showFormsPanel = false;
    }

    async removeImageMap(productMap: IImageMap) {
        const queryParameter = 'newProduct=true';
        try {

            await this.products.remove(productMap, queryParameter);
            this.showMessage({
                successMsg: 'Products removed.',
                errorMsg: 'Could remove add products.',
            });
        } catch (e) {
            console.error('Error removing products', e);
        }
    }
}
