
import { APP_URL } from  "../../config/axios-config";
import { ISurvey} from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getSurvey = async (): Promise<ResponseFormat<ISurvey>> => {
    const response = await APP_URL.get<ISurvey[]>('survey');
    return { data:response.data };
}

export const addSurvey = async (body: ISurvey, survey: ISurvey[]): Promise<ResponseFormat<ISurvey>> => {
    const response = await APP_URL.post(`survey`, body);

    survey.push(response.data as ISurvey);
    return { data: survey };
}

export const updateSurvey = async (body: ISurvey, survey: ISurvey[]): Promise<ResponseFormat<ISurvey>> => {
    survey = [...survey]
    await APP_URL.put(`survey/${body.id}`, body);

    const elementPos = survey.map(x => x.id).indexOf(body.id);
    survey[elementPos!] = body;
    return { data: survey };
}

export const deleteSurvey = async (body: ISurvey, survey: ISurvey[]): Promise<ResponseFormat<ISurvey>> => {
    await APP_URL.delete(`survey/${body.id}`);

    survey.splice(survey.indexOf(body), 1);
    return { data: survey };
}






