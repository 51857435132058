import { Select, SelectProps, Spin } from "antd";
import debounce from "lodash.debounce";
import React from "react";

export interface DebounceSearchProps<ValueType = any> extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
    placeholder?: string
}

export type DebounceSelectProp = {
    key?: string;
    label: React.ReactNode;
    value: string | number
}

const DebounceSearch = <ValueType extends DebounceSelectProp>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSearchProps) => {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<ValueType[]>([]);

    const fetchRef = React.useRef(0);

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value)
                .then(newOptions => {
                    if (fetchId !== fetchRef.current) {
                        // for fetch callback order
                        return;
                    }

                    setOptions(newOptions);
                    setFetching(false);
                });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout, fetchOptions]);

    return (
        <Select<ValueType>
            showSearch
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

export default DebounceSearch;