import { APP_URL } from "../../config/axios-config";
import { IMFAStatus } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const postMFAStatus = async (formData: FormData, mfaStatuses: IMFAStatus[]): Promise<ResponseFormat<IMFAStatus>> => {
    const response = await APP_URL.post('Client/MFAStatus', formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });

    mfaStatuses.push(response.data as IMFAStatus);
    return { data: mfaStatuses }; 
}

export const getMFAStatusByUserId = async (userId: string): Promise<ResponseFormat<IMFAStatus[]>> => {
    const response = await APP_URL.get(`Client/MFAStatus/${userId}`);
    const items: IMFAStatus[][] = [response.data];
    return { data: items };
}