import { APP_URL } from "../../config/axios-config";
import { ICategory } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getCategories = async (queryParameters?: string): Promise<ResponseFormat<ICategory>> => {
    const response = await APP_URL.get(`category?${queryParameters || ''}`, {
        headers: {
            'X-Is-Admin': 'true'
        }
    });

    const categories: ICategory[] = response.data;
    return { data: categories };
}

// 

export const addCategory = async (formData: FormData, categories: ICategory[]): Promise<ResponseFormat<ICategory>> => {
    const response = await APP_URL.post('category', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    categories.push(response.data as ICategory);
    return { data: categories };
}

export const updateCategory = async (formData: FormData, categories: ICategory[]): Promise<ResponseFormat<ICategory>> => {
    categories = [...categories];

    const categoryId = formData.get('id');
    const response = await APP_URL.put(`category/${categoryId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    const updatedCategory = response.data as ICategory;
    const elementPos = categories.findIndex(x => x.id === updatedCategory.id);
    if (elementPos !== -1) {
        categories[elementPos] = updatedCategory;
    }
    return { data: categories };
}

export const deleteCategory = async (body: ICategory, categories: ICategory[]): Promise<ResponseFormat<ICategory>> => {
    await APP_URL.delete(`category/${body.id}`);

    return { data: categories };
}