import { DatePicker, Form, FormInstance, Input, message, Button, Upload, UploadProps } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Buttons, Files, Search } from "..";
import { copyObjects } from "../../services";
import { formDataValues } from "../../services/formData";
import { Store } from "../../stores/stores";
import { IBanner } from "../../utils/interfaces";
import { DebounceSelectProp } from "../Search/DebounceSearch";
import { UploadOutlined } from '@ant-design/icons';
import CSVReader from "react-csv-reader";
import { RiFontSize } from "react-icons/ri";

type NewBannerProp = {
    banner?: IBanner,
    onFormFinish: (bnr: FormData) => void,
    isLoading?: boolean
};

const NewBanner = observer(({ banner, isLoading, onFormFinish }: NewBannerProp) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [removedFiles, setRemovedFiles] = useState<string[]>([]);
    const [selectedFile, setSelectedFile] = useState(0);
    const formRef = useRef<FormInstance>(null);
    const { inventoryStore } = Store;
    const initialValues = copyObjects(banner, {
        fromDate: banner?.fromDate ? moment(banner.fromDate) : undefined,
        toDate: banner?.toDate ? moment(banner.toDate) : undefined,
        invList: banner?.bannerSku.map(x => x.inventoryMaster?.sku),
        skuList: banner?.bannerSku.map(x => x.skuMaster?.sku),

    });

    const selectComplementaryItems = ({ ...props }: any) => {
        return (
            <Search.DebounceSearch<DebounceSelectProp>
                fetchOptions={(_) => inventoryStore.searchInventoryMaster(_)}
                mode="multiple"
                {...props}
            />
        );
    };

    const formData = [
        { name: 'title', label: "Title", component: Input, required: true },
        { name: 'fromDate', label: "From", component: DatePicker, required: false },
        { name: 'toDate', label: "To", component: DatePicker, required: false },
        { name: 'invList', label: "Linked Stock", component: selectComplementaryItems, required: false },
        { name: 'skuList', label: "Linked Stock", component: selectComplementaryItems, required: false, hidden: true },
    ];

    // function to remove any repeating values in the list
    function uniqueFilter(value: any, index: any, self: string | any[]) {
        return self.indexOf(value) === index;
    }


    // onclick events for the span component and the upload icon
    const triggerCSV = () => {
        const fileInput = document.querySelector('.csv-reader-button input') as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        }
    };



    // Code to append the current values inside the 'invList' with csv values without any empty fields
    const handleFileLoaded = (data: string[][], fileInfo: any) => {
        const csvValues = data.slice(1).map(row => row[0]).filter(value => value.trim() !== "");
        const prevCodes = formRef.current?.getFieldValue('invList') || [];
        formRef.current?.setFieldsValue({ 'invList': [...prevCodes, ...csvValues].filter(uniqueFilter) });
    };

    const onFinishFailed = (errorInfo: any) => {
        if (!banner) {
            message.error('Could not add banner.')
        } else {
            message.error('Could not update banner.')
        }
    };

    const onFinish = (values: any) => {
        let formData = new FormData();
        let formValues = copyObjects(banner, values);

        if (banner) {
            formData.append('id', banner.id);
        }

        if (values.toDate && values.fromDate) {
            const fromDate = moment(values.fromDate).startOf("day");
            const toDate = moment(values.toDate).startOf("day");

            if (!fromDate.isSameOrBefore(toDate)) {
                message.error('Enter valid date range.');
                return;
            }
            formData.append('fromDate', fromDate.toISOString());
            formData.append('toDate', toDate.toISOString());
        } else if (values.toDate || values.fromDate) {
            message.error('Enter valid date range.');
            return;
        }

        if (fileList[0]?.originFileObj || fileList[0]?.url) {
            formData = formDataValues(formData, formValues)
            if (fileList[0]?.originFileObj) {
                formData.append('imageToUpload', fileList[0].originFileObj)
            }
            if (removedFiles.length > 0) {
                formData.append('imageToDelete', removedFiles[0])
            }
            onFormFinish(formData)
        } else {
            message.error('Add banner image.')
        }
    }

    const validateMessages = {
        required: "Field is required!",
    };


    useEffect(() => {
        if (banner) {
            setFileList([{
                uid: banner?.imageUrl,
                name: banner?.imageUrl,
                url: banner?.imageUrl,
            }]);
        } else {
            setFileList([]);
        }
    }, [banner]);

    return (
        <Form
            ref={formRef}
            name='new-banner'
            className='custom-form inventory-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout='vertical'
            requiredMark={false}
            validateMessages={validateMessages}
            initialValues={initialValues}
        >
            <Files.ImagesPreviewUpload
                name='bannerImage'
                title={'Add Main Image'}
                maxCount={1}
                fileList={fileList}
                selectedFile={selectedFile}
                setFileList={setFileList}
                setSelectedFile={setSelectedFile}
                onRemoveUrlImage={(url) => setRemovedFiles(arr => [...arr, url])}
            />
            {formData.map(({ component: Component, ...item }, index) =>
                !item.hidden && (
                    <>


                        <Form.Item
                            key={index}
                            rules={[{ required: item.required }]}
                            {...item}
                        >
                            <Component />

                        </Form.Item>
                        {/* Upload icon and the upload icon */}
                        {item.name === 'invList' && <>
                            <div className="csvDiv">
                                <UploadOutlined onClick={triggerCSV} className="uploadIcon" />
                                <span onClick={triggerCSV} className="custom-csv-label"  >Upload CSV</span>

                                <CSVReader
                                    cssClass="csv-reader-button"
                                    onFileLoaded={handleFileLoaded}
                                    label="Upload CSV"
                                    cssLabelClass="custom-csv-label" // Make sure to match the class name here
                                />
                            </div>
                        </>

                        }

                    </>
                )
            )}
            <div className='custom-form-submit'>
                <Buttons.Small
                    htmlType='submit'
                    text={banner ? 'Update banner' : 'Add banner'}
                    loading={isLoading}
                    disabled={isLoading}
                />
            </div>
        </Form>
    );
});

export default NewBanner;
