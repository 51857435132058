import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { addStaff, deleteStaff, getStaff, updateStaff } from "../services/api-service/staff";
import { IMessage, IUserAuth } from "../utils/interfaces";
import { message } from "antd";
import { ResponseFormat } from "../utils/types";
import { copyObjects } from "../services";

export class StaffStore {
    staff = new LoadManager<IUserAuth>({ data: [] }, getStaff, addStaff, deleteStaff, updateStaff);

    query = "";
    showPanel = false;
    currentStaff: IUserAuth | null = null;

    constructor() {
        makeObservable(this, {
            staff: observable,
            query: observable,
            setQuery: action,
            getStaff: computed,
            showPanel: observable,
            currentStaff: observable,
            panelTitle: computed,
            addNew: action,
            editStaff: action,
            resetForm: action,
            isLoading: computed,
            loadStaff: action,
            onFormSubmit: action,
            removeStaff: action,
        })
    }

    setQuery(s: string) {
        this.query = s.trim();
    }

    get getStaff(): ResponseFormat<IUserAuth> {
        if (this.query.length === 0) {
            return this.staff.value;
        } else {
            const data = this.staff.value.data.filter(stff => {
                return (
                    String(stff?.firstName).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(stff?.lastName).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(stff?.emailAddress).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(stff?.role).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(stff?.userType?.name).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                )
            });

            return { data: data }
        }
    }

    get panelTitle(): string {
        return this.isNew ? 'Add new staff member' : 'Edit staff member';
    }

    get isNew(): boolean {
        return !this.currentStaff;
    }

    addNew() {
        this.showPanel = true;
        this.currentStaff = null;
    }

    editStaff(staff: any) {
        this.showPanel = true;
        this.currentStaff = staff;
    }

    resetForm() {
        this.showPanel = false;
        this.currentStaff = null;
    }

    get isLoading(): boolean {
        return this.staff.isLoading;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.staff.error) {
            message.error(errorMsg)
        } else {
            message.success(successMsg)
            this.resetForm();
        }
    }

    // Load staff from api
    loadStaff() {
        this.staff.fetch()
    }

    // add staff from api
    async onFormSubmit(staff: IUserAuth) {
        let updatedBody: IUserAuth | undefined;

        try {
            if (this.currentStaff) {
                updatedBody = copyObjects(this.currentStaff, staff);
                await this.staff.update(updatedBody);
                this.showMessage({
                    successMsg: 'Staff member updated.',
                    errorMsg: 'Could not update staff member.',
                });
                this.loadStaff();
            } else {
                await this.staff.add(staff)
                updatedBody = this.staff.value.data.find(x => x.id === staff.id);
                this.showMessage({
                    successMsg: 'New staff member added.',
                    errorMsg: 'Could not add new staff member.',
                });
            }

        } catch (e) {
            console.log('Error updating/adding staff', e);
        }
    }

    async removeStaff() {
        try {
            if (this.currentStaff) {
                await this.staff.remove(this.currentStaff)
            }

            this.showMessage({
                successMsg: 'Staff member deactivated.',
                errorMsg: 'Could not deactivate staff member.',
            });

            this.loadStaff();
            this.resetForm();
        } catch (e) {
            console.log('Error deleting staff', e);
        }
    }
}