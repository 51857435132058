import { Table, Popconfirm } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Buttons, Spinners, Drawers, Forms } from "..";
import { Store } from "../../stores/stores";
import { IContentSlider } from "../../utils/interfaces";
import { ContentSliderPosition } from "../../utils/enums";
import { ColumnType } from "antd/lib/table";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const ContentSlider = observer(() => {
    const {  contentSliderStore } = Store;
    const sliders = contentSliderStore.contentSliders.value;

    React.useEffect(() => {
        contentSliderStore.loadContentSliders()
    }, [contentSliderStore])
    
    type PageLabelMap = {
        home_page: string;
        about_page: string;
        special_page: string;
    };
    

    const pageLabelMap: PageLabelMap = {
        home_page: 'Home Page',
        about_page: 'About Page',
        special_page: 'Specials Page'
    };

    // Create a mapping of enum values to display labels
    const positionLabelMap = {
        [ContentSliderPosition.LEFT]: 'Left side',
        [ContentSliderPosition.CENTER]: 'Center',
        [ContentSliderPosition.RIGHT]: 'Right side',
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'imageLaptopUrl',
            key: 'imageUrl',
            fixed: 'left' as 'left',
            width: 140,
            render: (imageUrl: string) => <img src={imageUrl} className='table-content-slider-image' alt="banner" />,
        },
        {
            title: 'Page',
            dataIndex: 'refNo',
            fixed: 'left' as 'left',
            width: 100,
            key: 'refNo',
            filters: [
                { text: 'Home Page', value: 'home_page' },
                { text: 'About Page', value: 'about_page' },
                { text: 'Specials Page', value: 'special_page' }
            ],
            onFilter: (value: string, record: IContentSlider) => record.refNo === value,
            render: (refNo: string) => pageLabelMap[refNo as keyof PageLabelMap] || refNo, 
        },
        {
            title: 'Header Text',
            dataIndex: 'headerText',
            key: 'headerText',
        },
        {
            title: 'Sub Header Text',
            dataIndex: 'subHeaderText',
            key: 'subHeaderText',
        },
        {
            title: 'Sub Text',
            dataIndex: 'subText',
            key: 'subText',
        },
        {
            title: 'Position',
            dataIndex: 'positionEnum',
            key: 'positionEnum',
            render: (position: ContentSliderPosition) => positionLabelMap[position],
        },
        {
            title: 'Position Number',
            dataIndex: 'positionNumber',
            key: 'positionNumber',
        },
        {
            title: 'Page Link',
            dataIndex: 'pageLink',
            key: 'pageLink',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => moment(createdAt).format('DD-MM-YYYY'),
        },
        {
            title: 'Overlay',
            dataIndex: 'overlay',
            key: 'overlay',
            render: (overlay: boolean) => overlay ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} /> },
        {
            title: 'Edit',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 80,
            render: (text: any, slider: IContentSlider) => (
                <>
                     <Buttons.Text
                        text="Edit"
                        onClick={() => contentSliderStore.editContentSlider(slider)}
                    />
                    <Popconfirm
                            title='Are you sure to delete this slider?'
                            onConfirm={() => contentSliderStore.removeContentSlider(slider)}
                            okText='Yes'
                            cancelText='No'
                            placement='topRight'
                    >
                        <Buttons.Text text="Delete"/>
                    </Popconfirm>

                </>
                   
                    
            ),
            
        },
    ];

    return (
        <Spinners.CustomSpinner
            isSpinning={contentSliderStore.isLoading}
            title={"Getting Banners"}
        >
            <div className='form-options-container'>
                <Buttons.Text
                    text="Add New"
                    onClick={() => contentSliderStore.editContentSlider(null)}
                    loading= {false}
                    disabled={false}
                />
            </div>
            <div className='custom-table-container'>
                <Table
                    dataSource={sliders.data}
                    columns={columns as ColumnType<IContentSlider>[]}
                    rowKey='id'
                    className='custom-table contractpricing-table'
                    scroll={{ x: 2500 }}
                    pagination={false}
                />
            </div>
            <Drawers.CustomDrawer
                title="Edit Website Banner"
                visible={contentSliderStore.showFormsPanel}
                setVisible={(_) => contentSliderStore.resetContentSliderForm()}
                children={() => (
                    <Forms.NewBannerSlide
                        slider={contentSliderStore.currentContentSlider}    
                        onFormFinish={(_) => contentSliderStore.submitContentSlider(_)}
                        onSliderDelete={() => {}}
                        isLoading={contentSliderStore.isLoading}
                    />
                )}
            />
        </Spinners.CustomSpinner >
    )
});

export default ContentSlider;



