import { DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Image } from "antd";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { BsArrowRight } from "react-icons/bs";
import { Files } from "..";
import { FALLBACK_IMAGE } from "../../config/storage";
import {PiMapPinLineLight} from "react-icons/pi"

interface ImagesPreviewUploadProp extends UploadProps {
    fileList: UploadFile<any>[];
    setFileList: any;
    selectedFile: number;
    setSelectedFile: (i: number) => any;
    onRemoveUrlImage?: (url: string, idx: number) => void;
    onMapIconClick?: (e: any) => void,
    title?: string;
}

export const getBase64UploadFile = (file: RcFile): any => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const ImagesPreviewUpload = ({
    title,
    fileList,
    setFileList,
    selectedFile,
    setSelectedFile,
    onRemoveUrlImage,
    onMapIconClick,
    ...rest
}: ImagesPreviewUploadProp) => {

    const handleImageSelect = async (fileList: UploadFile<any>[]) => {
        const files: UploadFile<any>[] = [];
        for (let index = 0; index < fileList.length; index++) {
            const file = fileList[index];
            if (file?.originFileObj) {
                file.preview = await getBase64UploadFile(file.originFileObj);
            }

            files.push(file);
        }
        setFileList(files);
    }

    const handleImageChange = (imageIndex: number) => {
        setSelectedFile(imageIndex);
    }

    const handleRemovedImage = (imageIndex: number) => {
        setSelectedFile(0);
        const image = fileList[imageIndex];
        if (image?.url && onRemoveUrlImage) {
            onRemoveUrlImage(image.url, imageIndex)
        }

        setFileList((arr: any) => arr.filter((x: any, index: any) => index !== imageIndex));
    }

    const nextImage = () => {
        if (selectedFile !== fileList.length - 1) {
            setSelectedFile(selectedFile + 1)
        }
    }

    return (
        <>
            <div className='inventory-form-image-main'>
                {fileList.length > 0 &&
                    <Image
                        src={fileList[selectedFile]?.preview || fileList[selectedFile]?.url}
                        alt="product"
                        height='100%'
                        width='100%'
                        fallback={FALLBACK_IMAGE}
                    />
                }
            </div>

            <div className='inventory-form-image-add'>
                {fileList.length > 0 &&
                    <>
                        <div className='inventory-form-image-list'>
                            {fileList.map((file, index) => (
                                <div style={{ marginRight: '12px' }}>
                                    <div className='inventory-form-image-small'>
                                        <Image
                                            className='inventory-form-image-small-img'
                                            src={file?.preview || fileList[index]?.url}
                                            alt="product"
                                            onClick={() => handleImageChange(index)}
                                            preview={false}
                                            fallback='https://storage.googleapis.com/gfox-public/images/fallback.png'
                                        />
                                    </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingLeft: '0.2em' }}>
                                            {onMapIconClick && (
                                                <PiMapPinLineLight
                                                    onClick={() => {
                                                        setSelectedFile(index);
                                                        onMapIconClick(index)
                                                    }}
                                                    style={{ cursor: "pointer", color: '#f5222d' }}
                                                />
                                            )}
                                            <DeleteTwoTone
                                                onClick={() => handleRemovedImage(index)}
                                                style={{ color: '#f5222d' }}
                                            />
                                        </div>
                                </div>

                            ))}
                        </div>
                        {rest?.maxCount && rest?.maxCount > 1 &&
                            <div className='inventory-form-image-indicator'>
                                <span>{` ${selectedFile + 1} of ${fileList.length}`}</span>
                                <BsArrowRight
                                    size={16}
                                    className='inventory-form-image-next'
                                    onClick={nextImage}
                                />
                            </div>
                        }
                    </>
                }

                <Files.CustomUpload
                    accept='image/*'
                    fileList={fileList}
                    setFileList={setFileList}
                    itemRender={() => <div></div>}
                    onChange={(_) => handleImageSelect(_.fileList)}
                    {...rest}
                    children={() => (
                        <Button
                            className='inventory-form-image-addbtn'
                            icon={<PlusOutlined style={{}} />}
                        >
                            {title}
                        </Button>
                    )}
                />
            </div>
        </>
    )
}

export default ImagesPreviewUpload;
