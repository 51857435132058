import { APP_URL } from "../../config/axios-config";
import { IStaffRequestHandler, IUserAuth } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getStaff = async (): Promise<ResponseFormat<IUserAuth>> => {
    const response = await APP_URL.get('staff');

    let staff: IUserAuth[] = [];

    if (response?.data) {
        staff = response.data;
    }
    return { data: staff };
}

export const addStaff = async (body: IUserAuth, staff: IUserAuth[]): Promise<ResponseFormat<IUserAuth>> => {
    const slug = body?.userType?.slug;
    body.userType = undefined;

    const response = await APP_URL.post(`staff/${slug}`, body);

    staff.push(response.data as IUserAuth);
    return { data: staff };
}

export const updateStaff = async (body: IUserAuth, staff: IUserAuth[]): Promise<ResponseFormat<IUserAuth>> => {
    staff = [...staff]

    const requestBody: IStaffRequestHandler = {
        userAuth: body,
        userTypeSlug: body?.userType?.slug
    }

    const response = await APP_URL.put(`staff/${body.id}`, requestBody);

    body.userType = response.data?.userType;
    const elementPos = staff.map(x => x.id).indexOf(body.id);
    staff[elementPos!] = body;
    return { data: staff };
}

export const deleteStaff = async (body: IUserAuth, staff: IUserAuth[]): Promise<ResponseFormat<IUserAuth>> => {
    await APP_URL.delete(`staff/${body.id}`);

    staff.splice(staff.indexOf(body), 1);
    return { data: staff };
}