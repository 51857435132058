import { APP_URL } from "../../config/axios-config";
import { IPaystackTransactionRequest } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getPaystackTransaction = async (queryParameters?: string): Promise<ResponseFormat<IPaystackTransactionRequest>> => {
    const response = await APP_URL.get('pay/paystack/transaction?' + queryParameters || '');

    const inventoryMasters: IPaystackTransactionRequest[] = response.data;
    return { data: inventoryMasters };
}
