import { Form, Input, message } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppLogo } from "../../../assets";
import { Buttons, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import "./style.css";

const AccountVerification = observer(() => {
    const { token }: any = useParams();
    const history = useHistory();
    const { emailTokensStore } = Store;

        // Function to parse query parameters
    const getQueryParam = (param: string) => {
        // eslint-disable-next-line no-restricted-globals
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    };

    const onFinish = () => {
           const isSourceWebsite = getQueryParam('source') === 'website';
        console.log('query');
        if (isSourceWebsite) {
            window.location.href = 'https://gfox.co.za/';
        } else {
            history.replace('/');
        }
    };


    const onFinishFailed = (errorInfo: any) => {
        message.error('Could not create password.');
    };

    React.useEffect(() => {
        if (token) {
            emailTokensStore.loadEmailToken(token);
        }
    }, [emailTokensStore, token]);

    return (
        <Spinners.CustomSpinner
            title={"Getting Account"}
            isSpinning={emailTokensStore.isLoading}
        >
            <main id="account-verification">
                <img className='account-verification-applogo' src={AppLogo} alt="G.Fox logo" />

                {emailTokensStore.tokenValid && (
                    <>
                        <h1 className='header account-verification-header'>Account Verification</h1>

                        <div className='landing-login-form'>
                            <Form
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={(values => emailTokensStore.onCreatePassword(values, onFinish))}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    name="password"
                                    rules={[{
                                        required: true,
                                        message: emailTokensStore.passwordRequirements,
                                        pattern: emailTokensStore.passwordPattern,
                                    }]}
                                >
                                    <Input.Password
                                        className='text-input account-verification-input'
                                        placeholder='Password'
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="confirmPassword"
                                    rules={[{
                                        required: true,
                                        message: emailTokensStore.passwordRequirements,
                                        pattern: emailTokensStore.passwordPattern,
                                    }]}
                                >
                                    <Input.Password
                                        className='text-input account-verification-input'
                                        placeholder='Confirm Password'
                                        size="large"
                                    />
                                </Form.Item>

                                <Buttons.Primary
                                    text={'CREATE PASSWORD'}
                                    htmlType='submit'
                                    loading={emailTokensStore.isLoading}
                                    disabled={emailTokensStore.isLoading}
                                />
                            </Form>
                        </div>
                    </>
                )}
            </main>
        </Spinners.CustomSpinner >
    );
});

export default AccountVerification;