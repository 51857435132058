import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Input, Table, Modal, Form, message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Spinners, Drawers, Forms } from "..";
import { Store }  from "../../stores/stores";
import { ICODAccountNumber } from "../../utils/interfaces";

const SpecialsNumberDrawer = observer(() => {
    const [form] = Form.useForm();
    const { CODAccountNumberStore } = Store;
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            fixed: 'left' as 'left',
            width: 70,
            render: (text: any, record: ICODAccountNumber, index: number) => ((current - 1) * pageSize) + index + 1,
        },
        {
            title: "Account Number",
            dataIndex: "accountNumber",
            width: 200,
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            width: 200,
            render: (createdAt: string) => new Date(createdAt).toLocaleString(),
        },
        {
            key: 'action',
            fixed: 'right' as 'right',
            width: 140,
            render: (text: any, record: ICODAccountNumber) => (
                <span>
                    <Buttons.Text text="Edit" onClick={() => handleEdit(record)} />
                    <Buttons.Text text="Delete" onClick={() => handleDelete(record)} />
                </span>
            ),
        },
    ];

    const handleDelete = async (record: ICODAccountNumber) => {
        CODAccountNumberStore.editCODAccountNumber(record);
        await CODAccountNumberStore.removeCODAccountNumber();
    };
    const handleEdit = (record: ICODAccountNumber) => {
        CODAccountNumberStore.editCODAccountNumber(record);
    };

    useEffect(() => {
        if (CODAccountNumberStore.showPanel) {
            CODAccountNumberStore.loadCODAccountNumbers();
        }
    }, [CODAccountNumberStore.showPanel]);

    return (
        <><Drawer
            className='custom-drawer'
            title="COD Account Numbers"
            visible={CODAccountNumberStore.showPanel}
            onClose={() => CODAccountNumberStore.togglePanel(false)}
            width="60%"
            children={(
                <>
                    <div className='form-options-container'>
                        <Buttons.Text
                            text="Add new"
                            loading={false}
                            disabled={false}
                            onClick={() => CODAccountNumberStore.addCODAccountNumber()} />
                    </div>
                    <Input
                        className='pageheader-options-search orders-search'
                        placeholder="Search list..."
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        disabled={CODAccountNumberStore.isLoading}
                        onChange={(e) => CODAccountNumberStore.setSearchQuery(e.target.value)}
                        style={{ marginTop: '1em', marginBottom: '2em', width: '50%' }} />
                    <div className='custom-table-container'>
                        <Spinners.CustomSpinner
                            title={'Getting COD Account Numbers'}
                            isSpinning={CODAccountNumberStore.isLoading}
                        >
                            <Table
                                rowKey='id'
                                className='custom-table'
                                columns={columns}
                                dataSource={CODAccountNumberStore.filteredCODAccountNumbers}
                                pagination={{
                                    pageSize: pageSize,
                                    current: current,
                                    onChange: (page, pageSize) => {
                                        setCurrent(page);
                                        setPageSize(pageSize);
                                    }
                                }} />
                        </Spinners.CustomSpinner>
                    </div>
                </>
            )} />
            <Drawers.CustomDrawer
                title="COD Account Number"
                visible={CODAccountNumberStore.showFormsPanel}
                setVisible={(_) => CODAccountNumberStore.resetCODForm()}
                children={() => (
                    <Forms.NewCODAccountForm
                        codAccountNumber={CODAccountNumberStore.currentCODAccountNumber}
                        onFormFinish={(_) => CODAccountNumberStore.onFormSubmit(_)}
                        isLoading={CODAccountNumberStore.isLoading} />
            )} /></>
    );
});

export default SpecialsNumberDrawer;
