import React from 'react';
import { EditorComposer, Editor, ToolbarPlugin, BoldButton, ItalicButton, UnderlineButton, TextColorPicker, BackgroundColorPicker, FontFamilyDropdown, FontSizeDropdown, Divider, CodeFormatButton, InsertLinkButton, TextFormatDropdown, InsertDropdown, AlignDropdown } from 'verbum';
import { LexicalEditor } from "lexical";

type HtmlEditorProps = {
  onChange: (editorState: string, editor?: LexicalEditor) => void;
  initialEditorState?: string;
};

const HtmlEditor = ({ onChange ,initialEditorState}: HtmlEditorProps) => {
  return (
    <EditorComposer initialEditorState={initialEditorState}>
      <Editor hashtagsEnabled={true} onChange={onChange as any}>
        <ToolbarPlugin defaultFontSize="20px">
          <FontFamilyDropdown />
          <FontSizeDropdown />
          <Divider />
          <BoldButton />
          <ItalicButton />
          <UnderlineButton />
          <CodeFormatButton />
          <InsertLinkButton />
          <TextColorPicker />
          <BackgroundColorPicker />
          <TextFormatDropdown />
          <Divider />
          <InsertDropdown 
            enablePoll={false} 
            enableImage={{ 
              enable: true, 
              maxWidth: 720,
            }}
          />
          <Divider />
          <AlignDropdown />
        </ToolbarPlugin>
      </Editor>
    </EditorComposer>
  );
};

export default HtmlEditor;
