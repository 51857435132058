import React, { useState, useEffect } from 'react';
import { Table, Space, TableColumnsType, message, Input, Modal } from 'antd';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { RiSearchLine } from "react-icons/ri";
import CategoryModal from "../../../components/Modals/CategoryModal";
import { Buttons, Forms, ExpandedTables, Spinners, Drawers } from "../../../components";
import { observer } from 'mobx-react-lite';
import { ICategory } from "../../../utils/interfaces";
import { Store } from "../../../stores/stores";

const CategoryManagement: React.FC = observer(() => {
  const { inventoryStore } = Store;
  const [modalVisible, setModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [subcategoryDrawerVisible, setSubcategoryDrawerVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState<ICategory | null>(null);
  const [selectedParentCategory, setSelectedParentCategory] = useState<ICategory | null>(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      await inventoryStore.getCategories();
    } catch (error) {
      console.error("Error loading categories:", error);
      message.error('Failed to load categories');
    }
  };

  const handleEdit = (category: ICategory) => {
    setEditingCategory(category);
    setSelectedParentCategory(category.parentCategoryId ? inventoryStore.categoriesData.find(cat => cat.id === category.parentCategoryId) || null : null);
    setDrawerVisible(true);
  };

  const handleAddSubcategory = (parentCategory: ICategory) => {
    setEditingCategory(null);
    setSelectedParentCategory(parentCategory);
    setDrawerVisible(true);
  };

  const handleDelete = (refNo: string) => {
    const categoryToDelete = inventoryStore.categoriesData.find(cat => cat.refNo === refNo);
    
    if (!categoryToDelete) {
        message.error('Category not found');
        return;
    }

    if (categoryToDelete.inverseParentCategory && categoryToDelete.inverseParentCategory.length > 0) {
        message.error('Cannot delete category with subcategories');
        return;
    }

    Modal.confirm({
        title: 'Are you sure you want to delete this category?',
        icon: <ExclamationCircleOutlined />,
        content: 'This action cannot be undone.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            return inventoryStore.deleteCategory(categoryToDelete)
                .then(() => {
                    message.success('Category deleted successfully');
                    loadCategories();
                })
                .catch((error) => {
                    message.error('Failed to delete category: ' + error.message);
                });
        },
    });
  };

  const handleAddCategory = () => {
    setEditingCategory(null);
    setSelectedParentCategory(null);
    setDrawerVisible(true);
  };

  const handleFormSubmit = async (formData: FormData) => {
    try {
      if (editingCategory) {
        // Editing existing category
        formData.append('id', editingCategory.id);
        await inventoryStore.editCategory(formData);
        message.success('Category updated successfully');
      } else {
        // Adding new category
        if (selectedParentCategory) {
          formData.append('parentCategoryId', selectedParentCategory.id);
        }
        await inventoryStore.addNewCategory(formData);
        message.success('Category added successfully');
      }

      setDrawerVisible(false);
      setModalVisible(false);
      setSubcategoryDrawerVisible(false);
      setEditingCategory(null);
      await loadCategories();

      if (!editingCategory && selectedParentCategory) {
        setExpandedRowKeys(prevKeys => [...prevKeys, selectedParentCategory.refNo]);
        await inventoryStore.loadSubcategories(selectedParentCategory);
      }
    } catch (error) {
      console.error(`Error ${editingCategory ? 'updating' : 'adding'} category:`, error);
      message.error(`Failed to ${editingCategory ? 'update' : 'add'} category`);
    }
  };

  const handleExpand = async (expanded: boolean, record: ICategory) => {
    let loadingMessage: any;
    if (expanded) {
      try {
        loadingMessage = message.loading('Loading subcategories...', 0);
        await inventoryStore.loadSubcategories(record);
      } catch (error) {
        console.error("Error loading subcategories:", error);
        message.error('Failed to load subcategories');
      } finally {
        loadingMessage?.();
        inventoryStore.setLoadingSubCategories(false);
      }
    }
    setExpandedRowKeys(expanded 
      ? [...expandedRowKeys, record.refNo]
      : expandedRowKeys.filter(key => key !== record.refNo)
    );
  };

  const columns: TableColumnsType<ICategory> = [
    {
      title: 'Category',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => <div className='long-text'>{text}</div>,
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
      render: (featured: boolean | null) => (
        featured ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      filters: [
        { text: 'Active', value: true },
        { text: 'Not Active', value: false },
      ],
      onFilter: (value: any, record: ICategory) => record.active === value,
      render: (active: boolean | null) => (
        active ? (
          <CheckOutlined style={{ color: 'green' }} />
        ) : (
          <CloseOutlined style={{ color: 'red' }} />
        )
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right' as 'right',
      render: (_, record) => (
        <Space size="middle">
          <Buttons.Text
            text='Add Subcategory'
            onClick={() => handleAddSubcategory(record)}
          />
          <Buttons.Text
            text='Edit'
            onClick={() => handleEdit(record)}
          />
          <Buttons.Text
            text='Delete'
            onClick={() => handleDelete(record.refNo)}
          />
        </Space>
      ),
    },
  ];

  return (
    <main id="category-management" className="home-content-page">
      <div className="pageheader-container">
        <h1 className="header">Category Management</h1>
        <div className="pageheader-options">
          <Input
            size="large"
            className="pageheader-options-search"
            placeholder="Search categories..."
            prefix={<RiSearchLine color="#CBD5E0" />}
            disabled={inventoryStore.isCategoriesLoading}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Buttons.Small
            text={"Add new"}
            disabled={inventoryStore.isCategoriesLoading}
            onClick={handleAddCategory}
          />
        </div>
      </div>

      <div className="custom-table-container">
        <Spinners.CustomSpinner
          title="Loading Categories"
          isSpinning={inventoryStore.isCategoriesLoading}
        >
          <Table
            dataSource={inventoryStore.categoriesData.filter(
            (cat) =>
                  cat.parentCategoryId === null &&
                  ((cat.name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
                    (cat.description?.toLowerCase() || '').includes(searchQuery.toLowerCase()))
            )}
            columns={columns}
            rowKey="refNo"
            className="custom-table"
            expandable={{
              expandedRowRender: (record) => (
                <ExpandedTables.ExpandedCategoryTable
                  category={record}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onExpand={handleExpand}
                  onAddSubcategory={handleAddSubcategory}
                  isLoading ={inventoryStore.isLoadingSubCategories}
                />
              ),
              expandedRowKeys,
              onExpand: handleExpand,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <MinusSquareOutlined onClick={e => onExpand(record, e)} />
                ) : (
                  <PlusSquareOutlined onClick={e => onExpand(record, e)} />
                )
            }}
          />
        </Spinners.CustomSpinner>
      </div>

      <Drawers.CustomDrawer
        title={editingCategory ? "Edit Category" : selectedParentCategory ? "Add New Subcategory" : "Add New Category"}
        visible={drawerVisible}
        setVisible={(visible) => {
          setDrawerVisible(visible);
          if (!visible) {
            setEditingCategory(null);
            setSelectedParentCategory(null);
          }
        }}
        children={() => (
          <Forms.NewCategoryForm
            onFormFinish={handleFormSubmit}
            isLoading={inventoryStore.isCategoriesLoading}
            parentCategory={selectedParentCategory}
            initialValues={editingCategory || undefined}
          />
        )}
      />
    </main>
  );
});

export default CategoryManagement;