import React, { useState, useCallback, useRef, useEffect } from "react";
import { Form, Input, Modal, Switch } from "antd";
import { ICustomPage } from "../../utils/interfaces";
import HtmlEditor from "./EditorWithGenerator";
import { LexicalEditor } from "lexical";
import { $generateHtmlFromNodes } from '@lexical/html';
import { Buttons } from "..";
import { Store } from "../../stores/stores";

interface CustomPageFormProps {
  customPage?: ICustomPage;
  onFormFinish: (formData: FormData) => void;
  isLoading: boolean;
}

const NewCustomPageForm: React.FC<CustomPageFormProps> = ({ customPage, onFormFinish, isLoading }) => {
  const [form] = Form.useForm();
  const { customPageStore } = Store;
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const editorRef = useRef<{ updateEditorWithHtml: () => void }>(null);
  const [editorStateJSON, setEditorStateJSON] = useState(customPage?.previewContent || "");
  const [editorStateHTML, setEditorStateHTML] = useState(customPage?.previewHtml || "");
  const [previewUrl, setPreviewUrl] = useState('');

  const onChange = (editorState:string,editor?:LexicalEditor) => {
    // Update local state with the editor's current state
    editor?.update(() => {
        const editorState = editor?.getEditorState();
        const jsonString = JSON.stringify(editorState.toJSON());
        const htmlString = $generateHtmlFromNodes(editor, null);
        setEditorStateJSON(jsonString);
        setEditorStateHTML(htmlString);
    });
  };

  const handleSave = async () => {

    await editorRef.current?.updateEditorWithHtml();
    const pageName = form.getFieldValue('name');
    const currentEditorStateJSON = await new Promise<string>((resolve) => {
      setEditorStateJSON((jsonString) => {
        resolve(jsonString);
        return jsonString;
      });
    });
  
    const currentEditorStateHTML = await new Promise<string>((resolve) => {
      setEditorStateHTML((htmlString) => {
        resolve(htmlString);
        return htmlString;
      });
    });
  
    form.validateFields().then(async (values) => {
      let formData = new FormData();
      formData.append('previewContent', currentEditorStateJSON);
      formData.append('previewHtml', currentEditorStateHTML);
      formData.append('name', pageName);

      await customPageStore.submitPreview(formData);
      await customPageStore.loadCustomPages();
    }).catch(error => {
      console.error("Validation failed:", error);
    });
  };

  const handlePublish = async () => {
    await editorRef.current?.updateEditorWithHtml();
  
    form.validateFields().then(async values => {
      let formData = new FormData();
      formData.append('id', customPage?.id || '');
      formData.append('name', values.name);
      formData.append('isActive', String(values.isActive));
  
      await customPageStore.publishCustomPage(formData);
    }).catch(error => {
      console.error("Validation failed:", error);
    });
  };

  const showPreview = useCallback(async () => {
    await editorRef.current?.updateEditorWithHtml();
    
    const pageName = form.getFieldValue('name');
    if (pageName) {
      const baseUrl = process.env.REACT_APP_WEBSITE_DOMAIN || 'http://localhost:3000';
      const url = `${baseUrl}/pages/preview/${pageName}`;
      setPreviewUrl(url);
      setIsPreviewVisible(true);
    } else {
    }
  }, []);
  
  const handlePreviewClose = () => {
    customPageStore.toogleFormsPanel(false);
    setIsPreviewVisible(false);
  };
  

  return (
    <Form
      form={form}
      layout="vertical"
      className='custom-form-custom'
      initialValues={customPage || {}}
    >
      <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Enter page name' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Content" required>
        <HtmlEditor
          ref={editorRef}
          onChange={onChange}
          {...(editorStateJSON && { initialEditorState: editorStateJSON })} 
        />
      </Form.Item>
      <Form.Item name="isActive" label="Active" valuePropName="checked">
        <Switch />
      </Form.Item>
      <div className='txtBtn'>
        <Buttons.Text
          text='Preview'
          onClick={showPreview}
        />
        <br/>
        <p>Save to preview latest changes.</p>
      </div>
      <div className='custom-form-submit'>
        <Buttons.Small
          htmlType='button'
          text='Save'
          onClick={handleSave}
        />
      </div>
      <div className='custom-form-submit'>
        <Buttons.Small
          htmlType='button'
          text='Publish'
          onClick={handlePublish}
        />
      </div>

      <Modal
        title="Page Preview"
        visible={isPreviewVisible}
        onCancel={handlePreviewClose}
        footer={null}
        width="95%"
        style={{ top: 20 }}
        bodyStyle={{ height: '150vh', width: '100%' }}
      >
        {previewUrl && (
          <iframe
            src={previewUrl}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Page Preview"
          />
        )}
      </Modal>
    </Form>
  );
};

export default NewCustomPageForm;