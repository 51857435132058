import { action, computed, makeObservable, observable } from "mobx";
import { IBranch, IMessage, IVoucher, IVoucherConstraint } from "../utils/interfaces";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { addVoucher, deleteVoucher, getVoucher, getVoucherByRefNo, updateVoucher } from "../services/api-service/voucher"
import { message } from "antd";
import { getBranches } from "../services/api-service/branch";
import { addVoucherConstraintRange, getVoucherConstraint, updateVoucherConstraintRange } from "../services/api-service/voucher-constraint";
import axios, { } from "axios";
import { } from "../config/axios-config";
import { VoucherType } from "../utils/enums";

export type FormActiveTabType = "1" | "2";

export class VoucherStore {
    vouchers = new LoadManager<IVoucher>({ data: [] }, getVoucher, addVoucher, deleteVoucher, updateVoucher);
    voucherConstraints = new LoadManager<IVoucherConstraint>({ data: [] }, getVoucherConstraint);
    branches = new LoadManager<IBranch>({ data: [] }, getBranches );
    formActiveTab: FormActiveTabType = "1";
    searchQuery = ""
    showPanel = false;
    showEditPanel = false;
    showLoyaltyPanel = false;
    currentVoucher?: IVoucher | null = null;
    birthdayConstraint: IVoucherConstraint | null = null;
    // currentConstraint?: IVoucherConstraint[] = [];

    constructor() {
        makeObservable(this, {
            vouchers: observable,
            branches: observable,
            birthdayConstraint: observable,
            voucherConstraints: observable,
            formActiveTab: observable,
            resetSearchQuery:action,
            searchQuery: observable,
            currentVoucher: observable,
            showPanel: observable,
            showEditPanel: observable,
            isLoading: computed,
            filteredQueryResults: computed,
            togglePanel: action,
            toggleLoyaltyPanel: action,
            showLoyaltyPanel:observable,
            toggleEditPanel: action, setFormActiveTab: action,
            setSearchQuery: action,
            loadVouchers: action,
            editVoucher: action,
            resetForm: action,
            showMessage: action,
            isRefAvailable: action,
            submitVoucher: action,
            submitVoucherConstraint: action,
        })
    }

        toggleLoyaltyPanel(v: boolean) {
        this.showLoyaltyPanel = v;
    }

    get isLoading(): boolean {
        return this.vouchers.isLoading
            || this.voucherConstraints.isLoading
            || this.branches.isLoading;
    }

get filteredQueryResults(): IVoucher[] {
    let results = this.vouchers.value.data;

    if (this.searchQuery.length > 0) {
        results = results.filter(x => (
            String(x.refNo).toUpperCase().indexOf(this.searchQuery.toUpperCase()) >= 0
            || String(x.title).toUpperCase().indexOf(this.searchQuery.toUpperCase()) >= 0
            || String(x.description).toUpperCase().indexOf(this.searchQuery.toUpperCase()) >= 0
        ));
    }
    results = results.slice().sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime());
    return results;
}

    async setFormActiveTab(v: FormActiveTabType) {
        if (v === '2') {
            if (!this.currentVoucher) {
                throw new Error("voucherStore set tab: current voucher not setr");
            }

            const hide = message.loading("Getting voucher constraints");
            await this.voucherConstraints.fetch(`voucherId=${this.currentVoucher?.id}`)
            setTimeout(() => hide(), 500);
            if (this.voucherConstraints.error) {
                this.showMessage({ successMsg: '' ,errorMsg: 'Failed to failed voucher detauls'});
                return;
            }
        }
        this.formActiveTab = v;
    }

    setSearchQuery(s: string) {
        this.searchQuery = s.trim();
    }

    togglePanel(v: boolean) {
        this.showPanel = v;
    }

    toggleEditPanel(v: boolean) {
        this.setFormActiveTab("1");
        this.showEditPanel = v;
    }

    editVoucher(v: IVoucher | null) {
        this.currentVoucher = v;
        this.toggleEditPanel(true);
    }

    resetForm() {
        this.setFormActiveTab("1");
        this.toggleEditPanel(false);
        this.currentVoucher = null;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.vouchers.error || this.vouchers.error) {
            message.error(errorMsg)
        } else {
            this.resetForm();
            message.success(successMsg)
        }
    }

   async submitVoucher(voucher: IVoucher, voucherType?: VoucherType) {
    if (voucherType) {
        voucher.voucherType = voucherType;
    }
    if (this.currentVoucher) {
        await this.vouchers.update(voucher);
        this.showMessage({
            successMsg: 'Voucher added.',
            errorMsg: 'Could not add voucher.',
        });
    } else {
        await this.vouchers.add(voucher);
        this.showMessage({
            successMsg: 'Voucher updated.',
            errorMsg: 'Could not update voucher.',
        });
    }
    this.loadVouchers();
}

    async submitVoucherConstraint(vouchers: IVoucherConstraint[]) {
        this.voucherConstraints.isLoading = true;

        const toUpdateList = vouchers.filter(x => !!x.id);
        const toAddList = vouchers.filter(x => !x.id);
        try {
            await updateVoucherConstraintRange(toUpdateList);
            await addVoucherConstraintRange(toAddList, this.currentVoucher?.id);
        } catch (err) {
            message.error('Could not add/update voucher constraints');
            return
        } finally {
            message.success('Added voucher constraints');
        }

        this.voucherConstraints.isLoading = false;
        await this.setFormActiveTab("1");
        await this.setFormActiveTab("2");
    }

    async isRefAvailable(refNo: string): Promise<boolean> {
        if (refNo.trim().length === 0) {
            return false;
        }


        try  {
           const voucher = await getVoucherByRefNo(refNo);
            return !!this.currentVoucher && this.currentVoucher.id  === voucher.id;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status === 404) {
                return true // refNo does not exists
            }
            message.error('Failed to validate voucher reference.');
            console.error("voucherStore is ref available err:", err);
            return false;
        }
    }

    async loadVouchers() {
        await this.vouchers.fetch();
    }

    resetSearchQuery() {
        this.searchQuery = '';
    }
}
