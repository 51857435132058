import {Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { MdOutlineVerified } from "react-icons/md";
import { Buttons, Drawers, Forms, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import "./style.css";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { IVerificationRequest } from "../../../utils/interfaces";

export const Verification = observer(() => {
    const { clientsStore} = Store;

    const verification = clientsStore.getVerification;

    const columns : (ColumnGroupType<IVerificationRequest> | ColumnType<IVerificationRequest>)[] = [
        {
            title: '#',
            dataIndex: 'id',
            fixed: 'left',
            width: 30,
            render: (text: any, record: any, index: any) => index + 1
        },
        {
            title: 'Email Address',
            dataIndex: 'user',
            key: 'email',
            width: 200,
            render: (text: any, record: IVerificationRequest) => (
                <div>{record.user?.emailAddress ?? ''}</div> 
            )
        },
        {
            title: 'Status',
            dataIndex: 'verified', 
            width: 200,
            render: (text: any, record: any) => (
                <div>{record.verified ? 'Verified' : 'Not Verified'}</div> 
            )
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber', 
            width: 200,
            render: (text: any, record: any) => (
                <div>{record.phoneNumber}</div> 
            )
        },
    ];
    React.useEffect(() => {
        clientsStore.loadVerifications()
    }, [clientsStore]);

    return (
        <main id="home" className='home-content-page'>
            <div className='pageheader-container'>
                <h1 className='header'>Phone Number verification</h1>
                <div className='pageheader-options'>
                    <Input
                        size='large'
                        className='pageheader-options-search'
                        placeholder="Search list..."
                        defaultValue={clientsStore.verificationQuery}
                        prefix={<MdOutlineVerified color='#CBD5E0' />}
                        onChange={(_) => clientsStore.setSearchQuery(_.target.value)}
                    />
                     <Buttons.Small
                        text={'Add new'}
                        onClick={() => {
                            clientsStore.addNewVerification()
                        }}
                    />  
                </div>
            </div>

            <div className='custom-table-container'>
                <Spinners.CustomSpinner
                    title={'Getting Ratings and Reviews'}
                    isSpinning={clientsStore.isLoadingVerification}
                >
                    <Table
                        dataSource={clientsStore.searchedVerifications}
                        columns={columns}
                        className='custom-table'
                        rowKey='id'
                        scroll={{ x: 1400 }}
                        pagination={{
                            style: { margin: '16px' }
                        }}
                    />
                </Spinners.CustomSpinner>

            </div>

            <Drawers.CustomDrawer
                title={clientsStore.verificationPanelTitle}
                visible={clientsStore.showVerificationPanel}
                setVisible={(_) => clientsStore.verificationResetForm()}
                children={() => (
                    <Forms.NewVerification
                        onFormFinish={(v) => clientsStore.submitVerification(v)}
                        isLoading={clientsStore.isLoadingVerification}
                    />
                )}
            />
        </main>
    );
});
