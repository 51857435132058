import { action, computed, makeObservable, observable } from "mobx";
import { ILinkedAccount } from "../utils/interfaces";
import { getLinkedAccounts } from "../services/api-service/linked-account";
import { message } from "antd";

export class LinkedAccountsStore {
    linkedAccounts: ILinkedAccount[] = [];
    
    isLoading = false;
    showPanel = false;
    searchQuery = '';
    searchFilter: "Approved" | "Declined" | null = null;

    constructor() {
        makeObservable(this, {
            linkedAccounts: observable,
            isLoading: observable,
            showPanel: observable,
            searchQuery: observable,
            loadLinkedAccounts: action,
            togglePanel: action,
            showMessage: action,
            setSearchQuery: action,           
            searchLinkedAccounts: computed,
        });
    }

    async loadLinkedAccounts() {
        this.isLoading = true;
        try {
            const response = await getLinkedAccounts();
            if (response && response.data !== null) {
                this.linkedAccounts = response.data;
            } else {
                throw new Error("Invalid response received");
            }
        } catch (error) {
            console.error("Error loading linked accounts:", error);
            this.showMessage("Failed to load linked accounts");
        } finally {
            this.isLoading = false;
        }
    }

    togglePanel(v: boolean) {
        this.showPanel = v;
    }

    showMessage(messageText: string) {
        message.error(messageText);
    }

    setSearchQuery(query: string) {
        this.searchQuery = query.trim();
    }

    setSearchFilter(filter: "Approved" | "Declined" | null) {
        this.searchFilter = filter;
    }

    get searchLinkedAccounts(): ILinkedAccount[] {
        let filteredAccounts = this.linkedAccounts;

        // Apply search query filter
        const query = this.searchQuery.toLowerCase().trim();
        if (query !== '') {
            filteredAccounts = filteredAccounts.filter(account =>
                (account.id.toLowerCase().includes(query)) ||
                (account.mainClientId?.toLowerCase().includes(query)) ||
                (account.userAuth?.firstName?.toLowerCase().includes(query)) ||
                (account.userAuth?.lastName?.toLowerCase().includes(query)) ||
                (account.userAuth?.emailAddress?.toLowerCase().includes(query)) ||
                (account.mainClient?.accountNumber?.toLowerCase().includes(query)
            )
            );
        }

        return filteredAccounts;
    }
}
