import { APP_URL } from "../../config/axios-config";
import { IEmailToken } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const addEmailToken = async (body: IEmailToken, items: IEmailToken[]): Promise<ResponseFormat<IEmailToken>> => {
    const response = await APP_URL.post('emailToken', body);

    items.push(response.data as IEmailToken);

    return { data: items };
}

export const updateEmailToken = async (body: IEmailToken, items: IEmailToken[]): Promise<ResponseFormat<IEmailToken>> => {
    items = [...items]

    await APP_URL.put(`emailToken/${body.id}`, body);

    const elementPos = items.map(x => x.id).indexOf(body.id);
    items[elementPos!] = body;

    return { data: items };
}

export const deleteEmailToken = async (body: IEmailToken, items: IEmailToken[]): Promise<ResponseFormat<IEmailToken>> => {
    await APP_URL.delete(`emailToken/${body.id}`);

    return { data: items };
}

export const getEmailTokenById = async (id: string): Promise<IEmailToken> => {
    const response = await APP_URL.get(`emailToken/${id}`);

    let item: IEmailToken = response.data;
    return item;
}

export const resetPassword = async (id: string, body: any): Promise<IEmailToken> => {
    const response = await APP_URL.post(`emailToken/${id}/PasswordReset`, body);

    let item: IEmailToken = response.data;
    return item;
}