import { Avatar, Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, Forms, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import "./style.css";
import { PlatformRights } from "../../../utils/enums";

export const StaffManagement = observer(() => {
    const { staffStore } = Store;

    const staff = staffStore.getStaff.data;

    const getAvatar = (name: string) => {
        return `https://ui-avatars.com/api/?name=${name}background=000&color=D8232A`
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            fixed: 'left' as 'left',
            width: 30,
            render: (text: any, record: any, index: any) => index + 1
        },
        {
            dataIndex: 'profilePicture',
            width: 75,
            render: (text: any, record: any) => {
                const name = `${record?.firstName ?? ''} ${record?.lastName ?? ''}`;
                return <Avatar size={50} src={getAvatar(name)} />
            }
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            width: 200,
            render: (text: any, record: any) => (
                <div>{`${record?.firstName ?? ''} ${record?.lastName ?? ''}`}</div>
            )
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
            width: 200,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            width: 200,
        },
        {
            title: 'Platform Rights',
            dataIndex: 'userTypeList',
            width: 150,
            render: (text: PlatformRights[], record: any) =>
                Object.values(PlatformRights)
                    .filter(x => text.some(t => t === x))
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 60,
            render: (text: any, record: any) => (
                <Buttons.Text
                    text='Edit'
                    onClick={() => {
                        staffStore.editStaff(record);
                    }}
                />
            )
        },
    ];

    React.useEffect(() => {
        staffStore.loadStaff()
    }, [staffStore]);

    return (
        <main id="home" className='home-content-page'>
            <div className='pageheader-container'>
                <h1 className='header'>Staff Management</h1>
                <div className='pageheader-options'>
                    <Input
                        size='large'
                        className='pageheader-options-search'
                        placeholder="Search list..."
                        defaultValue={staffStore.query}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        onChange={(_) => {
                            staffStore.setQuery(_.target.value)
                        }}
                    />
                    <Buttons.Small
                        text={'Add new'}
                        onClick={() => {
                            staffStore.addNew()
                        }}
                    />
                </div>
            </div>

            <div className='custom-table-container'>
                <Spinners.CustomSpinner
                    title={'Getting Staff'}
                    isSpinning={staffStore.isLoading}
                >
                    <Table
                        dataSource={[...staff]}
                        columns={columns}
                        className='custom-table'
                        rowKey='id'
                        scroll={{ x: 1400 }}
                        pagination={{
                            style: { margin: '16px' }
                        }}
                    />
                </Spinners.CustomSpinner>

            </div>

            <Drawers.CustomDrawer
                title={staffStore.panelTitle}
                visible={staffStore.showPanel}
                setVisible={(_) => staffStore.resetForm()}
                children={() => (
                    <Forms.NewStaff
                        userAuth={staffStore.currentStaff}
                        isLoading={staffStore.isLoading}
                        onStaffDelete={() => staffStore.removeStaff()}
                        onFormFinish={(staff) => staffStore.onFormSubmit(staff)}
                    />
                )}
            />
        </main>
    );
});
