import { APP_URL } from "../../config/axios-config";
import { IImageMap } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getImages = async (queryParameter?: string): Promise<{data: IImageMap[]}> => {
    const response = await APP_URL.get('ImageMap?'+ queryParameter || '');
    let products: IImageMap[] = response.data;
    return { data: products };
}

export const addImageMap = async (body: FormData, items: IImageMap[]): Promise<ResponseFormat<IImageMap>> => {
     const response = await APP_URL.post('ImageMap', body, {
         headers: {
             "Content-Type": "multipart/form-data"
         }
     });
     items.push(response.data as IImageMap);
     return { data: items };
}

export const updateImageMap = async (body: FormData, items: IImageMap[]): Promise<ResponseFormat<IImageMap>> => {
    const response = await APP_URL.put(`ImageMap/${body.get('id')}`, body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const data = response.data as IImageMap;
    const elementPos = items.map(x => x.id).indexOf(data.id);
    items[elementPos!] = data;
    return { data: items };
}

export const deleteImageMap = async (body: IImageMap, items: IImageMap[], queryParameter?: string): Promise<ResponseFormat<IImageMap>> => {
    await APP_URL.delete(`ImageMap/${body.id}?` + queryParameter || '');
    items = items.filter(x => x.id !== body.id)
    return { data: items };
}

export const getImageMapById = async (id: string,queryParameter: string): Promise<IImageMap> => {
    const response = await APP_URL.get(`ImageMap/${id}?` + queryParameter || '');
    let item: IImageMap = response.data;
    return item;
}

export const updateImage = async (body: IImageMap, items: IImageMap[]): Promise<ResponseFormat<IImageMap>> => {
    await APP_URL.put(`ImageMap/${body.id}/GeoJSON`, body);
    const elementPos = items.map(x => x.id).indexOf(body.id);
    items[elementPos!] = body;
    return { data: items };
}
