import { Form, FormInstance, Input, message } from "antd";
import React from "react";
import { Buttons } from "..";
import { IAddress } from "../../utils/interfaces";

type NewAddressFormProp = {
    address?: any,
    onFormFinish: (address: IAddress) => void
    isLoading: boolean
};

const NewAddressForm = ({ address, isLoading, onFormFinish }: NewAddressFormProp) => {
    const formRef = React.createRef<FormInstance>();

    const validateMessages = {
        required: "Field is required!",
    };

    const formData = [
        { name: 'address1', label: "Address 1", component: Input, required: true },
        { name: 'address2', label: "Address 2", component: Input, required: true },
        { name: 'address3', label: "Address 3", component: Input, required: false },
        { name: 'city', label: "City", component: Input, required: true },
        { name: 'province', label: "Province", component: Input, required: false },
        { name: 'country', label: "Country", component: Input, required: true },
        { name: 'postalCode', label: "Postal code", component: Input, required: false },
    ];

    const onFinish = (values: any) => {
        onFormFinish(values);
    };

    const onFinishFailed = (errorInfo: any) => {
        if (!address) {
            message.error('Could not add new address.')
        } else {
            message.error('Could not update address.')
        }
    };

    return (
        <>
            <Form
                ref={formRef}
                name='new-address'
                className='custom-form'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={address}
            >
                {formData.map(({ component: Component, ...item }, index) =>
                    <Form.Item
                        key={index}
                        rules={[{ required: item.required }]}
                        {...item}
                    >
                        <Component />
                    </Form.Item>
                )}

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={address ? 'Update address' : 'Add address'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
};

export default NewAddressForm;