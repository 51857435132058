import { Drawer, Input, Popconfirm, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Forms } from "..";
import { Store } from "../../stores/stores";
import { ICustomPage } from "../../utils/interfaces";
import { CustomSpinner } from "../Spinners";
import CustomDrawer from "./CustomDrawer";

const CustomPageDrawer = observer(() => {
    const { customPageStore } = Store;

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            fixed: 'left' as 'left',
            width: 70,
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 140,
        },
        {
            title: "Created",
            dataIndex: "createdAt",
        },
        {
            title: "Updated At",
            dataIndex: "updatedAt",
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 90,
            render: (text: any, record: ICustomPage) => (
                <>
                    <Buttons.Text
                        text='Edit'
                        onClick={() => customPageStore.editCustomPage(record)}
                    />

                    <Popconfirm
                        title='Are you sure to delete this custom page?'
                        onConfirm={() => customPageStore.removeCustomPage(record)}
                        okText='Yes'
                        cancelText='No'
                        placement='topRight'
                    >
                        <Buttons.Text text="Delete"/>
                    </Popconfirm>
                </>
            )
        },
    ];

    useEffect(() => {
        if (customPageStore.showPanel) {
            customPageStore.loadCustomPages();
        }
    }, [customPageStore.showPanel]);
    

    return (
        <Drawer
            className='custom-drawer'
            title="Custom Pages"
            visible={customPageStore.showPanel}
            onClose={() => customPageStore.tooglePanel(false)}
            width="80%"
            children={(
                <>
                    <div className='form-options-container'>
                        <Buttons.Text
                            text="Add new"
                            loading={false}
                            disabled={false}
                            onClick={() => customPageStore.editCustomPage(undefined)}
                        />
                    </div>

                    <Input
                        className='pageheader-options-search orders-search'
                        placeholder="Search list..."
                        defaultValue={customPageStore.searchQuery}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        disabled={customPageStore.isLoading}
                        onChange={(e) => customPageStore.setSearchQuery(e.target.value)}
                        style={{ marginTop: '1em', marginBottom: '2em', width: '50%' }}
                    />

                    <div className='custom-table-container'>
                        <Table
                            rowKey='id'
                            className='custom-table contractpricing-table'
                            columns={columns}
                            scroll={{ x: 2500 }}
                            dataSource={customPageStore.filteredQueryResults}
                            pagination={false}
                        />
                    </div>

                    <CustomDrawer
                        title={!customPageStore.currentCustomPage ? "Add new item" : "Edit item"}
                        visible={customPageStore.showFormsPanel}
                        width="70%"
                        setVisible={(visible) => customPageStore.toogleFormsPanel(visible)}
                        children={observer(() => (
                            <Forms.NewCustomPageForm
                                customPage={customPageStore.currentCustomPage}
                                onFormFinish={(formData) => customPageStore.submitCustomPage(formData)}
                                isLoading={customPageStore.isLoading}
                            />
                        ))}
                    />
                </>
            )}
        />
    );
});

export default CustomPageDrawer;
