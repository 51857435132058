import React, { useCallback, useRef, useState } from "react";
import { Spinners } from "../../../components";
import { useQuery } from "../../../utils/ReactRouter/useQuery";
import "./style.css"

export const PdfViwer = () => {
    const query = useQuery();

    const [pdfUrl,] = useState<string>(query?.get("url") ?? "");
    const [isLoading, setIsLoading] = useState(true);

    const iframeRef = useRef<any>();
    const intervalRef = useRef<any>();

    const getDocsPdfUrl = useCallback(() => {
        return `https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`
    }, [pdfUrl]);

    const onIFrameLoad = () => {
        clearInterval(intervalRef.current);
        setIsLoading(false);
    };

    const updateIFrame = useCallback(() => {
        iframeRef.current.src = getDocsPdfUrl();
    }, [getDocsPdfUrl]);

    React.useEffect(() => {
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => updateIFrame(), 4000);
        }
    }, [query, updateIFrame]);

    React.useEffect(() => {
        return () => onIFrameLoad()
    }, []);

    return (
        <>
            <Spinners.CustomSpinner
                isSpinning={isLoading}
                title="Loading document..."
            >
                {pdfUrl && <iframe
                    id="pdfViwer"
                    ref={iframeRef}
                    sandbox="allow-scripts allow-same-origin"
                    className="pdf-viewer-iframe"
                    title={"PDF Viwer"}
                    onLoad={onIFrameLoad}
                    onError={updateIFrame}
                    src={getDocsPdfUrl()}
                />}
            </Spinners.CustomSpinner>
        </>
    );
};
