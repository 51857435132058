import { Menu } from "antd";
import { action, computed, makeObservable, observable } from "mobx"
import { addContentSlider, deleteContentSlider, getContentSliders, updateContentSlider } from "../services/api-service/content-slider"
import { IContentSlider, IMessage } from "../utils/interfaces"
import { LoadManager } from "../utils/LoadManager/LoadManager"
import { message } from "antd"

export class ContentSliderStore {
    contentSliders = new LoadManager<IContentSlider>({ data: [] }, getContentSliders, addContentSlider, deleteContentSlider, updateContentSlider);

    showPanel = false;
    showFormsPanel = false;
    currentContentSlider?: IContentSlider | null;

    constructor() {
        makeObservable(this, {
            contentSliders: observable,
            isLoading: computed,
            showPanel: observable,
            showFormsPanel: observable,
            tooglePanel: action,
            toogleFormsPanel: action,
            editContentSlider: action,
            resetContentSliderForm: action,
            submitContentSlider: action,
        });
    }

    get isLoading(): boolean {
        return this.contentSliders.isLoading;
    }

    loadContentSliders() {
        this.contentSliders.fetch();
    }

    tooglePanel(v: boolean) {
        this.showPanel = v;
    }

    toogleFormsPanel(v: boolean) {
        this.showFormsPanel = v;
    }

    editContentSlider(contentSlider: IContentSlider | null) {
        this.showFormsPanel = true;
        if (contentSlider === null) {
            this.currentContentSlider = undefined;
            return;
        }       
        this.currentContentSlider = contentSlider;
    }

    resetContentSliderForm() {
        this.showFormsPanel = false;
        this.currentContentSlider = undefined;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.contentSliders.error || this.contentSliders.error) {
            message.error(errorMsg)
        } else {
            this.resetContentSliderForm();
            message.success(successMsg)
        }
    }

    async submitContentSlider(contentSlider: FormData) {
        try {
    
            if (this.currentContentSlider !== undefined) {
                await this.contentSliders.update(contentSlider);
                this.showMessage({
                    successMsg: 'Content slider updated.',
                    errorMsg: 'Could not update content slider.',
                });
                this.showFormsPanel = false;
            } else {
                await this.contentSliders.add(contentSlider);
                this.showMessage({
                    successMsg: 'Content slider added.',
                    errorMsg: 'Could not add content slider.',
                });
                this.showFormsPanel = false;
                this.showPanel = false;
            }
    
            this.loadContentSliders();
        } catch (e) {
            console.log('Error updating/adding content slider', e);
        }
    }
    

    async removeContentSlider(contentSlider: IContentSlider) {
        try {
            await this.contentSliders.remove(contentSlider)
            this.showMessage({
                successMsg: 'Content slider removed.',
                errorMsg: 'Could not remove content banner.',
            });
            this.loadContentSliders();
            this.showFormsPanel = false;
        } catch (e) {
            console.log('Error removing content slider', e);
        }
    }
}

