import { APP_URL } from "../../config/axios-config";
import { IAddress } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getAddresses = async (): Promise<ResponseFormat<IAddress>> => {
    const response = await APP_URL.get('address');

    let addresses: IAddress[] = [];

    if (response?.data) {
        addresses = response.data;
    }
    return {
        data: addresses
    };
}

export const addAddress = async (body: IAddress, addresses: IAddress[]): Promise<ResponseFormat<IAddress>> => {
    const response = await APP_URL.post('address', body);

    addresses.push(response.data as IAddress);

    return { data: addresses };
}

export const updateAddress = async (body: IAddress, addresses: IAddress[]): Promise<ResponseFormat<IAddress>> => {
    addresses = [...addresses]

    await APP_URL.put(`address/${body.id}`, body);

    const elementPos = addresses.map(x => x.id).indexOf(body.id);
    addresses[elementPos!] = body;

    return { data: addresses };
}

export const deleteAddress = async (body: IAddress, addresses: IAddress[]): Promise<ResponseFormat<IAddress>> => {
    await APP_URL.delete(`address/${body.id}`);

    return { data: addresses };
}