import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd"

type CustomSpinnerProp = {
    title: string;
    isSpinning: boolean;
    children: React.ReactNode;
}

const CustomSpinner = ({ title, isSpinning, children: Component }: CustomSpinnerProp) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <Spin
            className='custom-spin'
            tip={title}
            indicator={antIcon}
            spinning={isSpinning}
            children={Component}
        />
    );
}

export default CustomSpinner;