import { APP_URL } from "../../config/axios-config";
import { IBranch } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getBranches = async (): Promise<ResponseFormat<IBranch>> => {
    const response = await APP_URL.get('branch');

    let branches: IBranch[] = [];

    if (response?.data) {
        branches = response.data;
    }
    return {
        data: branches
    };
}

export const addBranch = async (body: FormData, branches: IBranch[]): Promise<ResponseFormat<IBranch>> => {
    const response = await APP_URL.post('branch', body, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    branches.push(response.data as IBranch);
    return { data: branches };
}


export const updateBranch = async (body: FormData, branches: IBranch[]): Promise<ResponseFormat<IBranch>> => {
    const response = await APP_URL.put(`branch/${body.get('id')}`, body, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    const data = response.data as IBranch;
    const elementPos = branches.map(x => x.id).indexOf(data.id);
    branches[elementPos!] = data;
    return { data: branches };
}

export const updateBulkEmailSignatures = async (formData: FormData): Promise<void> => {
        const response = await APP_URL.put('branch/bulk-email-signatures', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        return;
}


export const deleteBranch = async (body: IBranch, branches: IBranch[]): Promise<ResponseFormat<IBranch>> => {
    await APP_URL.delete(`branch/${body.id}`);

    return { data: branches };
}