export const formDataValues = (formData: FormData, formValues: any): FormData => {
    Object.keys(formValues).forEach(key => {
        if (Array.isArray(formValues[key])) {
            formValues[key].forEach((x?: any) => x && formData.append(key, x));
        } else if (formValues[key]) {
            formData.append(key, formValues[key]);
        }
    });

    return formData;
}
