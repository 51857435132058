import { APP_URL } from "../../config/axios-config";
import { IImageMap, ISpecial } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getSpecials = async (queryParameters?: string): Promise<ResponseFormat<ISpecial>> => {
    const response = await APP_URL.get('special?' + queryParameters || '');
    let branches: ISpecial[] = response.data;
    return { data: branches };
}

export const addSpecial = async (body: FormData, items: ISpecial[]): Promise<ResponseFormat<ISpecial>> => {
     const response = await APP_URL.post('special', body, {
         headers: {
             "Content-Type": "multipart/form-data"
         }
     });
     items.push(response.data as ISpecial);
     return { data: items };
}

export const updateSpecial = async (body: FormData, items: ISpecial[]): Promise<ResponseFormat<ISpecial>> => {
    const response = await APP_URL.put(`special/${body.get('id')}`, body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const data = response.data as ISpecial;
    const elementPos = items.map(x => x.id).indexOf(data.id);
    items[elementPos!] = data;
    return { data: items };
}

export const deleteSpecial = async (body: ISpecial, items: ISpecial[]): Promise<ResponseFormat<ISpecial>> => {
    await APP_URL.delete(`special/${body.id}`);
    items = items.filter(x => x.id !== body.id)
    return { data: items };
}

export const getSpecialById = async (id: string): Promise<ISpecial> => {
    const response = await APP_URL.get(`special/${id}`);
    let item: ISpecial = response.data;
    return item;
}

export const updateSpecialImageMap = async (body: IImageMap, items: IImageMap[]): Promise<ResponseFormat<IImageMap>> => {
    await APP_URL.put(`special/${body.specialId}/imageMap/${body.id}`, body);
    const elementPos = items.map(x => x.id).indexOf(body.id);
    items[elementPos!] = body;
    return { data: items };
}
