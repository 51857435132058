import { observable, action, computed, makeObservable } from "mobx";
import { LoadManager } from "../utils/LoadManager/LoadManager";
import { addSurvey, deleteSurvey, getSurvey, updateSurvey } from "../services/api-service/survey";
import { IMessage, ISurvey, ISurveyQuestion } from "../utils/interfaces";
import { message } from "antd";
import { ResponseFormat } from "../utils/types";
import { copyObjects } from "../services";

export class SurveyStore {
    hrSurveys = new LoadManager<ISurvey>({ data: [] }, getSurvey, addSurvey, deleteSurvey, updateSurvey);
    currentSurveyQuestion: ISurveyQuestion | null = null;

    query = "";
    showPanel = false;
    showResponses = false;
    showBuildPanel = false;
    currentSurvey: ISurvey | null = null;
    surveyLoaded: boolean = false;

    constructor() {
        makeObservable(this, {
            currentSurveyQuestion:observable,
            hrSurveys: observable,
            query: observable,
            setQuery: action,
            selectSurvey: action,
            filteredHRSurveys: computed,
            showPanel: observable,
            showResponses: observable,
            showBuildPanel: observable,
            currentSurvey: observable,
            panelTitle: computed,
            addNew: action,
            editSurvey: action,
            resetForm: action,
            isLoading: computed,
            loadSurvey: action,
            onFormSubmit: action,
            removeSurvey: action,
            surveyLoaded: observable,
        })
    }

    setQuery(s: string) {
        this.query = s.trim();
    }

    get filteredHRSurveys(): ResponseFormat<ISurvey> {
        if (this.query.length === 0) {
            return this.hrSurveys.value;
        } else {
            const data = this.hrSurveys.value.data.filter(srv => {
                return (
                    String(srv?.name).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(srv?.openingMessage).toUpperCase().indexOf(this.query.toUpperCase()) >= 0
                    || String(srv?.closingMessage).toUpperCase().indexOf(this.query.toUpperCase()) >= 0)});

            return { data: data }
        }
    }

    get panelTitle(): string {
        return this.isNew ? 'Add new survey' : 'Edit survey';
    }

    get isNew(): boolean {
        return !this.currentSurvey;
    }

    addNew() {
        this.showPanel = true;
        this.currentSurvey = null;
    }

    editSurvey(survey: ISurvey, panel: 'edit' |  'build' | 'responses' = 'edit') {
        if (panel === 'edit') {
            this.showPanel = true;
        } if (panel === 'responses') {
            this.showResponses = true;
        } else {
            this.showBuildPanel = true;
        }
        this.currentSurvey = survey;
    }

    resetForm() {
        this.showPanel = false;
        this.showBuildPanel= false;
        this.showResponses = false;
        this.currentSurvey = null;
    }

    get isLoading(): boolean {
        return this.hrSurveys.isLoading;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.hrSurveys.error) {
            message.error(errorMsg)
        } else {
            message.success(successMsg)
            this.resetForm();
        }
    }

    // Load survey from api
    async loadSurvey() {
        await this.hrSurveys.fetch()
        this.surveyLoaded = true; // and this line
    }

    selectSurvey(index: number) {
        const surveys = this.hrSurveys.value.data;
        if (surveys && surveys.length > index) {
            this.currentSurvey = surveys[index];
        }
    }

    // add survey from api
    async onFormSubmit(survey: ISurvey) {
        let updatedBody: ISurvey | undefined;

        try {
            if (this.currentSurvey) {
                updatedBody = copyObjects(this.currentSurvey, survey);
                await this.hrSurveys.update(updatedBody);
                this.showMessage({
                    successMsg: 'Survey member updated.',
                    errorMsg: 'Could not update survey member.',
                });
                this.loadSurvey();
            } else {
                await this.hrSurveys.add(survey)
                updatedBody = this.hrSurveys.value.data.find(x => x.id === survey.id);
                this.showMessage({
                    successMsg: 'New survey member added.',
                    errorMsg: 'Could not add new survey member.',
                });
            }

        } catch (e) {
            console.log('Error updating/adding survey', e);
        }
    }

    async removeSurvey() {
        try {
            if (this.currentSurvey) {
                await this.hrSurveys.remove(this.currentSurvey)
            }

            this.showMessage({
                successMsg: 'Survey deactivated.',
                errorMsg: 'Could not deactivate survey.',
            });

            this.loadSurvey();
            this.resetForm();
        } catch (e) {
            console.log('Error deleting survey', e);
        }
    }

}
