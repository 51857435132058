import { message, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { Buttons, Spinners } from "..";
import { downloadObject } from "../../services/downloadObject";
import { Store } from "../../stores/stores";
import { IParcelLabel } from "../../utils/interfaces";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { APIService } from "../../services";
import { zplParcelLabel } from "../../utils/zpl/zpl-label";

const ParcelLabelsTable = observer(() => {
    const { ordersStore } = Store;
    const labels = ordersStore.currentLabels;
    const currentOrder = ordersStore.currentOrder;

    const columns = [
        {
            title: "Parcel #",
            dataIndex: "parcelNo",
            // width: "200px",
            render: (text: string, record: IParcelLabel) =>
                <div>
                    {record.barcode?.split("_")[1]}
                </div>
        },
        {
            title: "Save",
            dataIndex: "print",
            width: "70px",
            fixed: 'right' as 'right',
            render: (text: string, record: IParcelLabel) => (
                <>
                    <div style={{ fontSize: 8 }}>
                        <Buttons.Text
                            icon={<CloudDownloadOutlined />}
                            onClick={async () => {
                                saveZPLBarcode(record);
                            }}
                        />
                    </div>
                </>
            )
        },
    ];

    const saveZPLBarcode = async (parcel: IParcelLabel) => {
        const hideLoading = message.loading("Saving label.");
        try {
            const totalParcels = labels.length
            const orderDate = currentOrder?.dateOrdered;
            const zpl = await zplParcelLabel({ parcel, totalParcels, orderDate })
            const imgBlob = await APIService.Order.getLabelaryImage(zpl);
            var reader = new FileReader();
            reader.readAsDataURL(imgBlob);
            reader.onloadend = (e) => {
                downloadObject(reader.result as string, parcel.barcode);
            }
        } catch (error) {
            message.error("Could not save label.")
        }
        hideLoading();
    }

    return (
        <Spinners.CustomSpinner
            title="Getting Labels"
            isSpinning={ordersStore.isLoading}
        >
            <h2 className="header">Invoice: #{ordersStore.currentOrder?.refNo}</h2>
            <div className="header">Print Dimensions: (H)100mm x (W)100mm</div>

            <Table
                rowKey='id'
                className='custom-table  contractpricing-table'
                columns={columns}
                dataSource={[...labels]}
                pagination={false}
                scroll={{ x: 800 }}
            />
        </Spinners.CustomSpinner >
    );
});

export default ParcelLabelsTable;