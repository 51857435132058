import React, { isValidElement, useCallback, useRef } from "react";
import { ImageMapEditor }  from "@bridgelabsdesign/gfox-image-tool";
import { Buttons, Drawers } from "..";
import Modal from "antd/lib/modal/Modal";
import { observer } from "mobx-react-lite";
import { Store } from "../../stores/stores";
import { Search } from "..";
import { DebounceSelectProp } from "../Search/DebounceSearch";
import { Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FALLBACK_IMAGE } from "../../config/storage";

type FormType = {
	sku: { label: string, value: string};
};

const ProductsImageMapDrawer = observer(({
	isVisible,
	setIsVisible,
	onSaveImage,
}:{
	isVisible: boolean
	setIsVisible(v: boolean): void;
	onSaveImage(geojson: {}): void;
}) => {
	const { ImageMapStore, inventoryStore } = Store;
	const mapId = "image-map";
	const [form] = useForm<FormType>();


	const handleSaveImage = () => {
		const geojson = ImageMapStore.mapRef?.generateGeoJson();
		if (geojson) {
			onSaveImage(geojson);
		}
	}

	const handleOnLayerCreate = () => {
		setIsVisible(true);
	};

	const handleOnFormSubmit = (values: FormType) => {
		if (ImageMapStore.mapLayerCreateCallback) {
			ImageMapStore.mapLayerCreateCallback(values.sku.value);
		}
		ImageMapStore.setIsSkuSelectionVisible(false)
	};

	const handleOnCancel = () => {
		ImageMapStore.setIsSkuSelectionVisible(false)
		if (ImageMapStore.mapLayerCreateCallback) {
			ImageMapStore.mapLayerCreateCallback(undefined);
		}
	};

	const handleLoadMapRef = useCallback(async () => {
			ImageMapStore.loadMap(mapId);
			if (ImageMapStore.mapRef && ImageMapStore.mapImageUrl) {
				await ImageMapStore.mapRef.loadImage(ImageMapStore.mapImageUrl.url ?? FALLBACK_IMAGE);
				const initialGeoJson = !!ImageMapStore.mapImageUrl
					? ImageMapStore.geoJsonRefs[ImageMapStore.mapImageUrl.index]
					: null;
				if (initialGeoJson) {
		 			try {
						ImageMapStore.mapRef.importGeoJSON(initialGeoJson);
		 			} catch (err) {
		 				console.warn(err);
		 			}
				}
			}
	}, []);

	React.useEffect(() => {
		if (isVisible) {
			handleLoadMapRef();
		}
		return () => {
		};
	}, [isVisible, handleLoadMapRef])

	return (
    <Drawers.CustomDrawer
				width="98%"
				title={'Image Map'}
				visible={isVisible}
				setVisible={(v) => {
					ImageMapStore.mapRef?.remove();
					setIsVisible(v)
			}}
        children={observer(() => (
					<div className="image-map-drawer">
						<div className='form-options-container'>
							<Buttons.Text
								text="Save Image"
								onClick={() => handleSaveImage()}
							/>
						</div>

						<div id={mapId}></div>
						<Modal
							title="Select Inventory Item"
							visible={ImageMapStore.isSkuSelectionVisible}
							onCancel={() => handleOnCancel()}
							onOk={() => form.submit()}
						>
							<Form<FormType>
								form={form}
								name='new-voucherConstraint'
								className='custom-form'
								onFinish={handleOnFormSubmit}
								onFinishFailed={() => message.error('Could set reference')}
								layout='vertical'
								requiredMark={false}
								validateMessages={{
									required: "Field is required!",
								}}
								initialValues={{}}
							>
								<Form.Item
										name="sku"
										rules={[{ required: true }]}
								>
									<Search.DebounceSearch<DebounceSelectProp>
											fetchOptions={(query) => inventoryStore.searchInventoryMaster(query)}
											labelInValue={true}
									/>
								</Form.Item>
							</Form>
						</Modal>
					</div>
				))}
			/>
	);
});

export default ProductsImageMapDrawer;
