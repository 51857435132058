import { message } from "antd"
import { action, computed, makeObservable, observable } from "mobx"
import { addBanner, deleteBanner, getBanners, updateBanner } from "../services/api-service/banner"
import { IBanner, IMessage } from "../utils/interfaces"
import { LoadManager } from "../utils/LoadManager/LoadManager"

export class BannerStore {
    banners = new LoadManager<IBanner>({ data: [] }, getBanners, addBanner, deleteBanner, updateBanner)

    showPanel = false;
    showFormsPanel = false;
    currentBanner?: IBanner;

    constructor() {
        makeObservable(this, {
            banners: observable,
            isLoading: computed,
            showPanel: observable,
            showFormsPanel: observable,
            tooglePanel: action,
            toogleFormsPanel: action,
            editBanner: action,
            resetBannerForm: action,
            submitBanner: action,
        });
    }

    get isLoading(): boolean {
        return this.banners.isLoading;
    }

    loadBanners() {
        const queryParameters = "linkedSku=true&active=false";
        this.banners.fetch(queryParameters);
    }

    tooglePanel(v: boolean) {
        this.showPanel = v;
    }

    toogleFormsPanel(v: boolean) {
        this.showFormsPanel = v;
    }

    editBanner(banner?: IBanner) {
        this.showFormsPanel = true;
        this.currentBanner = banner;
    }

    resetBannerForm() {
        this.showFormsPanel = false;
        this.currentBanner = undefined;
    }

    showMessage({ successMsg, errorMsg }: IMessage) {
        if (this.banners.error || this.banners.error) {
            message.error(errorMsg)
        } else {
            this.resetBannerForm();
            message.success(successMsg)
        }
    }

    async submitBanner(banner: FormData) {
        try {
            if (this.currentBanner !== undefined) {
                await this.banners.update(banner);
                this.showMessage({
                    successMsg: 'Banner added.',
                    errorMsg: 'Could not add banner.',
                });
            } else {
                await this.banners.add(banner);
                this.showMessage({
                    successMsg: 'Banner updated.',
                    errorMsg: 'Could not update banner.',
                });
            }
            this.loadBanners();
        } catch (e) {
            console.log('Error updating/adding banner', e);
        }
    }

    async removeBanner(banner: IBanner) {
        try {
            await this.banners.remove(banner)
            this.showMessage({
                successMsg: 'Banner removed.',
                errorMsg: 'Could remove add banner.',
            });
        } catch (e) {
            console.log('Error removing banner', e);
        }
    }
}