import { Button, ButtonProps } from "antd";
import "./style.css";

export type CustomButtonProps = Exclude<ButtonProps, 'children'> & {
    text?: React.ReactNode;
}

const Primary = ({ text, ...rest }: CustomButtonProps) => {
    return (
        <Button className='primary-btn' {...rest}>
            {text}
        </Button>
    );
}

export default Primary;
