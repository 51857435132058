import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Drawers, Forms, Spinners } from "../../../components";
import { Store } from "../../../stores/stores";
import { IAddress, IBranch, IBranchCod, IPostalAddress, ISysproWarehouse } from "../../../utils/interfaces";
import "./style.css";


export const BranchManagement = observer(() => {
    const { addressesStore, branchesStore, postalAddressStore } = Store;

    const activeTab = branchesStore.activeTab;
    const branches = branchesStore.getBranches.data;
    const [selectedBranchIds, setSelectedBranchIds] = useState<string[]>([]);
    const [EmailSignatureVisible, setEmailSignatureVisible] = useState(false);

    const columns = [
        {
            title: 'Ref #',
            dataIndex: 'refNo',
            fixed: 'left' as 'left',
            width: 70,
        },
        {
            title: 'Branch Name',
            dataIndex: 'branchName',
        },
        {
            title: 'Area of Service',
            dataIndex: 'areaOfService',
        },
        {
            title: 'Quote Threshold',
            dataIndex: 'quoteThreshold',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            render: (text: IAddress | null, record: any) => (
                text &&
                <div className='custom-table-address-field'>
                    <div>{text.address1}</div>
                    <div>{text.address2}</div>
                    <div>{text.address3}</div>
                    <div>{text.city}</div>
                    <div>{text.province}</div>
                    <div>{text.country}</div>
                    <div>{text.postalCode}</div>
                </div>
            )
        },
        {
            title: 'Postal Address',
            dataIndex: 'postalAddress',
            render: (text: IPostalAddress | null, record: any) => (
                text &&
                <div className='custom-table-address-field'>
                    <div>{text.postBox}</div>
                    <div>{text.suburb}</div>
                    <div>{text.postalCode}</div>
                    <div>{text.province}</div>
                    <div>{text.country}</div>
                </div>
            )
        },
        {
            title: 'Manager',
            dataIndex: 'manager',
        },
        {
            title: 'Tel. number',
            dataIndex: 'telNumber',
        },
        {
            title: 'Fax number',
            dataIndex: 'faxNumber',
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Sales email',
            dataIndex: 'salesEmailAddress',
        },
        {
            title: 'Embroidery email',
            dataIndex: 'embroideryEmail',
        },
        {
            title: 'Email Signature',
            dataIndex: 'emailSignature',
            render: (url: string | null, record: any) => (
                url ? <img src={url} alt="Email Signature" style={{ width: '100px', height: 'auto' }} /> : 'No Image'
            )
        },
        {
            title: 'COD',
            dataIndex: 'codAccount',
            render: (text: IBranchCod | null, record: any) => (
                text &&
                <div className='custom-table-address-field'>
                    <div>{text.codAccountNumber}</div>
                    <div>{text.name}</div>
                    <div>{text.bankBranch}</div>
                    <div>{text.bankBranchCode}</div>
                    <div>{text.bankAccountNumber}</div>
                    <div>{text.bankSwiftNumber}</div>
                </div>
            )
        },
        {
            title: 'SysPro Company',
            dataIndex: 'sysproCompany',
        },
        {
            title: 'SysPro Warehouse',
            dataIndex: 'sysproWarehouse',
            render: (text: ISysproWarehouse | null, record: any) => text && text.name
        },
        {
            title: 'Location',
            dataIndex: 'location',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            render: (isNew: boolean, record: any) => (
                isNew ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 100,
            render: (text: any, record: IBranch) => (
                <Buttons.Text
                    text='Edit'
                    onClick={() => {
                        branchesStore.editBranch(record);
                        branchesStore.editBranchCod(record?.codAccount);
                        addressesStore.editAddress(record?.address);
                        postalAddressStore.editAddress(record?.postalAddress);
                    }}
                />
            )
        },
    ];
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IBranch[]) => {
            setSelectedBranchIds(selectedRowKeys as string[]);
        },
    };

    const BranchDetails = () => (
        <Forms.NewBranch
            branch={branchesStore.currentBranch}
            isLoading={branchesStore.branches.isLoading}
            onBranchDelete={() => branchesStore.removeBranch()}
            onFormFinish={(branch) => branchesStore.onFormSubmit(branch)}
            onWarehouseSearch={(query) => branchesStore.searchWarehouses(query)}
        />
    );

    const AddressDetails = () => (
        <Forms.NewAddress
            address={addressesStore.currentAddress}
            isLoading={addressesStore.isLoading}
            onFormFinish={async (address) => {
                const newAddress = await addressesStore.onFormSubmit(address);
                branchesStore.addAddress(newAddress);
            }}
        />
    );

    const PostalAddressDetails = () => (
        <Forms.NewPostalAddress
            postalAddress={postalAddressStore.currentAddress}
            isLoading={postalAddressStore.isLoading}
            onFormFinish={async (address) => {
                const newAddress = await postalAddressStore.onFormSubmit(address);
                branchesStore.addPostalAddress(newAddress);
            }}
        />
    );

    const CODAccount = () => (
        <Forms.NewBranchCod
            codAccount={branchesStore.currentCodAccount}
            isLoading={branchesStore.isLoadingCod}
            onFormFinish={async (cod) => {
                const newCod = await branchesStore.onCodFormSubmit(cod);
                branchesStore.addCodAccount(newCod);
            }}
        />
    );

    const tabsPane = [
        { key: "0", tab: "Details", component: BranchDetails },
        { key: "1", tab: "Address", component: AddressDetails, disabled: branchesStore.isNew },
        { key: "2", tab: "Postal Address", component: PostalAddressDetails, disabled: branchesStore.isNew },
        { key: "3", tab: "COD", component: CODAccount, disabled: branchesStore.isNew },
    ];

    React.useEffect(() => {
        branchesStore.loadBranches();
    }, [branchesStore]);

    return (
        <main id="branch-management" className='home-content-page'>
            <div className='pageheader-container'>
                <h1 className='header'>Branch Management</h1>
                <div className='pageheader-options'>
                    <div>
                        <Buttons.Small
                            text={'Bulk Email Signatures'}
                            className='dropdown-btn'
                            style={{
                                opacity: selectedBranchIds.length === 0 ? 0.5 : 1,
                                pointerEvents: selectedBranchIds.length === 0 ? 'none' : 'auto',
                            }}
                            onClick={() => setEmailSignatureVisible(true)}
                        />
                    </div>
                    <Input
                        size='large'
                        className='pageheader-options-search'
                        placeholder="Search list..."
                        defaultValue={branchesStore.query}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        onChange={(_) => {
                            branchesStore.setQuery(_.target.value)
                        }}
                    />
                    <Buttons.Small
                        text={'Add new'}
                        onClick={() => {
                            branchesStore.addNew()
                            addressesStore.addAddress();
                            postalAddressStore.addAddress();
                        }}
                    />
                </div>
            </div>

            <div className='custom-table-container'>
                <Spinners.CustomSpinner
                    title={'Getting Branches'}
                    isSpinning={branchesStore.isLoading}
                >
                    <Table
                        rowSelection={rowSelection}
                        dataSource={[...branches]}
                        columns={columns}
                        className='custom-table'
                        rowKey='id'
                        scroll={{ x: 2800 }}
                        pagination={{
                            style: { margin: '16px' }
                        }}
                    />
                </Spinners.CustomSpinner>
            </div>

            <Drawers.CustomDrawer
                title={branchesStore.panelTitle}
                visible={branchesStore.showPanel}
                setVisible={(_) => branchesStore.resetForm()}
                children={() => (
                    <Tabs
                        activeKey={activeTab}
                        defaultActiveKey="1"
                        className='custom-tabs'
                        size='large'
                        onChange={(_) => branchesStore.setActiveTab(_)}
                    >
                        {tabsPane.map(({ component: Component, ...item }) =>
                            <Tabs.TabPane {...item} >
                                <Component />
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                )}
            />
            <Drawers.CustomDrawer
                title="Upload Bulk Email Signature"
                visible={EmailSignatureVisible}
                setVisible={setEmailSignatureVisible}
                children={() => (
                    <Forms.BulkEmailSignatureForm
                    selectedBranchIds={selectedBranchIds}
                    onClose={() => setEmailSignatureVisible(false)}
                />
                )}
            />
        </main>
    )
});
