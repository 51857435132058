import { APP_URL } from "../../config/axios-config";

import { IClient, ISysProPricing, IVerificationRequest } from "../../utils/interfaces";
import { Pagination, ResponseFormat } from "../../utils/types";

export const getClients = async (queryParameters?: string): Promise<ResponseFormat<IClient>> => {
   
    const response = await APP_URL.get('client?' + queryParameters || '');

    let clients: IClient[] = [];
    let pagination: Pagination = null;

    if (response?.data) {
        clients = response.data;
    }

    if (response.headers["x-pagination"]) {
        pagination = JSON.parse(response.headers["x-pagination"]);
    }

    return { data: clients, pagination: pagination };
}

// Get the clients CSV data from the getClientsCSV APP_URL
export const getClientsCSV = async (): Promise<string> => {
    const response = await APP_URL.get('Client/client.csv');
    return response.data;
}

export const addClient = async (body: IClient, clients: IClient[]): Promise<ResponseFormat<IClient>> => {
    const response = await APP_URL.post('client', body);

    clients.push(response.data as IClient);
    return { data: clients };
}

export const updateClient = async (body: IClient, clients: IClient[]): Promise<ResponseFormat<IClient>> => {
    clients = [...clients]

    await APP_URL.put(`client/${body.id}`, body);

    const elementPos = clients.map(x => x.id).indexOf(body.id);
    clients[elementPos!] = body;
    return { data: clients };
}

export const getById = async (id: string): Promise<IClient> => {
    const response = await APP_URL.get<IClient>(`client/${id}`);
    return response.data;
}

export const deleteClient = async (body: IClient, clients: IClient[], queryParameter?: string): Promise<ResponseFormat<IClient>> => {
    await APP_URL.delete(`client/${body.id}?` + queryParameter || '');

    return { data: clients };
}

export const getPricing = async (body: IClient): Promise<ResponseFormat<ISysProPricing>> => {
    const response = await APP_URL.get(`client/${body.id}/contractPricing`);

    return { data: response?.data ?? [] };
}

export const populateVerification = async (request: IVerificationRequest): Promise<void> => {
    try {
        const response = await APP_URL.post('/Client/PopulateVerification', request);
        console.log(response.data.message); // Assuming the response contains a message
    } catch (err) {
        throw err;
    }
};

export const getVerifications = async (): Promise<ResponseFormat<IVerificationRequest>> => {
    const response = await APP_URL.get('Client/Verifications');

    let staff: IVerificationRequest[] = [];

    if (response?.data) {
        staff = response.data;
    }
    return { data: staff };
}
