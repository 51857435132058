import {Input,Table, Form, Popconfirm } from "antd";
import React, { useContext, useState, useEffect, useRef } from 'react';
import "./style.css";
import { FormInstance } from 'antd/lib/form';
import { Buttons } from "..";
import { copyObjects } from "../../services";
import { DeleteOutlined } from "@ant-design/icons";


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: 'status';
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                        min: 0.0000001
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0] & {
    values: any,
    onInputChange: (data: any[]) => void,
};

export interface ParcelItem {
    key: React.Key;
    parcelNo: number;
    items: number;
    height: number;
    width: number;
    length: number;
    weight: number;
}
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

class EditableTable extends React.Component<EditableTableProps, any> {
    columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[];

    constructor(props: any) {
        super(props);

        this.columns = [
            {
                title: '#',
                dataIndex: 'parcelNo',
                editable: false,
                render: (_: any, record: any, index: any) => index + 1
            },
            {
                title: 'No. of Parcels',
                dataIndex: 'items',
                editable: true
            },
            {
                title: 'Height',
                dataIndex: 'height',
                editable: true
            },
            {
                title: 'Width',
                dataIndex: 'width',
                editable: true
            },
            {
                title: 'Length',
                dataIndex: 'length',
                editable: true
            },
            {
                title: 'Weight',
                dataIndex: 'weight',
                editable: true
            },
            {
                dataIndex: 'operation',
                render: (_, record: { key: React.Key } | any) =>
                    this.state.dataSource.length >= 1 ? (
                        <Popconfirm
                            title="Are you sure to delete?"
                            placement="left"
                            onConfirm={() => this.handleDelete(record.key)}
                        >
                            <Buttons.Text
                                icon={<DeleteOutlined color="#D8232A" />}
                            />
                        </Popconfirm>
                    ) : <div></div>
            },
        ];
        this.state = {
            dataSource: [],
            count: 1,
            amountProd: ''
        };
    }

    componentDidUpdate(prevProps: EditableTableProps, prevState: EditableTableProps) {
        if (prevState.dataSource !== this.state.dataSource) {
            this.props.onInputChange(this.state.dataSource);
        }
    }

    handleDelete = (key: React.Key) => {
        let dataSource = [...this.state.dataSource];
        dataSource = dataSource.filter(item => item.key !== key);
        this.setState({ dataSource: dataSource.map((x, i) => copyObjects(x, { name: i + 1 })) });
    };

    handleAdd = () => {
        const { amountProd, dataSource } = this.state;

        const newData: ParcelItem[] = [];
        for (let i = 0; i < amountProd; i++) {
            newData.push({
                key: dataSource.length + i + 1,
                parcelNo: dataSource.length + i + 1,
                items: 0,
                height: 0,
                width: 0,
                length: 0,
                weight: 0,
            })
        }
        this.setState({
            dataSource: [...dataSource, ...newData],
        });
    };

    handleSave = (row: ParcelItem) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
    };
    handleAmountChange = (e: any) => {
        this.setState({ amountProd: e.target.value })
    }

    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: ParcelItem) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <div>
                <div className='create-flex'>
                    <input className="parcels-input" placeholder='Number of products' value={this.state.amountProd} onChange={this.handleAmountChange}></input>
                    <Buttons.Text text='Add' onClick={this.handleAdd} />
                </div>
                <div className="measurement-units">
                    <h4>Units of measurement:</h4>
                    <div className="unit-labels">
                        <span>Height: cm</span>
                        <span>Width: cm</span>
                        <span>Length: cm</span>
                        <span>Weight: kg</span>
                    </div>
                </div>
                <Table
                    className='custom-table masterstock-table'
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns as ColumnTypes}
                />
            </div>
        );
    }
}

export default EditableTable