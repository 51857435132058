import { APP_URL } from "../../config/axios-config";
import { IBanner } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getBanners = async (queryParameters?: string): Promise<ResponseFormat<IBanner>> => {
    const response = await APP_URL.get('banner?' + queryParameters || '');
    let branches: IBanner[] = response.data;
    return { data: branches };
}

export const addBanner = async (body: FormData, items: IBanner[]): Promise<ResponseFormat<IBanner>> => {
    const response = await APP_URL.post('banner', body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    items.push(response.data as IBanner);
    return { data: items };
}

export const updateBanner = async (body: FormData, items: IBanner[]): Promise<ResponseFormat<IBanner>> => {
    console.log(body.get('id'))
    const response = await APP_URL.put(`banner/${body.get('id')}`, body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const data = response.data as IBanner;
    const elementPos = items.map(x => x.id).indexOf(data.id);
    items[elementPos!] = data;
    return { data: items };
}

export const deleteBanner = async (body: IBanner, items: IBanner[]): Promise<ResponseFormat<IBanner>> => {
    await APP_URL.delete(`banner/${body.id}`);
    items = items.filter(x => x.id !== body.id)
    return { data: items };
}

export const getBannerById = async (id: string): Promise<IBanner> => {
    const response = await APP_URL.get(`banner/${id}`);
    let item: IBanner = response.data;
    return item;
}
