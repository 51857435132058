import { FormInstance, Form, Select, message } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Buttons, ExpandedTables } from "..";
import { OrderStatus } from "../../services";
import { Store } from "../../stores/stores";
import { DeliveryTypes, StatusCodes } from "../../utils/enums";
import { IOrder } from "../../utils/interfaces";
import { ParcelItem } from "../ExpandedTables/ProductSpecificationTable";

type UpdateOrderStatusFormProp = {
    order?: IOrder | null,
    onFormFinish: (order: IOrder, parcels?: ParcelItem[]) => void,
    isLoading: boolean,
};

const UpdateOrderStatus = observer(({ order, onFormFinish, isLoading }: UpdateOrderStatusFormProp) => {
    const formRef = React.createRef<FormInstance>();
    const [parcels, setParcels] = React.useState<ParcelItem[]>([]);
    const [formValues, setFormValues] = React.useState<Partial<IOrder>>({});

    const { ordersStore } = Store;

    const validateMessages = {
        required: "Field is required!",
    };

    const shouldCreateParcel = useMemo<boolean>(() => {
        return formValues?.statusCode === StatusCodes.PACKED && order?.deliveryOrders[0].delivery.refNo === DeliveryTypes.ExpressDelivery
    }, [order, formValues]);

    const handleOnFinish = (values: IOrder) => {
        let parcelList: ParcelItem[] | undefined;
        if (shouldCreateParcel) {
            for (let index = 0; index < parcels.length; index++) {
                const element = parcels[index];
                const invalidInput = Object.entries(element).findIndex(([key, value]) => +value <= 0);
                if (invalidInput >= 0) {
                    message.error('Enter parcel details.');
                    return;
                }
            }
            parcelList = parcels;
        }

        onFormFinish(values, parcelList);
    }

    const handleOnValueshange = (changedValues: any, values: any) => {
        setFormValues(values);
    }

    useEffect(() => {
        if (order !== null || order !== undefined) {
            setFormValues({ ...order });
        }
    }, [order]);

    return (
        <>
            {shouldCreateParcel &&
                <div className="order-status-getlabels">
                    <Buttons.Text
                        htmlType='submit'
                        text={'View Labels'}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={() => ordersStore.toogleLabelsPanel(true)}
                    />
                </div>
            }
            <Form
                ref={formRef}
                name='new-staff'
                className='custom-form'
                onFinish={handleOnFinish}
                onValuesChange={(c, v) => handleOnValueshange(c, v)}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={order ?? {}}
            >
                <Form.Item
                    name="statusCode"
                    label="Order Status"
                    rules={[{ required: true }]}
                >
                    <Select>
                        {OrderStatus.orderStatusInfo.map((item, index) => (
                            <Select.Option
                                key={index}
                                value={item.statusCode}
                            >
                                <div style={{ color: item.color }}>
                                    {item.readableText}
                                </div>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {shouldCreateParcel &&
                    <ExpandedTables.ProductSpecificationTable
                        values={parcels}
                        onInputChange={(_: any) => setParcels(_)}
                    />
                }

                <div className="custom-form-submit">
                    <Buttons.Small
                        htmlType='submit'
                        text={'Update'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
});

export default UpdateOrderStatus;
