import React, { useEffect, useState } from 'react';
import { Form, Input, Switch, message } from 'antd';
import { observer } from "mobx-react-lite";
import { ICategory } from '../../utils/interfaces';
import { Buttons, Files } from "..";
import { UploadFile } from "antd/lib/upload/interface";

interface NewCategoryFormProps {
  onFormFinish: (category: FormData) => void;
  isLoading: boolean;
  parentCategory: ICategory | null;
  initialValues?: ICategory;
}

const NewCategoryForm: React.FC<NewCategoryFormProps> = observer(({ 
  onFormFinish, 
  isLoading, 
  parentCategory,
  initialValues
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState(0);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      if (initialValues.imageUrl) {
        setFileList([{
          uid: initialValues.imageUrl,
          name: initialValues.imageUrl,
          url: initialValues.imageUrl,
        }]);
      }
    } else {
      form.resetFields();
      setFileList([]);
    }
  }, [initialValues, form]);

  const onFinish = (values: any) => {
    const formData = new FormData();
    
    Object.keys(values).forEach(key => {
      if (values[key] !== undefined && values[key] !== null) {
        formData.append(key, values[key]);
      }
    });

    if (fileList[0]?.originFileObj) {
      formData.append('imageToUpload', fileList[0].originFileObj);
    }
    if (removedFiles.length > 0) {
      formData.append('imageToDelete', removedFiles[0]);
    }

    if (parentCategory?.id) {
      formData.append('parentCategoryId', parentCategory.id);
    }

    onFormFinish(formData);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('Please fill all required fields correctly.');
  };

  const validateMessages = {
    required: "Field is required!",
  };

  return (
    <Form
      form={form}
      name='new-category'
      className='custom-form inventory-form'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout='vertical'
      requiredMark={false}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <Files.ImagesPreviewUpload
        name='categoryImage'
        title={'Add Category Image'}
        maxCount={1}
        fileList={fileList}
        selectedFile={selectedFile}
        setFileList={setFileList}
        setSelectedFile={setSelectedFile}
        onRemoveUrlImage={(url) => setRemovedFiles(arr => [...arr, url])}
      />
      
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="refNo"
        label="Reference Number"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item name="featured" label="Featured" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name="active" label="Active" valuePropName="checked">
        <Switch />
      </Form.Item>
      {parentCategory && (
        <Form.Item label="Parent Category">
          <Input value={parentCategory.name} readOnly />
        </Form.Item>
      )}
      <div className='custom-form-submit'>
        <Buttons.Small
          htmlType='submit'
          text={initialValues ? 'Update category' : 'Add category'}
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </Form>
  );
});

export default NewCategoryForm;