import { Input, Table } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Spinners } from "..";
import { Store } from "../../stores/stores";

const ContractPricingTable = observer(() => {
    const { clientsStore } = Store;
    const pricingList = clientsStore.getClientPricing;

    const columns = [
        {
            title: "Sku/Stock Code",
            dataIndex: "stockCode",
        },
        {
            title: "Discount1",
            dataIndex: "discount1",
        },
        {
            title: "Gross Price",
            dataIndex: "grossPrice",
            render: (text: any, record: any) =>
                <div>R {(text ?? 0)?.toFixed(2)}</div>
        },
    ];


    return (
        <Spinners.CustomSpinner
            title="Getting Customer Pricing"
            isSpinning={clientsStore.isLoading}
        >
            <Input
                size='large'
                className='pageheader-options-search contract-pricing-search'
                placeholder="Search list..."
                defaultValue={clientsStore.pricingQuery}
                prefix={<RiSearchLine color='#CBD5E0' />}
                onChange={(_) => {
                    clientsStore.setPricingQuery(_.target.value)
                }}
            />

            <Table
                rowKey='id'
                className='custom-table  contractpricing-table'
                columns={columns}
                dataSource={[...pricingList]}
                pagination={false}
            />
        </Spinners.CustomSpinner >
    );
});

export default ContractPricingTable;