import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Drawers, Headers, Siders } from '../components';
import { useAuthContext } from '../context/AuthContext';
import { home_pages } from '../pages/home-pages';
import { Store } from '../stores/stores';
import { Layouts } from '../utils/enums';

const Home = observer(() => {
    const [collapsed, setCollapsed] = useState(true);
    const { authKey, hasRoutePermission } = useAuthContext();
    const { notificationsStore } = Store;

    React.useEffect(() => {
        notificationsStore.startMessageConnection(authKey)
        notificationsStore.loadUnreadMessages();
    }, [authKey, notificationsStore])

    const getRoutes = (defaultRoutes: any[]) =>
        defaultRoutes.map((prop, index) => {
            if (prop.layout === Layouts.Home && hasRoutePermission(prop.platformRights)) {
                return (
                    <Route
                        key={index}
                        path={prop.layout + prop.path}
                        exact={prop.exact}
                        component={prop.component}
                    />
                );
            }
            return null;
        });

    return (
        <Layout>
            <Siders.Main collapsed={collapsed} routes={home_pages} />
            <Layout.Content className='home-content'>
                <Headers.Main collapsed={collapsed} setCollapsed={setCollapsed} />
                <Switch>{getRoutes(home_pages)}</Switch>
                <Drawers.OrderInfoDrawer />
            </Layout.Content>
        </Layout>
    );
});

export default Home;