import { Form, FormInstance, Input, message, Switch, Popconfirm } from "antd";
import React from "react";
import { Buttons, Search } from "..";
import { EmailService } from "../../services";
import { IBranch, IClient } from "../../utils/interfaces";
import { DebounceSelectProp } from "../Search/DebounceSearch";

type NewCustomerFormProp = {
    client?: IClient,
    onFormFinish: (client: IClient) => void
    onClientDelete: () => void
    isLoading: boolean
    onBranchSearch: (query: string) => Promise<any[]>;
    tooglePricingPanel: (value: boolean) => void
};

const NewCustomerForm = ({ client, isLoading, onBranchSearch, onFormFinish, ...props }: NewCustomerFormProp) => {
    const formRef = React.createRef<FormInstance>();
    const branchRef = React.useRef<Partial<IBranch>>({});

    const initialValues = Object.assign({}, { ...client }, client?.userAuth, {
        branchName: client?.branch?.branchName
    })

    const validateMessages = {
        required: "Field is required!",
    };

    // Popconfirm Delete/ Cancel

    function confirmDelete() {
        props.onClientDelete();
      }
      
      function cancelDelete(e: any) {
        console.log(e);
        message.error('Delete operation cancelled');
      }

    const BranchSelect = ({ ...props }) => (
        <Search.DebounceSearch<DebounceSelectProp>
            fetchOptions={onBranchSearch}
            onSelect={(value: any, option: any) => {
                branchRef.current.refNo = option.key as string;
                branchRef.current.branchName = option.label as string;
            }}
            {...props}
        />
    );

    const customerFormData = [
        { name: 'sysproId', label: 'Syspro Id', component: Input, required: false },
        { name: 'firstName', label: 'First name', component: Input, required: true },
        { name: 'lastName', label: 'Last name', component: Input, required: true },
        { name: 'emailAddress', label: 'Email', component: Input, required: true },
        { name: 'claimedAccountNumber', label: 'Claimed account number', component: Input, required: false },
        { name: 'accountNumber', label: 'Account number', component: Input, required: false },
        { name: 'branchName', label: 'Branch', component: BranchSelect, required: false },
        { name: 'billingCompany', label: 'Billing company', component: Input, required: false },
        { name: 'billingPhone', label: 'Billing phone', component: Input, required: true },
        { name: 'communicationConsent', label: 'Communication consent', component: Switch, valuePropName: "checked", required: false },
    ];

    const handleOnFinish = (values: any) => {
        if (EmailService.isEmailValid(values.emailAddress)) {
            const newClient: IClient = {
                ...values,
                branchId: initialValues.branchName === values.branchName ? client?.branchId : values?.branchName,
                userAuth: {
                    ...client?.userAuth,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    emailAddress: values.emailAddress,
                }
            };
            onFormFinish(newClient);
        } else {
            message.error('Enter a valid email address');
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error('Could not add new customer.')
    };

    return (
        <>
            <div className='form-options-container'>
                <Buttons.Text
                    text="Send Reset Link"
                    loading={false}
                    disabled={true}
                />

                {client && client?.accountNumber && (
                    <Buttons.Text
                        text="Contract Pricing"
                        disabled={isLoading}
                        onClick={() => props.tooglePricingPanel(true)}
                    />
                )}

                <Popconfirm title={`Are you sure you want to delete this client - ${client?.userAuth?.firstName} ${client?.userAuth?.lastName}? `} 
                onConfirm={confirmDelete} 
                onCancel={cancelDelete} 
                okText="Yes" 
                cancelText="No"
                okButtonProps={{className: 'confirmBtn'}}>
                    <Buttons.Text
                        text="Delete Client"
                    />
                </Popconfirm>
               
            </div>

            <Form
                ref={formRef}
                name='new-customer'
                className='custom-form'
                onFinish={handleOnFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                requiredMark={false}
                validateMessages={validateMessages}
                initialValues={initialValues}
            >
                {customerFormData.map(({ component: Component, ...item }, index) =>
                    <Form.Item
                        key={index}
                        rules={[{ required: item.required }]}
                        {...item}
                    >
                        <Component />
                    </Form.Item>
                )}

                <div className='custom-form-submit'>
                    <Buttons.Small
                        htmlType='submit'
                        text={client ? 'Update client' : 'Add client'}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </Form>
        </>
    );
};

export default NewCustomerForm;