import { APP_URL } from "../../config/axios-config";
import { ICustomPage } from "../../utils/interfaces";
import { ResponseFormat } from "../../utils/types";

export const getCustomPages = async (): Promise<ResponseFormat<ICustomPage>> => {
    const response = await APP_URL.get('CustomPage');

    let customPages: ICustomPage[] = response.data;
    return { data: customPages };
}

export const getCustomPageById = async (id: string): Promise<ICustomPage> => {
    const response = await APP_URL.get(`CustomPage/${id}`);

    let customPage: ICustomPage = response.data;
    return customPage;
}

export const getCustomPageByName = async (name: string): Promise<ICustomPage> => {
    const response = await APP_URL.get(`CustomPage/pages/${name}`);
    
    let customPage: ICustomPage = response.data;
    return customPage;
}


export const deleteCustomPage = async (body: ICustomPage, items: ICustomPage[]): Promise<ResponseFormat<ICustomPage>> => {
    await APP_URL.delete(`CustomPage/${body.id}`);
    const updatedItems = items.filter(x => x.id !== body.id);
    return { data: updatedItems };
}

export const addCustomPage = async (body: FormData, items: ICustomPage[]): Promise<ResponseFormat<ICustomPage>> => {
    const response = await APP_URL.post('CustomPage', body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    items.push(response.data as ICustomPage);
    return { data: items };
}

export const updateCustomPage = async (body: FormData, items: ICustomPage[]): Promise<ResponseFormat<ICustomPage>> => {
    const response = await APP_URL.put(`CustomPage/${body.get('id')}`, body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    const updatedPage = response.data as ICustomPage;
    const index = items.findIndex(x => x.id === updatedPage.id);
    if (index !== -1) {
        items[index] = updatedPage;
    }
    return { data: items };
}

export const addPreview = async (body: FormData): Promise<ResponseFormat<ICustomPage>> => {
    const response = await APP_URL.post('CustomPage/pages/preview', body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    return { data: response.data };
};

export const publishPage = async (body: FormData): Promise<ResponseFormat<ICustomPage>> => {
    const response = await APP_URL.post('CustomPage/pages/publish', body, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    return { data: response.data };
};
