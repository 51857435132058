import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { mini_program_pages } from '../pages/mini-program';
import { Layouts } from '../utils/enums';

const MiniProgram = () => {
    const getRoutes = (defaultRoutes: any[]) =>
        defaultRoutes.map((prop, index) => {
            if (prop.layout === Layouts.MiniProgram) {
                return (
                    <Route
                        key={index}
                        path={prop.layout + prop.path}
                        exact={prop.exact}
                        component={prop.component}
                    />
                );
            }
            return null;
        });

    return (
        <Switch>{getRoutes(mini_program_pages)}</Switch>
    );
}

export default MiniProgram;