import { Tag } from "antd";

export type CustomTagProp = {
    text: any
};

const CustomTag = ({ text }: CustomTagProp) => {
    return (
        <Tag>
            {text}
        </Tag>
    )
}

export default CustomTag;