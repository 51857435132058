import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Input, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Buttons, Forms } from "..";
import { Store } from "../../stores/stores";
import { IUserAuth, IVoucher } from "../../utils/interfaces";
import CustomDrawer from "./CustomDrawer";
import { VoucherType } from "../../utils/enums";

const LoyaltyCardDrawer = observer(() => {
    const { vouchersStore } = Store;

    const columns = [
        {
            "title": "Id",
            "dataIndex": "id",
            fixed: 'left' as 'left',
            width: 70,
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: "Ref No",
            dataIndex: "refNo",
            fixed: 'left' as 'left',
            width: 140,
            render: (text: any) =>
                <div>{text ? `#${text}` : ''}</div>
        },
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "description",
            dataIndex: "description",
        },
        {
            title: "Active",
            dataIndex: "active",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Not Active',
                    value: false,
                },
            ],
            onFilter: (value: any, record: IVoucher) => record.active === !!value,
            render: (isNew: boolean, record: any) => (
                isNew ? (
                    <CheckOutlined style={{ color: 'green' }} />
                ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                )
            )
        },
        {
            title: "Last Edit",
            dataIndex: "lastEditBy",
            render: (text: IUserAuth, record: any) => {
                if (!text?.firstName && !text?.lastName) {
                    return '---';
                }
                return `${text?.firstName ?? ''} ${text.lastName ?? ''}`;
            }
        },
        {
            title: "Created By",
            dataIndex: "lastEditBy",
            render: (text: IUserAuth, record: any) => {
                if (!text?.firstName && !text?.lastName) {
                    return '---';
                }
                return `${text?.firstName ?? ''} ${text.lastName ?? ''}`;
            }
        },
        {
            title: "From",
            dataIndex: "fromDate",
        },
        {
            title: "To",
            dataIndex: "toDate",
        },
        {
            title: "Created",
            dataIndex: "createdAt",
        },
        {
            key: 'edit',
            fixed: 'right' as 'right',
            width: 90,
            render: (text: any, record: IVoucher) => (
                <Buttons.Text
                    text='Edit'
                    onClick={() => vouchersStore.editVoucher(record)}
                />
            )
        },
    ];

    React.useEffect(() => {
        vouchersStore.loadVouchers();
    }, [vouchersStore]);
    
    const filteredData = vouchersStore.filteredQueryResults.filter((voucher: IVoucher) => voucher.voucherType === VoucherType.LOYALTY_CARD);

    return (
        <Drawer
            className='custom-drawer'
            title="Loyalty Card"
            visible={vouchersStore.showLoyaltyPanel}
               onClose={() => {
                vouchersStore.togglePanel(false);
                vouchersStore.resetSearchQuery(); // Reset the search filter
            }}
            width="60%"
            children={(
                <>
                    <div className='form-options-container'>
                        <Buttons.Text
                            text="Add new"
                            loading={false}
                            disabled={false}
                            onClick={() => vouchersStore.editVoucher(null)}
                        />
                    </div>

                    <Input
                        className='pageheader-options-search orders-search'
                        placeholder="Search list..."
                        defaultValue={vouchersStore.searchQuery}
                        prefix={<RiSearchLine color='#CBD5E0' />}
                        disabled={vouchersStore.isLoading}
                        onChange={(_) => vouchersStore.setSearchQuery(_.target.value)}
                        style={{ marginTop: '1em', marginBottom: '2em', width: '50%' }}
                    />

                    <div className='custom-table-container'>
                        <Table
                            rowKey='id'
                            className='custom-table contractpricing-table'
                            columns={columns}
                            scroll={{ x: 2500 }}
                            dataSource={filteredData}
                            pagination={false}
                        />
                    </div>

                    <CustomDrawer
                        title={!vouchersStore.currentVoucher ? "Add new item" : "Edit item"}
                        visible={vouchersStore.showEditPanel}
                        width="70%"
                        setVisible={(_) => vouchersStore.toggleEditPanel(_)}
                        children={observer(() => (
                            <Tabs
                                activeKey={vouchersStore.formActiveTab}
                                className='custom-tabs'
                                size='large'
                                onTabClick={(_) => vouchersStore.setFormActiveTab(_ as any)}
                            >
                                <Tabs.TabPane
                                    tab="Loyalty Card Discount"
                                    key="1"
                                    disabled={vouchersStore.isLoading}
                                >
                                    <Forms.NewVoucher
                                        voucher={vouchersStore.currentVoucher}
                                        onFormFinish={(_) => vouchersStore.submitVoucher(_, VoucherType.LOYALTY_CARD)} 
                                        onVoucherDelete={() => {}}
                                        isLoading={vouchersStore.isLoading}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab="Constraints"
                                    key="2"
                                    disabled={vouchersStore.isLoading || !vouchersStore.currentVoucher}
                                >
                                    <Forms.NewVoucherConstraint
                                        voucherId={vouchersStore.currentVoucher?.id}
                                        voucherConstraints={vouchersStore.voucherConstraints.value.data}
                                        onFormFinish={(v) => vouchersStore.submitVoucherConstraint(v)}
                                        onDelete={() => {}}
                                        isLoading={vouchersStore.isLoading}
                                        isLoyaltyCard={true} 
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        ))}
                    />
                </>
            )}
        />
    );
});

export default LoyaltyCardDrawer;
