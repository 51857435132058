import { makeAutoObservable, observable, flow } from "mobx"
import { ResponseFormat } from "../types"

export type DataModifier<T> = (item: T | any, items: T[], queryParameters?: string) => Promise<ResponseFormat<T>>

export class LoadManager<T> {
    value: ResponseFormat<T>
    isLoading: boolean = false
    error: Error | null = null
    fetcher: (queryParameters?: string) => Promise<ResponseFormat<T>>
    pusher?: DataModifier<T>
    remover?: DataModifier<T>
    updater?: DataModifier<T>


    constructor(value: ResponseFormat<T>, loader: () => Promise<ResponseFormat<T>>, pusher?: DataModifier<T>, remover?: DataModifier<T>, updater?: DataModifier<T>) {
        makeAutoObservable(this, {
            value: observable,
            isLoading: observable,
            error: observable,
            fetch: flow,
            add: flow,
            update: flow,
            remove: flow,
        })
        this.value = value;
        this.fetcher = loader;
        this.pusher = pusher;
        this.remover = remover;
        this.updater = updater;
    }

    *fetch(queryParameters?: string) {
        this.error = null;
        this.isLoading = true;

        try {
            const response: ResponseFormat<T> = yield this.fetcher(queryParameters);
            this.value = response;
        } catch (error: any) {
            this.error = error;
        }

        this.isLoading = false;
    }

    *update(item: T | FormData) {
        this.isLoading = true;
        this.error = null;

        try {
            const response: ResponseFormat<T> = yield this.updater!(item, this.value.data);
            this.value = response;
        } catch (error: any) {
            this.error = error;
        }

        this.isLoading = false;
    }

    *add(item: T | FormData) {
        this.isLoading = true;
        this.error = null;

        try {
            const response: ResponseFormat<T> = yield this.pusher!(item, this.value.data);
            this.value = response;
        } catch (error: any) {
            this.error = error;
        }

        this.isLoading = false;
    }

    *remove(item: T, queryParameters?: string) {
        this.isLoading = true;
        this.error = null;

        try {
            const response: ResponseFormat<T> = yield this.remover!(item, this.value.data, queryParameters);
            this.value = response;
        } catch (error: any) {
            this.error = error;
        }

        this.isLoading = false;
    }
}