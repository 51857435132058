import { Form, FormInstance, Input, message, Select } from "antd";
import React from "react";
import { Buttons } from "..";
import { observer } from "mobx-react-lite";
import { Store } from "../../stores/stores";
import { ICODAccountNumber } from "../../utils/interfaces";
import "./style.css";

type NewCODAccountFormProp = {
    codAccountNumber?: ICODAccountNumber,
    onFormFinish?: (codAccountNumber: ICODAccountNumber) => void,
    isLoading: boolean,
};

const  NewCODAccountForm = observer(({ codAccountNumber, onFormFinish, isLoading }: NewCODAccountFormProp) => {
    const formRef = React.createRef<FormInstance>();
    const { CODAccountNumberStore } = Store;

    const validateMessages = {
        required: "Field is required!",
    };

    const handleOnFinish = async (formValues: any) => {
        try {
            await CODAccountNumberStore.onFormSubmit(formValues);
        } catch (error) {
            message.error('An error occurred while saving the cod account number.');
        }
    };

    return (
        <Form
            ref={formRef}
            name='new-cod-number'
            className='custom-form'
            onFinish={handleOnFinish}
            onFinishFailed={() => { }}
            layout='vertical'
            requiredMark={false}
            validateMessages={validateMessages}
            initialValues={codAccountNumber}
        >
            <Form.Item
                label="Account Number"
                name="accountNumber"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

            <div className='custom-form-submit'>
                <Buttons.Small
                    htmlType='submit'
                    text={codAccountNumber ? 'Update COD Number' : 'Add COD Number'}
                    loading={isLoading}
                    disabled={isLoading}
                />
            </div>
        </Form>
    );
});

export default NewCODAccountForm;
